import cn from 'classnames'
import currency from 'currency.js'

export type TContributionProp = {
  className?: string
  num: number
}

export default function Contribution({
  className = '',
  num,
}: TContributionProp): JSX.Element {
  const value = currency(num, { precision: 0 }).value

  const progress = value / 5 > 50 ? 50 : Math.ceil(value / 5)

  if (value === 0) {
    return <div className='h-22 flex items-center justify-end '>-</div>
  }
  return (
    <div
      className={cn(
        className,
        'relative w-98 h-22 bg-dark-300 px-2 rounded-2xl flex items-center justify-between',
      )}
    >
      <div
        style={{ width: `${progress}px` }}
        className={'bg-blue rounded-lg h-1 max-w-[50px]'}
      ></div>
      <div className='absolute text-light-900 text-xxs-r right-2  font-medium w-12 flex justify-end items-center'>
        {value}
      </div>
    </div>
  )
}
