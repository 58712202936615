import React from 'react'

import cn from 'classnames'

export type TProcessingProp = {
  className?: string
  showCount?: boolean
  count?: number
  percent: string | number
  color: string
}

export default function Processing({
  className = '',
  showCount = true,
  count,
  percent,
  color,
}: TProcessingProp): JSX.Element {
  return (
    <div
      className={cn(
        className,
        'h-22 bg-dark-300 px-2 rounded-2xl flex items-center max-w-max',
      )}
    >
      {showCount && (
        <div className='text-light-900 text-xxs-z mr-4 leading-[1.4] flex  items-center'>
          {count}
        </div>
      )}
      <div className='relative rounded-lg bg-light-100 w-100 h-1'>
        <div
          style={{ width: percent + '%' }}
          className={`absolute top-0 left-0 rounded-lg h-1 bg-${color}`}
        ></div>
      </div>

      <div className='w-56 ml-2 text-light-900 text-xxs-z leading-[1.4] font-medium  flex justify-end items-center'>
        {percent}%
      </div>
    </div>
  )
}
