import * as ROUTES from './constant'

import OrdersPage from 'pages/Orders'
import AccountsPage from 'pages/Accounts'
import ReferralPage from 'pages/Referral'
import AffiliatePage from 'pages/Affiliate'
import DisputePage from 'pages/Dispute'
import CreditPage from 'pages/Credit'
import BlocklistPage from 'pages/Blocklist'
import DomainsPage from 'pages/Domains'
import SalesPage from 'pages/Sales'
import LeadSourcingPage from 'pages/Sales/LeadSourcing'
import MapPage from 'pages/Map'
import LogisticsPage from 'pages/Logistics'
import OwnershipTransferPage from 'pages/OwnershipTransfer'

import PayoutPage from 'pages/Payout'
import LoginPage from 'pages/Login'

/**
 * https://files.slack.com/files-pri/T01569QU890-F04DYCC817S/image.png
 * 菜单权限控制 crm_role_id
 *  orders        1、11-29 显示
 *  accounts      1、11-199
 *  maps          1、11-19
 *  sales         1、11-19
 *  domain        1、21-29
 *  referral      1
 *  affiliate     1、101-199
 *  credit        1、21-29
 *  blocklist     1、21-29
 *  payout        1
 */

// nav menu route
const ORDERS: TRouteType = {
  path: ROUTES.ORDERS,
  name: 'Orders',
  component: OrdersPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 11,
    max: 29,
  },
}

const ACCOUNTS: TRouteType = {
  path: ROUTES.ACCOUNTS,
  name: 'Accounts',
  component: AccountsPage,
  auth: true,
}

const Map: TRouteType = {
  path: ROUTES.MAP,
  name: 'Map',
  component: MapPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 11,
    max: 19,
    crmTeamIds: [3, 4],
  },
}

// sales 导航菜单的 permission 字段暂时无用
// 目前是靠 SALES_HOVER 来控制导航菜单显示与否的
const SALES_AE: TRouteType = {
  path: ROUTES.SALES_AE,
  name: 'Account Executive',
  component: SalesPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 11,
    max: 19,
    crmTeamIds: [3, 4],
  },
}

const SALES_SE: TRouteType = {
  path: ROUTES.SALES_SE,
  name: 'Solution Engineer',
  component: SalesPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 11,
    max: 19,
    crmTeamIds: [3, 4],
  },
}

const LEAD_SOURCING: TRouteType = {
  path: ROUTES.LEAD_SOURCING,
  name: 'Lead Sourcing',
  component: LeadSourcingPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 11,
    max: 19,
    crmTeamIds: [3, 4],
  },
}

const DOMAINS: TRouteType = {
  path: ROUTES.DOMAINS,
  name: 'Domain',
  component: DomainsPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

const REFERRAL: TRouteType = {
  path: ROUTES.REFERRAL,
  name: 'Referral',
  component: ReferralPage,
  auth: true,
  permission: {
    roleId: 1,
  },
}

const AFFILIATE: TRouteType = {
  path: ROUTES.AFFILIATE,
  name: 'Affiliate',
  component: AffiliatePage,
  auth: true,
  permission: {
    roleId: 1,
    min: 101,
    max: 199,
  },
}

const DISPUTE: TRouteType = {
  path: ROUTES.DISPUTE,
  name: 'Dispute',
  component: DisputePage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

const CREDIT: TRouteType = {
  path: ROUTES.CREDIT,
  name: 'Credit',
  component: CreditPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

const BLOCKLIST: TRouteType = {
  path: ROUTES.BLOCKLIST,
  name: 'Blocklist',
  component: BlocklistPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

const LOGISTICS: TRouteType = {
  path: ROUTES.LOGISTICS,
  name: 'Logistics',
  component: LogisticsPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

const OWNERSHIP_TRANSFER: TRouteType = {
  path: ROUTES.OWNERSHIP_TRANSFER,
  name: 'Ownership Transfer',
  component: OwnershipTransferPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

// other route
const PAYOUT: TRouteType = {
  path: ROUTES.PAYOUT,
  name: 'Payout',
  component: PayoutPage,
  auth: true,
  permission: {
    roleId: 1,
  },
}
const LOGIN: TRouteType = {
  path: ROUTES.LOGIN,
  name: 'Login',
  component: LoginPage,
  auth: false,
}

const SUPPORT_HOVER: TRouteType = {
  path: '',
  name: 'Support',
  component: () => {},
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

const SALES_HOVER: TRouteType = {
  path: '',
  name: 'Sales',
  component: () => {},
  auth: true,
  permission: {
    roleId: 1,
    min: 11,
    max: 19,
    crmTeamIds: [3, 4],
  },
}

// 导航栏显示的菜单
export const NAVMENU = [ORDERS, ACCOUNTS, Map, SALES_HOVER, SUPPORT_HOVER, AFFILIATE]

// 导航栏 support 下拉列表下的菜单
export const SUPPORTS = [
  DOMAINS,
  CREDIT,
  BLOCKLIST,
  DISPUTE,
  LOGISTICS,
  PAYOUT,
  REFERRAL,
  OWNERSHIP_TRANSFER,
]

// 导航栏 sales 下拉列表下的菜单
export const SALES = [SALES_AE, SALES_SE, LEAD_SOURCING]

export const PAGEROUTES = [...NAVMENU, ...SUPPORTS, ...SALES, LOGIN]
