export const STATUS_OPTIONS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Initiated',
    value: 'initiated',
  },
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Past due',
    value: 'past_due',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
]
