import { SolidMapPin } from '@rushable/icons'
import currency from 'currency.js'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'

import BadgeText from 'components/BadgeText'
import Pricing from 'components/Pricing'
import PersonName from 'components/PersonName'
import Checkbox from 'components/Checkbox'
import StageProgress from 'components/StageProgress'

import { LEADSOURCE } from 'data/enum'
import type { TSameBrandNameSignupForm } from '../../helpers'

type TGroupItemRow = {
  item: TSameBrandNameSignupForm
  isEdit?: boolean
  signupFormIds: number[]
  handleBrandGroupIdChange: (id: number, check: boolean) => void
}

export default function GroupItemRow({
  item,
  isEdit,
  signupFormIds,
  handleBrandGroupIdChange,
}: TGroupItemRow) {
  const navigate = useNavigate()
  const {
    location_name,
    stage_days,
    signup_product,
    source,
    account,
    account_id,
    group_signup_id,
    id,
    stage,
    current_step,
  } = item
  const closer = account?.onboarding?.crm_closer
  const sourceName = LEADSOURCE.find(v => v.value === source)?.label
  const badgeInfo: {
    color: 'orange' | 'default'
    value: string
  } = group_signup_id
    ? {
        color: 'orange',
        value: 'Grouped',
      }
    : {
        color: 'default',
        value: 'Single',
      }

  const checked = signupFormIds.find(v => v === id)
  const clickedRow = () => {
    // navigate(`/accounts/detail/${account_id}?from=ownership-transfer`)
    if (!isEdit) {
      navigate(`/accounts/detail/${account_id}`)
    }
  }
  return (
    <div
      className={cn('h-10 flex text-light-500 hover:bg-dark-500', {
        'cursor-pointer': !isEdit,
      })}
      onClick={clickedRow}
    >
      {isEdit ? (
        <div className='w-100 px-4 h-full flex justify-start items-center'>
          <Checkbox
            checked={!!checked}
            onChange={e => handleBrandGroupIdChange(id, e.target.checked)}
          />
        </div>
      ) : (
        <div className='w-100 h-full flex justify-center items-center'>
          <BadgeText color={badgeInfo.color} value={badgeInfo.value} />
        </div>
      )}
      <div className='flex-1 px-4 flex items-center '>
        <SolidMapPin size={16} className='text-light-100 mr-1' />
        {location_name}
      </div>
      <div className='px-4 flex items-center'>
        <Pricing
          store={currency(signup_product?.pricing || 0).format()}
          userGroups={currency(signup_product?.convenience_fee || 0).format()}
        />
      </div>
      <div className='min-w-120 flex items-center px-4'>{closer?.team_name || '-'}</div>
      <div className='min-w-120 flex items-center px-4'>{sourceName}</div>
      <div className='w-200 pl-4 flex items-center'>
        <PersonName
          avatar={closer.profile_photo}
          name={`${closer.first_name} ${closer.last_name}`}
        />
      </div>
      <div className='w-100 px-4 flex items-center justify-end'>{stage_days} days</div>
      <div className='w-120 pr-4 flex items-center justify-end'>
        <StageProgress
          className='ml-auto'
          popTop={false}
          // 这里只会有 signup 状态的 account
          onboarding={{ status: 'signup_ready' } as any}
          signupForm={
            {
              current_step: current_step,
            } as any
          }
          statusName={stage}
        />
      </div>
    </div>
  )
}
