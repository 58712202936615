import React, { useRef } from 'react'

import cn from 'classnames'
import { SortDown, SortUp } from '@rushable/icons'
import useOnClickOutside from 'hooks/useOnClickOutside'

export type THDropdownProp = {
  open: boolean
  className?: string
  name?: string
  sort?: string
  customValue?: string
  options?: { label: string; value: string }[]
  onOptionChange?: (item: { label: string; value: string }) => void
  openTHDropdown?: () => void
  closeTHDropdown?: () => void
}

export default function THDropdown({
  open,
  className = '',
  name,
  sort,
  customValue,
  options,
  onOptionChange,
  openTHDropdown,
  closeTHDropdown,
}: THDropdownProp): JSX.Element {
  let optionName = ''
  if (options && customValue) {
    const nowOption = options.filter(v => v.value === customValue)[0]
    if (nowOption) {
      optionName = nowOption.label
    }
  }
  const ref = useRef(null)

  const handleClickOutside = () => {
    closeTHDropdown && closeTHDropdown()
  }
  useOnClickOutside(ref, handleClickOutside)

  return (
    <div
      className={cn(
        'flex flex-col cursor-pointer relative',
        open && 'bg-dark-500',
      )}
    >
      <div
        className={cn(
          className,
          'hover:bg-dark-500 px-4 py-2 flex items-center whitespace-nowrap	text-light-300 text-xs font-bold',
        )}
        onClick={() => openTHDropdown?.()}
      >
        <div className={`${sort && 'text-light-700'}`}>
          {optionName || name}
        </div>
        <div className='flex flex-col items-center ml-1'>
          <SortUp
            className={`${
              sort === 'ASC' ? 'text-light-700' : 'text-light-100'
            }`}
            size={6}
          />
          <SortDown
            className={`${
              sort === 'DESC' ? 'text-light-700' : 'text-light-100'
            }`}
            size={6}
          />
        </div>
      </div>
      {open && (
        <div
          ref={ref}
          className={cn(
            'bg-dark-500 py-2 w-full flex flex-col cursor-pointer border-t border-dashed border-dark-100 absolute top-[33.8px] z-10',
          )}
        >
          <div
            className={cn(
              className,
              'flex px-4  py-1 text-xs text-light-300 whitespace-nowrap',
            )}
            onClick={() => {
              if (sort === 'ASC') {
                onOptionChange?.({ label: 'sort', value: '' })
              } else {
                onOptionChange?.({ label: 'sort', value: 'ASC' })
              }
            }}
          >
            Sort up
          </div>
          <div
            className={cn(
              className,
              'flex px-4  py-1 text-xs text-light-300 whitespace-nowrap',
            )}
            onClick={() => {
              if (sort === 'DESC') {
                onOptionChange?.({ label: 'sort', value: '' })
              } else {
                onOptionChange?.({ label: 'sort', value: 'DESC' })
              }
            }}
          >
            Sort down
          </div>
          {options?.map(item => {
            return (
              <div
                className='ml-4 py-1 text-xs text-light-300'
                onClick={() => onOptionChange?.(item)}
                key={`THDropdown-${item.value}`}
              >
                {item.label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
