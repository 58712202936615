import React, { useState, useEffect } from 'react'

import { AngleLeft } from '@rushable/icons'
import Button from 'components/Button'
import { AdminLayout } from 'components/Layout'
import SingleInput from 'components/SingleInput'
import Processing from 'components/Processing'
import Modal from 'components/Modal'
import PayoutSchedule from 'components/PayoutSchedule'
import moment from 'moment'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPayoutDetail, payoutRetry } from 'redux/payouts'

import { currencyFormatter } from 'utils/digit'

import PayoutFilter from './components/PayoutFilter'
import PayoutDetailTable from './components/PayoutDetailTable'

export default function PayoutDetailPage() {
  const navigate = useNavigate()
  const [getParams] = useSearchParams()
  const [summary, setSummary] = useState<any>(null)
  const [rowId, setRowId] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [retryLoading, setRetryLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [retryData, setRetryData] = useState<any>({
    balance: '',
    amount: '',
    date: '',
    status: '',
    reason: '',
  })
  const [sortData, setSortData] = useState({
    sort_by: '',
    sort: '',
  })
  const [filterForm, setFilterForm] = useState<any>({
    status: '',
    page: 1,
    per_page: 40,
  })
  const [data, setData] = useState<any[]>([])

  const handleFilterChange = (type: string, value: any) => {
    let newForm = { ...filterForm }
    if (type === 'sort') {
      const sortForm = {
        sort_by: value.value ? value.type : '',
        sort: value.value,
      }
      newForm = {
        ...filterForm,
        ...sortForm,
      }
      setSortData(sortForm)
    } else {
      newForm = {
        ...filterForm,
        [type]: value,
      }
    }
    getTable({ ...newForm, page: 1 })
  }

  const onPageNoChange = (page: number) => {
    getTable({ ...filterForm, page })
  }

  const getTable = async (form: any) => {
    // 请求 api
    setLoading(true)
    try {
      setFilterForm(form)
      form['date'] = getParams.get('date')
      form['type'] = getParams.get('type')
      const res = await getPayoutDetail(form)
      setSummary(res.summary)
      setData(res.payouts.data)
      setTotal(res.payouts.total)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }

  const handleRetry = async () => {
    // 请求 api
    setRetryLoading(true)
    try {
      const res = await payoutRetry({ id: rowId })
      res.message && toast.success(res.message)
      setOpenModal(false)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setRetryLoading(false)
  }

  const getProcessing = () => {
    let percent = 0
    let number = 0
    let color = ''
    const status: any = getParams.get('status')
    if (['processing', 'stuck', 'done'].includes(status)) {
      number = parseFloat(
        status === 'done'
          ? summary.done
          : status === 'processing'
          ? summary.done
          : summary.stuck,
      )
      percent = Math.round((number / summary.accounts) * 10000) / 100.0
      color =
        status === 'processing' ? 'blue' : status === 'stuck' ? 'red' : 'green'
    }
    return (
      <Processing
        className='ml-8'
        count={number || 0}
        percent={percent.toFixed(0) || 0}
        color={color}
      />
    )
  }

  const getSchedule = () => {
    const type = getParams.get('type') === 'daily' ? 'daily' : 'weekly'
    const date = getParams.get('date') || ''
    let weekOfDay: any = parseInt(moment(date).format('E'))
    let last_sunday = moment(date)
      .subtract((weekOfDay % 7) + (type === 'daily' ? 0 : 7), 'days')
      .format('YYYY-MM-DD')

    return (
      <PayoutSchedule
        payoutType={type}
        payoutRanges={last_sunday}
        payoutDate={date}
        intoAccountDate={summary.estimate_arrival}
      />
    )
  }

  useEffect(() => {
    getTable(filterForm)
  }, [])

  return (
    <AdminLayout>
      <div className='px-10 py-8'>
        <div className='flex justify-between'>
          <div className='flex space-x-4'>
            <Button
              color='secondary'
              theme='outlined'
              onClick={() => navigate(-1)}
            >
              <div className='flex'>
                <AngleLeft size={12} className='mr-1' />
                GO BACK
              </div>
            </Button>
            <PayoutFilter
              hideType={true}
              filterForm={filterForm}
              handleFormChange={handleFilterChange}
            />
          </div>

          {summary && (
            <div className='rounded-lg bg-dark-500 h-10 px-4 flex items-center'>
              <div className='text-light-500 text-sm'>
                {moment(getParams.get('date')).format('MMMM YYYY')}
              </div>
              <div className='text-light-500 text-sm mx-8'>
                {getParams.get('type') === 'daily' ? 'Daily' : 'Weekly'}
              </div>
              {getSchedule()}
              {getProcessing()}
            </div>
          )}
        </div>
        <div className='mt-8'>
          <PayoutDetailTable
            loading={loading}
            total={total}
            current={filterForm.page}
            pageSize={filterForm.per_page}
            data={data}
            sortData={sortData}
            handleFilterChange={handleFilterChange}
            onPageNoChange={onPageNoChange}
            clickedRow={row => {
              if (row.stripe_status === 'failed') {
                setRetryData({
                  balance: row?.location?.current_balance?.stripe_amount,
                  amount: row.stripe_amount,
                  date: row.payout_created_at,
                  status: 'Stuck',
                  reason: row.failure_message,
                })
                setRowId(row.id)
                setOpenModal(true)
              }
            }}
          />
        </div>
        <Modal
          title='STUCK PAYOUT'
          open={openModal}
          toggle={() => setOpenModal(false)}
          okButtonProps={{ color: 'warning', loading: retryLoading }}
          cancelText={'CLOSE'}
          okText={'RETRY NOW'}
          onOk={() => handleRetry()}
        >
          <div className='flex flex-col space-y-6'>
            <div className='flex space-x-4'>
              <SingleInput
                value={currencyFormatter(retryData.balance)}
                disabled
                name='Current_Balance'
                label='Current Balance'
                onChange={e =>
                  setRetryData({
                    ...retryData,
                    balance: e.target.value,
                  })
                }
              />
              <SingleInput
                value={currencyFormatter(retryData.amount)}
                disabled
                name='PAYOUT_AMOUNT'
                label='PAYOUT AMOUNT'
                onChange={e =>
                  setRetryData({
                    ...retryData,
                    amount: e.target.value,
                  })
                }
              />
            </div>
            <div className='flex space-x-4'>
              <SingleInput
                value={moment(retryData.date).format('dddd, M/D/YYYY')}
                disabled
                name='PAYOUT_DATE'
                label='PAYOUT DATE'
                onChange={e =>
                  setRetryData({
                    ...retryData,
                    date: e.target.value,
                  })
                }
              />
              <SingleInput
                value={retryData.status}
                disabled
                name='ENTITY_STATUS'
                label='ENTITY STATUS'
                onChange={e =>
                  setRetryData({
                    ...retryData,
                    status: e.target.value,
                  })
                }
              />
            </div>
            <SingleInput
              value={retryData.reason}
              disabled
              name='STUCK_REASON'
              label='STUCK REASON'
              onChange={e =>
                setRetryData({
                  ...retryData,
                  reason: e.target.value,
                })
              }
            />
          </div>
        </Modal>
      </div>
    </AdminLayout>
  )
}
