import { SolidStore, SolidMapPin, SolidUser } from '@rushable/icons'
import Table from 'components/Table'
import DateAndTime from 'components/DateAndTime'
import currency from 'currency.js'

import type { TBlocklist } from '../helpers/constant'
import Button from 'components/Button'

type TRowProp = {
  [index: string]: any
}

export type TBlocklistTableProp = {
  className?: string
  data: Array<TRowProp>
  total: number
  loading?: boolean
  current?: number
  pageSize?: number
  blocklist: TBlocklist
  handleBlocklistChange: (newBlocklist: TBlocklist) => void
  onPageNoChange?: (value: any) => void
  clickedRow: (value: any) => void
}

export default function BlocklistTable({
  className = '',
  loading,
  data,
  total,
  current,
  pageSize,
  blocklist,
  handleBlocklistChange,
  onPageNoChange,
  clickedRow,
}: TBlocklistTableProp): JSX.Element {
  const columns = [
    {
      key: 'customer',
      name: 'CUSTOMER BLOCKED',
      className: 'min-w-48',
      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            <SolidUser size={16} className='mr-1 text-light-100' />
            <div className='text-ellipsis'>
              {value?.first_name} {value?.last_name}
            </div>
          </div>
        )
      },
    },
    {
      key: 'order',
      name: 'SOURCE BRAND',
      className: 'min-w-48',
      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            <SolidStore size={16} className='mr-1 text-light-100' />
            <div className='text-ellipsis'>{value?.location?.brand?.name || '-'}</div>
          </div>
        )
      },
    },
    {
      key: 'order',
      name: 'SOURCE LOCATION',
      className: 'min-w-48',
      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            <SolidMapPin size={16} className='mr-1 text-light-100 ' />
            <div className='text-ellipsis'>{value?.location?.name || '-'}</div>
          </div>
        )
      },
    },
    {
      key: 'blocked_by',
      name: 'BLOCKED BY',
      className: 'w-160',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value?.user?.first_name || '-'}</div>
      },
    },
    {
      key: 'order',
      name: 'AMOUNT',
      align: 'right',
      className: 'w-24',
      sortable: true,
      sortDirection: blocklist.name === 'amount' ? blocklist.type : '',
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {value ? currency(value.grand_total).format() : '-'}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleBlocklistChange({
          name: 'amount',
          type: value,
        })
      },
    },
    {
      key: 'customer',
      name: 'BLOCKED SINCE',
      align: 'right',
      className: 'w-200',
      sortable: true,
      sortDirection: blocklist.name === 'blocked_since' ? blocklist.type : '',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            {value?.blocked_at ? <DateAndTime
              format='MMM DD @ hh:mm A'
              parse='YYYY-MM-DD HH:mm:ss'
              value={value.blocked_at}
              noExpired={true}
            /> : <span>-</span>}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleBlocklistChange({
          name: 'blocked_since',
          type: value,
        })
      },
    },
    {
      key: 'customer',
      name: 'STRIPE',
      align: 'right',
      className: 'w-120',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
          <Button 
            theme='text' 
            color='primary' 
            size='small' 
            onClick={(e) =>{ e.stopPropagation(); if(value?.stripe_customer_link) { window.open(value?.stripe_customer_link) }}}
          >
            View on Stripe
          </Button>
          </div>
        )
      }
    },
  ]

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={value => onPageNoChange?.(value)}
        clickedRow={row => clickedRow(row)}
      />
    </div>
  )
}
