import currency from 'currency.js'
import Table, { TOnSort } from 'components/Table'
import BadgeText from 'components/BadgeText'
import PersonName from 'components/PersonName'
import { PACING, TPacing, TSalesType, TTableForm } from '../helpers/constant'

export type TStatsByPeriodTableProp = {
  salesType: TSalesType
  form: TTableForm
  data: any
  loading?: boolean
  handleChange: (type: 'sort', value: any) => void
  goDetail: (item: any) => void
}

export default function StatsByPeriodTable({
  salesType,
  form,
  data,
  loading,
  handleChange,
  goDetail,
}: TStatsByPeriodTableProp) {
  const { sort_by, sort } = form
  const isAE = salesType === 'AE'

  const handleSort: TOnSort = (key, item) => {
    handleChange('sort', { type: item.value ? key : '', value: item.value })
  }

  const columns = [
    ...(isAE
      ? [
          {
            key: 'rank',
            name: 'RANK',
            className: 'w-16',
            align: 'center',
            custom: (value: number) => {
              if (value === 1) {
                return <div>🥇</div>
              }
              if (value === 2) {
                return <div>🥈</div>
              }
              if (value === 3) {
                return <div>🥉</div>
              }
              return <div>{value?.toString()}</div>
            },
          },
        ]
      : []),
    {
      key: 'closer',
      name: isAE ? 'ACCOUNT EXECUTIVE' : 'SOLUTION ENGINEER',
      custom: (item: any) => {
        const { profile_photo, first_name, last_name } = item
        return (
          <div className='flex items-center'>
            <PersonName
              className='w-full'
              avatar={profile_photo}
              name={`${first_name} ${last_name}`}
            />
          </div>
        )
      },
    },
    {
      key: 'effort',
      name: 'EFFORT',
      className: 'w-96',
      sortable: true,
      sortDirection: sort_by === 'effort' ? sort : '',
      onSortDirection: handleSort,
      align: 'right',
      custom: (value: string) =>
        isNaN(+value) ? <>-</> : <>{Number(value).toFixed(0)}</>,
    },
    {
      key: 'volume_amount',
      name: 'VOLUME',
      className: 'w-96',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'volume_amount' ? sort : '',
      onSortDirection: handleSort,
      custom: (value: any) => {
        return <div>{currency(value, { precision: 0 }).format()}</div>
      },
    },
    {
      key: 'actual_quantity',
      name: 'LAUNCH',
      className: 'w-88',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'actual_quantity' ? sort : '',
      onSortDirection: handleSort,
    },
    ...(salesType === 'AE'
      ? [
          {
            key: 'sales_goal',
            name: 'QUOTA',
            className: 'w-20',
            align: 'right',
          },
          {
            key: 'pacing',
            name: 'PACING',
            className: 'w-120',
            custom: (value: TPacing) => {
              const pacing = PACING[value]?.name || ''
              const color = PACING[value]?.color
              return <BadgeText value={pacing} color={color as any} />
            },
          },
        ]
      : []),
    {
      key: 'effort_bonus',
      name: 'EFFORT',
      className: 'w-112',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'effort_bonus' ? sort : '',
      onSortDirection: handleSort,
      custom: (value: string, item: any) => {
        if (isNaN(+value)) {
          return <div>-</div>
        }
        return <div>{currency(value, { precision: 0 }).format()}</div>
      },
    },
    {
      key: 'volume_commission_amount',
      name: 'LAUNCH $',
      className: 'w-96',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'volume_commission_amount' ? sort : '',
      onSortDirection: handleSort,
      custom: (value: any) => {
        return <div>{currency(value, { precision: 0 }).format()}</div>
      },
    },
    {
      key: 'setup_fee_commission_amount',
      name: 'SETUP $',
      className: 'w-96',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'setup_fee_commission_amount' ? sort : '',
      onSortDirection: handleSort,
      custom: (value: any) => {
        return <div>{currency(value, { precision: 0 }).format()}</div>
      },
    },
    {
      key: 'adjustment_amount',
      name: 'ADJ $',
      className: 'w-96',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'adjustment_amount' ? sort : '',
      onSortDirection: handleSort,
      custom: (value: any) => {
        return <div>({currency(value, { precision: 0 }).format()})</div>
      },
    },
    {
      key: 'total_amount',
      name: 'TOTAL $',
      className: 'w-112',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'total_amount' ? sort : '',
      onSortDirection: handleSort,
      custom: (value: any) => (
        <div>{currency(value, { negativePattern: '(!#)', precision: 0 }).format()}</div>
      ),
    },
  ]
  return (
    <div>
      <Table loading={loading} columns={columns} data={data} clickedRow={goDetail} />
    </div>
  )
}
