import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import { getAccessToken, removeAccessToken } from 'utils/auth'

export default function setupInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (!config?.headers) {
        throw new Error(
          "Expected 'config' and 'config.headers' not to be undefined",
        )
      }
      if (!config?.url?.includes('/login')) {
        config.headers.Authorization = `Bearer ${getAccessToken()}`
      }
      return config
    },
    error => {
      // 对请求错误做些什么
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const status = error.status || error.response?.status || 0
      // 401 及 user.status 为 inactive 状态的
      if (
        status === 401 ||
        (status === 403 && error.config?.url === 'profile')
      ) {
        toast.error('Please login')
        setTimeout(() => {
          removeAccessToken()
          window.location.replace('/login')
        }, 1000)
        return
      }
      let errData = error.response?.data || {
        message: error.message,
        errors: {},
      }
      errData = { ...errData, status }
      return Promise.reject(errData)
    },
  )
}
