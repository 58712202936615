/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement } from 'react'
import cn from 'classnames'

import { Dialog } from '@headlessui/react'
import Button, { TButtonProp } from 'components/Button'

import DrawCore from './core'

export interface TDrawProp {
  className?: string
  open: boolean
  maskClosable?: boolean // 点击遮罩层是否允许关闭
  toggle: () => void
  children: ReactElement | ReactElement[]
  title?: string | ReactElement
  footer?: null | ReactElement | ReactElement[]
  footerLeftElement?: ReactElement | ReactElement[]
  onOk?: () => void
  onCancel?: () => void
  isCancelBtn?: boolean
  okText?: string
  cancelText?: string
  okButtonProps?: Omit<TButtonProp, 'children' | 'onClick'>
  cancelButtonProps?: Omit<TButtonProp, 'children' | 'onClick'>
}

export default function Draw({
  className,
  open,
  maskClosable = true,
  title,
  toggle,
  footer,
  footerLeftElement,
  children,
  okText = 'CONFIRM',
  cancelText = 'CANCEL',
  isCancelBtn = true,
  okButtonProps,
  cancelButtonProps,
  onOk = toggle,
  onCancel = toggle,
}: TDrawProp) {
  const getDefaultFooter = () => {
    return (
      <>
        <div>{footerLeftElement}</div>
        <div className='flex'>
          {isCancelBtn && (
            <Button
              className='mr-4'
              color='secondary'
              {...cancelButtonProps}
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          )}
          <Button {...okButtonProps} onClick={onOk}>
            {okText}
          </Button>
        </div>
      </>
    )
  }

  return (
    <DrawCore
      className={cn(
        'flex flex-col flex-nowrap w-640 bg-dark-500 border border-dark-100',
        className,
      )}
      open={open}
      maskClosable={maskClosable}
      toggle={toggle}
    >
      <>
        {title && (
          <Dialog.Title
            as='h3'
            className='py-6 text-base font-bold text-light-700 text-center border-b border-dark-100'
          >
            {title}
          </Dialog.Title>
        )}
      </>
      <div className='flex-1 overflow-y-auto overflow-x-hidden p-8'>
        {children}
      </div>
      <>
        {footer !== null && (
          <div className='w-full flex justify-between items-center py-6 px-8 border-t border-dark-100 z-50'>
            {footer || getDefaultFooter()}
          </div>
        )}
      </>
    </DrawCore>
  )
}
