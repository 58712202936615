import type { TBonus } from '../helpers/constant'
import currency from 'currency.js'

export default function BonusCalculationSideBar({ bonus }: { bonus: TBonus }) {
  const {
    cancellation_quantity,
    adjustment_amount,
    total_bonus,
    effort,
    effort_bonus,
    effort_bonus_coe,
    volume_commission_amount,
    setup_fee_commission_amount,
  } = bonus
  return (
    <div
      className='w-400 border-r border-dark-100 text-light-300 text-xs'
      style={{ minHeight: 'calc(100vh - 72px)' }}
    >
      <div className='py-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='flex flex-col space-y-2'>
          <div className='font-bold'>Effort Bonus</div>
          <div className='text-light-500'>
            {effort === '-'
              ? '-'
              : `${currency(effort, { precision: 0 }).format()} pts x ${
                  effort_bonus_coe ? effort_bonus_coe * 100 : 0
                }%`}
          </div>
        </div>
        <div className='text-right font-bold text-lg text-light-700'>
          {effort_bonus === '-' ? '-' : currency(effort_bonus, { precision: 0 }).format()}
        </div>
      </div>
      <div className='py-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='flex flex-col space-y-2'>
          <div className='font-bold'>Launch Commission</div>
          <div className='text-light-500'>
            See Calculation
            {/* {currency(volume_amount, {  precision: 0 }).format()} x 15% */}
          </div>
        </div>
        <div className='text-right font-bold text-lg text-light-700'>
          {currency(volume_commission_amount, { precision: 0 }).format()}
        </div>
      </div>
      <div className='py-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='flex flex-col space-y-2'>
          <div className='font-bold'>Setup Fee Commission</div>
          <div className='text-light-500'>
            See Calculation
            {/* {currency(setup_fee_amount, { precision: 0 }).format()} x 10% */}
          </div>
        </div>
        <div className='text-right font-bold text-lg text-light-700'>
          {currency(setup_fee_commission_amount, { precision: 0 }).format()}
        </div>
      </div>
      <div className='py-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='flex flex-col space-y-2'>
          <div className='font-bold'>Adjustment</div>
          <div className='text-light-500'>
            Early cancellation x {cancellation_quantity}
          </div>
        </div>
        <div className='text-right font-bold text-lg text-light-700'>
          - {currency(adjustment_amount, { precision: 0 }).format()}
        </div>
      </div>
      <div className='py-4 px-10 flex justify-between items-center'>
        <div className='font-bold'>Total Bonus</div>
        <div className='text-right font-bold text-lg text-green'>
          {currency(total_bonus, { precision: 0 }).format()}
        </div>
      </div>
    </div>
  )
}
