import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AdminLayout } from 'components/Layout'
import PageLoading from 'components/PageLoading'
import LogisticItemDrawer from 'pages/Accounts/components/LogisticItemDrawer'
import LogisticsFilter from './components/LogisticsFilter'
import LogisticsTable from './components/LogisticsTable'
import { useAppDispatch, useAppSelector } from 'redux/hooks'

import {
  setHideShipped,
  getLogisticsList,
  setPage,
  setTotal,
  setPageSize,
  setData,
  setOrder,
} from 'redux/logistics'

import { TRowProp } from 'components/Table'

export default function LogisticsPage() {
  const dispatch = useAppDispatch()
  const { hideShipped, keyword, sortData, page, pageSize } = useAppSelector(
    state => state.logistics,
  )
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [curItem, setCurItem] = useState<Record<string, any>>({})
  const [onboardingId, setOnboardingId] = useState('')

  const initParams = {
    keyword,
    hide_shipped: hideShipped,
    sort_by: sortData.sort_by,
    sort: sortData.sort,
    page,
    per_page: pageSize,
  }

  const handleFormChange = (key: string, value: any) => {
    const params = {
      ...initParams,
    }
    if (key === 'page') {
      dispatch(setPage(value))
      params.page = value
    }
    if (key === 'hide_shipped') {
      const hide_shipped = value ? '1' : '0'
      dispatch(setHideShipped(hide_shipped))
      params.hide_shipped = hide_shipped
    }
    if (key === 'sort') {
      dispatch(
        setOrder({
          sort_by: value.type,
          sort: value.value,
        }),
      )
      params.sort_by = value.type
      params.sort = value.value
    }
    getTable(params)
  }

  const getTable = async (params?: any) => {
    // 请求 api
    setLoading(true)
    try {
      const res = await getLogisticsList({
        ...initParams,
        ...params,
      })
      dispatch(setTotal(res.total))
      dispatch(setPageSize(res.per_page))
      dispatch(setData(res.data))
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }

  const clickedRow = (row: TRowProp) => {
    setCurItem(row)
    setShow(true)
    setOnboardingId(row?.onboarding_id)
  }

  useEffect(() => {
    getTable()
  }, [])

  return (
    <AdminLayout>
      <div
        style={{ maxHeight: loading ? 'calc(100vh - 80px)' : 'none' }}
        className={`relative ${loading && 'overflow-y-hidden'}`}
      >
        {loading && (
          <div className='bg-dark-900 w-full h-full absolute z-40'>
            <PageLoading />
          </div>
        )}

        <div className='px-10 py-8'>
          <LogisticsFilter handleFormChange={handleFormChange} />
          <div className='mt-8'>
            <LogisticsTable
              loading={loading}
              handleFormChange={handleFormChange}
              clickedRow={clickedRow}
            />
          </div>
        </div>
        <LogisticItemDrawer
          title='EDIT LOGISTIC ITEM'
          show={show}
          curItem={curItem}
          onboardingId={onboardingId}
          toggle={() => setShow(!show)}
        />
      </div>
    </AdminLayout>
  )
}
