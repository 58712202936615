export const RUSHABLE_FREE_PLAN_ID = 108
export const RUSHABLE_MONTHLY_PLAN_ID = 102
export const RUSHABLE_ANNUAL_PLAN_ID = 107

export const SOURCEMAP = {
  self: 'Self-gen 🙌',
  sdr: 'By SDR',
  referral: 'Referral',
  existing: 'Existing',
  inbound: 'Inbound',
  affiliate: 'Affiliate',
}

export const SOURCEMAP2 = {
  self: 'By SELF',
  sdr: 'By SDR',
  referral: 'Referral',
  existing: 'Existing',
  inbound: 'Inbound',
  affiliate: 'Affiliate',
}
