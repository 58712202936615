import { IOptions } from 'components/Select/help'

export const StageOptions = [
  {
    label: 'All Stages',
    value: 'all',
  },
  {
    label: 'In Sales',
    value: 'in_sales',
  },
  {
    label: 'Onboarding',
    value: 'onboarding',
  },
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Cancelling',
    value: 'cancelling',
  },
  {
    label: 'Churned',
    value: 'churned',
  },
  {
    label: 'Withdrawal',
    value: 'withdrawal',
  },
]

export const OnboardingIssueOptions = [
  {
    label: 'No specification',
    value: '0',
  },
  {
    label: 'Has onboarding issue',
    value: '1',
  },
]

export const GBPLinkOptions = [
  {
    label: 'No specification',
    value: 'no',
  },
  {
    label: 'GBP website issue',
    value: 'website',
  },
  {
    label: 'GBP ordering issue',
    value: 'ordering',
  },
]
// pickup/delivery/dine_in/automated/marketing
export const FeatureOffOptions = [
  {
    label: 'No specification',
    value: 'no',
  },
  {
    label: 'Pickup off',
    value: 'pickup',
  },
  {
    label: 'Delivery off',
    value: 'delivery',
  },
  {
    label: 'Dine-in off',
    value: 'dine_in',
  },
  {
    label: 'Automated campaign off',
    value: 'automated',
  },
  {
    label: 'Marketing phone off',
    value: 'marketing',
  },
]

export type TFilterItem = {
  className?: string
  value: string
  options?: IOptions[]
  onChange: (item: any, rest: any) => void
}
