export const LEADSOURCE = [
  { value: 'self', label: 'By SELF' },
  { value: 'sdr', label: 'By SDR' },
  { value: 'outbound', label: 'Outbound' },
  { value: 'inbound', label: 'Inbound' },
  { value: 'referral', label: 'Referral' },
  { value: 'existing', label: 'Existing' },
  { value: 'affiliate', label: 'Affiliate' },
]
// solution engineer lvan 的id
export const LVAN_ID = 24

export const PRIMARYLANGUAGE = [
  { value: 'english', label: 'English' },
  { value: 'chinese', label: 'Chinese' },
  { value: 'spanish', label: 'Spanish' },
]

export const DAYS = [
  { label: 'SUN', abbr: 'sun' },
  { label: 'MON', abbr: 'mon' },
  { label: 'TUE', abbr: 'tue' },
  { label: 'WED', abbr: 'wed' },
  { label: 'THU', abbr: 'thu' },
  { label: 'FRI', abbr: 'fri' },
  { label: 'SAT', abbr: 'sat' },
]
