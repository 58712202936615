export const DOMAIN = process.env.REACT_APP_API_DOMAIN
export const MAPBOX_ACCESSTOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
export const ONBOARDING_ONBOARD_URL = process.env.REACT_APP_ONBOARDING_URL + '/onboard/'
export const ONBOARDING_TRANSFER_URL = process.env.REACT_APP_ONBOARDING_URL + '/transfer/'

export const API_URL = DOMAIN + '/dashboard/'

export const TOKEN_KEY = 'access_token'
export const CRM_ROLE_ID = 'crm_role_id'

export const RUSHABLE_TIMEZONE = 'America/Chicago'
