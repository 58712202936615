import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Logo, SolidUserCircled } from '@rushable/icons'
import { useAppSelector } from 'redux/hooks'
import useRoutePermission from 'hooks/useRoutePermissions'
import { NAVMENU } from 'routes/baseRoute'
import Button from 'components/Button'
import Profile from './Profile'
import NavHover from './NavHover'

export default function MenuNavs(): JSX.Element {
  const navigate = useNavigate()
  const profile = useAppSelector(state => state.auth.profile)
  const [show, setShow] = useState(false)
  const permissionMenu = useRoutePermission(NAVMENU)

  return (
    <>
      <div className='sticky z-50 top-0 bg-dark-900 flex items-center py-4 px-10 border-b text-dark-100'>
        <Button theme='text' onClick={() => navigate('/')}>
          <Logo style={{ mixBlendMode: 'screen' }} />
        </Button>
        <ul className='flex-1 flex ml-16 h-5 text-sm text-light-300'>
          {permissionMenu.map(item => {
            if (item.name === 'Support' || item.name == 'Sales') {
              return <NavHover navName={item.name} key={item.name} />
            }

            return (
              <li key={item.path} className='mr-6'>
                <NavLink
                  to={item.path}
                  className={v => (v.isActive ? 'text-light-700' : '')}
                >
                  {item.name}
                </NavLink>
              </li>
            )
          })}
        </ul>
        <button
          className='h-10 min-w-120 p-1 flex items-center text-light-700 rounded-32 border-2 border-light-700'
          onClick={() => setShow(true)}
        >
          {profile?.userPhoto ? (
            <div className='inline-flex rounded-full	overflow-hidden'>
              <img width={32} src={profile.userPhoto} alt='' />
            </div>
          ) : (
            <SolidUserCircled size={32} />
          )}
          <span className='text-light-700 text-sm mx-2'>{profile?.userName}</span>
        </button>
      </div>
      <Profile
        profile={profile}
        avatar={profile?.userPhoto || ''}
        show={show}
        toggle={() => setShow(false)}
      />
    </>
  )
}
