import { createAction } from '@reduxjs/toolkit'

import axiosInstance from 'utils/axios'
import { TSort, TData } from 'pages/Logistics/helpers/constant'

export const setHideShipped = createAction<string>('logistics/setHideShipped')
export const setPage = createAction<number>('logistics/setPage')
export const setPageSize = createAction<number>('logistics/setPageSize')
export const setTotal = createAction<number>('logistics/setTotal')
export const setData = createAction<TData>('logistics/setData')
export const setOrder = createAction<TSort>('logistics/setOrder')

export async function getLogisticsList(params: any) {
  const response = await axiosInstance.request({
    url: 'onboarding_logistics',
    method: 'get',
    params,
    cache: { ignoreCache: true },
  })
  return response.data
}
