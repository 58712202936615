import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'

import { AdminLayout } from 'components/Layout'
import AllTable from './components/AllTable'

import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getAffiliateCRMTeams, setAllFilterForm } from 'redux/affiliate'
import { setNeedReload } from 'redux/account'

export default function AffiliatePage() {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const { allData } = useAppSelector(state => state.affiliate)
  const { needReload } = useAppSelector(state => state.account)
  const [loading, setLoading] = useState(false)

  const handleFilterChange = (type: string, value: any) => {
    let newForm = { ...allData.filterForm }
    if (type === 'sort') {
      newForm = {
        ...allData.filterForm,
        sort_by: value.value ? value.type : '',
        sort: value.value,
      }
    } else {
      newForm = {
        ...allData.filterForm,
        [type]: value,
      }
    }
    getTable({ ...newForm, page: 1 })
  }

  const getTable = async (form?: any) => {
    // 请求 api
    setLoading(true)
    try {
      // needReload 判断从 signup 跳转列表时需不需要重新请求
      const res = await getAffiliateCRMTeams(params.id, form, needReload)
      dispatch(setNeedReload(false))
      dispatch(
        setAllFilterForm({
          total: res.total,
          data: res.data,
          filterForm: form,
        }),
      )
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }

  const onPageNoChange = (page: number) => {
    getTable({ ...allData.filterForm, page })
  }

  useEffect(() => {
    getTable(allData.filterForm)
  }, [])

  return (
    <AdminLayout>
      <div className='px-10 py-8'>
        <AllTable
          loading={loading}
          total={allData.total}
          current={allData.filterForm.page}
          pageSize={allData.filterForm.per_page}
          data={allData.data}
          handleFilterChange={handleFilterChange}
          onPageNoChange={onPageNoChange}
          goToCommission={() => {
            navigate(`/affiliate/my-commission/${params.id}`)
          }}
        />
      </div>
    </AdminLayout>
  )
}
