import React from 'react'
import cn from 'classnames'
import moment from 'moment'

export type TPayoutScheduleProp = {
  color?: 'green' | 'blue'
  payoutType: 'daily' | 'weekly'
  payoutRanges: string
  payoutDate: string
  intoAccountDate: string
}

export default function PayoutSchedule({
  color = 'green',
  payoutDate,
  payoutRanges,
  payoutType,
  intoAccountDate,
}: TPayoutScheduleProp): JSX.Element {
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
  return (
    <div className='relative flex space-x-4 items-center'>
      <div className={cn('flex justify-center space-x-2')}>
        {days.map((day, index) => {
          return (
            <div
              key={index}
              className={cn(
                'flex flex-col items-center',
                payoutType === 'weekly'
                  ? 'text-light-700'
                  : payoutType === 'daily' &&
                    Number(
                      moment(payoutRanges).add(index, 'd').format('DD'),
                    ) === Number(moment(payoutDate).format('DD'))
                  ? 'text-light-700'
                  : 'text-light-100',
              )}
            >
              <div className='font-bold text-xs'>{day}</div>
              <div className='text-xxs-z mt-[-4px]'>
                {moment(payoutRanges).add(index, 'd').format('DD')}
              </div>
            </div>
          )
        })}
      </div>
      <div
        className={cn(
          'w-9 pb-0.5 flex flex-col items-center border border-solid border-dark-100 rounded',
          `text-${color}`,
        )}
      >
        <div className=' font-bold text-xs'>
          {moment(intoAccountDate).format('ddd')}
        </div>
        <div className='text-xxs-z mt-[-4px]'>
          {moment(intoAccountDate).format('DD')}
        </div>
      </div>
    </div>
  )
}
