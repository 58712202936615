import React from 'react'

import cn from 'classnames'

import {
  LinedCar,
  LinedStore,
  LinedUtensils,
  LinedPaperPlane,
  LinedPhone,
  LinedPro,
} from '@rushable/icons'

export type TFeatureTrackingProp = {
  className?: string
  pickup?: boolean
  delivery?: boolean
  dineIn?: boolean
  email?: boolean
  phone?: boolean
  proPackageStatus?: string
}

export default function FeatureTracking({
  className = '',
  pickup,
  delivery,
  dineIn,
  email,
  phone,
  proPackageStatus = 'Inactive',
}: TFeatureTrackingProp): JSX.Element {
  return (
    <div className={cn(className, 'flex space-x-2  max-w-max')}>
      <LinedStore
        className={`${pickup ? 'text-light-500' : 'text-dark-100'}`}
        size={20}
      />
      <LinedCar
        className={`${delivery ? 'text-light-500' : 'text-dark-100'}`}
        size={20}
      />
      <LinedUtensils
        className={`${dineIn ? 'text-light-500' : 'text-dark-100'}`}
        size={20}
      />
      <LinedPaperPlane
        className={`${email ? 'text-light-500' : 'text-dark-100'}`}
        size={20}
      />
      <LinedPhone
        className={`${phone ? 'text-light-500' : 'text-dark-100'}`}
        size={20}
      />
      <LinedPro
        className={`${
          !!proPackageStatus
            ? proPackageStatus === 'Active'
              ? 'text-light-500'
              : proPackageStatus === 'Inactive'
              ? 'text-dark-100'
              : 'text-purple'
            : 'text-dark-100'
        }`}
        size={20}
      />
    </div>
  )
}
