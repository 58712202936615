import type { Tfee } from './types'
export * from './types'

export const getWebsiteValue = (
  website_preference: string,
  has_existing_domain: number,
  delegate_dns_or_website: number,
): string => {
  // 直接取值不需要考虑 null / undefined
  return `${website_preference}-${has_existing_domain}-${delegate_dns_or_website}`
}

export const getGBPValue = (
  has_gmb_ownership: number,
  need_help_regain_gmb: number,
  delegate_gmb_update: number,
): string => {
  // 需要考虑 null / undefined 情况
  const ownership = has_gmb_ownership === null ? 'null' : Number(!!has_gmb_ownership)

  const gmb_update = delegate_gmb_update === null ? 'null' : Number(!!delegate_gmb_update)

  return `${ownership}-${need_help_regain_gmb || 0}-${gmb_update}`
}

// format products
export const formatProduct = (products: Tfee[]) => {
  return products?.map(item => ({
    subscription_amount: +item.subAmount,
    subscription_discount: +item.subDiscount,
    dispatch_fee: +item.dispatchFee,
    signup_fee: +item.setupFee,
    signup_fee_discount: +item.setupFeeDiscount,
    mention_free_website: item.isFreeWebsite,
    include_pro_package: item.includeProPackage,
    pro_package_trial_months: item.proPackageTrialMonths || null,
    type: 'enssential_online_presence',
  }))
}
