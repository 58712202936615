import axios from 'axios'
import { API_URL } from 'data/constant'
import { setupCache } from 'axios-cache-adapter'

import setupInterceptors from './interceptors'

export const cache = setupCache({
  maxAge: 30 * 1000,
  exclude: { query: false },
})

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30 * 1000,
  adapter: cache.adapter,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json; charset=utf-8',
  },
})

setupInterceptors(axiosInstance)

export default axiosInstance
