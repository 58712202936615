import { forwardRef, HTMLAttributes, ForwardedRef, ReactElement, useRef } from 'react'
import cn from 'classnames'
import Label from 'components/Label'
import FieldError from 'components/FieldError'
import { useCombinedRefs } from 'hooks/useCombinedRefs'
import {
  onMoneyKeyDown,
  onPhoneKeyUp,
  onPhoneKeyDown,
  formatPhoneNumberPart,
  isNumber,
} from 'utils/digit'

interface InputProps extends HTMLAttributes<HTMLInputElement> {
  required?: boolean
  textAlign?: 'left' | 'center' | 'right'
  disabled?: boolean
  label?: string
  name: string
  value: string | number
  maxLength?: number
  errorText?: string
  autoComplete?: string
  className?: string
  inputClassName?: string
  iconBefore?: ReactElement
  iconAfter?: ReactElement
  isDigit?: boolean
  decimalPlaces?: number
  min?: number // 数字情况下
  max?: number
  type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url'
  onChange?: (event: TInputEvent) => void
  onChangePhone?: (value: string) => void
  onFocus?: (e: TInputEvent) => void
  onBlur?: (e: TInputEvent) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}
type InputRef = ForwardedRef<HTMLInputElement>

const styles = {
  iconStyle: 'absolute h-full top-0 flex items-center',
  inputStyle:
    'w-full py-2 text-sm border-2 rounded-lg  placeholder-light-100 text-light-700 resize-none focus:outline-none focus:border-light-700 focus:bg-dark-700 disabled:text-light-500',
}

const SingleInput = forwardRef(
  (
    {
      required,
      textAlign = 'left',
      disabled,
      label,
      name,
      value,
      maxLength,
      errorText,
      autoComplete = 'on',
      className = '',
      inputClassName,
      iconBefore,
      iconAfter,
      isDigit,
      decimalPlaces,
      type,
      min,
      max,
      onChange,
      onChangePhone,
      onFocus,
      onBlur,
      onKeyDown,
      ...prop
    }: InputProps,
    forwardedRef: InputRef,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const combineRef = useCombinedRefs(forwardedRef, inputRef)
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(e)
      if (isDigit) {
        onMoneyKeyDown(e)
      }
      if (onChangePhone) {
        onPhoneKeyDown(e)
      }
    }
    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (onChangePhone) {
        // @ts-ignore
        const ph = onPhoneKeyUp(e, e.target.value)
        onChangePhone?.(ph)
      }
    }
    const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      onFocus?.(e)
      if (isDigit) {
        inputRef?.current?.select()
      }
    }
    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      onBlur?.(e)
      if (isDigit) {
        let value: string | number = e.target.value
        if (min === 0 || min) {
          value = +value < min ? min : value
        }
        if (max === 0 || max) {
          value = +value > max ? max : value
        }
        const newVal = Number(value).toFixed(decimalPlaces)
        if (!isNaN(Number(newVal))) {
          onChange?.({
            ...e,
            target: {
              ...e.target,
              value: newVal,
            },
          })
        }
      }
    }

    let formatValue = value
    if (onChangePhone && isNumber(value)) {
      formatValue = formatPhoneNumberPart(value)
    }
    const cls = className.includes('w-') ? className : `w-full ${className}`
    return (
      <div className={cn('ru-SingleInput', cls)}>
        {label && (
          <div className='mb-2'>
            <Label htmlFor={name}>{label}</Label>
          </div>
        )}
        <div className='relative'>
          {iconBefore && (
            <div className={cn(styles.iconStyle, 'left-4')}>{iconBefore}</div>
          )}
          {iconAfter && (
            <div className={cn(styles.iconStyle, 'right-4')}>{iconAfter}</div>
          )}
          <input
            className={cn(
              styles.inputStyle,
              { 'text-left': textAlign === 'left' },
              { 'text-right': textAlign === 'right' },
              { 'text-center': textAlign === 'center' },
              !!errorText ? 'border-red' : 'border-dark-300',
              disabled
                ? 'bg-dark-700 border-dark-700 text-light-500'
                : 'bg-dark-300 hover:outline-none hover:border-light-700',
              !!iconBefore ? 'pl-10' : 'pl-4',
              !!iconAfter ? 'pr-10' : 'pr-4',
              inputClassName,
            )}
            aria-required={required}
            value={formatValue}
            disabled={disabled}
            id={name}
            name={name}
            ref={combineRef}
            maxLength={maxLength}
            type={type}
            autoComplete={autoComplete}
            onChange={e => {
              onChange?.(e)
              if (onChangePhone) {
                onChangePhone?.(e.target.value)
              }
            }}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...prop}
          />
        </div>
        {!!errorText && <FieldError className='mt-1'>{errorText}</FieldError>}
      </div>
    )
  },
)

export default SingleInput
