import { useEffect, useState } from 'react'
import currency from 'currency.js'
import { useParams, useSearchParams } from 'react-router-dom'

import Table, { TColumnProp, TOnSort } from 'components/Table'
import DateAndTime from 'components/DateAndTime'
import StageProgress from 'components/StageProgress'

import type { TTabKey } from '../helpers/constant'
import { SOURCEMAP } from 'utils/constant'

import { getNewSignups, getSetup, getCancellation } from 'redux/sales'

type TSortData = {
  sort_by: string
  sort: string
}

export default function BonusCalculationTable({ tabKey }: { tabKey: TTabKey }) {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const option = searchParams.get('option')
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [sortData, setSortData] = useState<TSortData>({ sort_by: '', sort: '' })

  useEffect(() => {
    handleGetTableData()
  }, [page, sortData])

  const handleGetTableData = async () => {
    setLoading(true)
    let api
    if (tabKey === 'launch') {
      api = getNewSignups
    }
    if (tabKey === 'setup_fee') {
      api = getSetup
    }
    if (tabKey === 'early_cancel') {
      api = getCancellation
    }
    if (!api) {
      return
    }
    try {
      const res = await api({
        id: id!,
        month: option!,
        sort_by: sortData.sort_by,
        sort: sortData.sort,
        page,
      })
      setPageSize(res.per_page)
      setTotal(res.total)
      setTableData(res.data)
    } catch (e: any) {
    } finally {
      setLoading(false)
    }
  }

  const handleSort: TOnSort = (key, item) => {
    setSortData({ sort_by: item.value ? key : '', sort: item.value })
  }

  const getColumns = (): any => {
    const baseColumns = [
      {
        key: 'location',
        name: 'BRAND',
        custom: (location: any) => {
          return <div>{location?.brand?.name || ''}</div>
        },
      },
      {
        key: 'location',
        name: 'LOCATION',
        custom: (location: any) => {
          return <div>{location?.name || ''}</div>
        },
      },
      {
        key: 'source',
        name: 'SOURCE',
        sortable: true,
        sortDirection: sortData.sort_by === 'source' ? sortData.sort : '',
        onSortDirection: handleSort,
        className: 'w-104',
        custom: (value: any) => {
          // @ts-ignore
          return <div>{SOURCEMAP[value] || '-'}</div>
        },
      },
    ]
    switch (tabKey) {
      case 'launch':
        return [
          ...baseColumns,
          getColumnByKey('VOLUME', sortData, handleSort),
          getColumnByKey('COMMISSION', sortData, handleSort),
          getColumnByKey('SIGNUP', sortData, handleSort),
          getColumnByKey('LAUNCH', sortData, handleSort),
        ]
      case 'setup_fee':
        return [
          ...baseColumns,
          getColumnByKey('SETUP', sortData, handleSort),
          getColumnByKey('COMMISSION', sortData, handleSort),
          getColumnByKey('LAUNCH', sortData, handleSort),
          getColumnByKey('STAGE', sortData, handleSort),
        ]
      case 'early_cancel':
        return [
          ...baseColumns,
          getColumnByKey('SIGNUP', sortData, handleSort),
          getColumnByKey('LAUNCH', sortData, handleSort),
          getColumnByKey('CANCEL', sortData, handleSort),
          getColumnByKey('STAGE', sortData, handleSort),
        ]
    }
  }

  return (
    <div className='mt-8'>
      <Table
        loading={loading}
        current={page}
        columns={getColumns()}
        clickedRow={row => {
          window.open(`${window.location.origin}/accounts/detail/${row.id}`)
        }}
        data={tableData}
        total={total}
        resizePageSize={pageSize}
        onPageNoChange={value => setPage(value)}
      />
    </div>
  )
}

function getColumnByKey(
  key: string,
  sortData: TSortData,
  handleSort: TOnSort,
): TColumnProp {
  const { sort_by, sort } = sortData
  switch (key) {
    case 'SETUP':
      return {
        key: 'setup',
        name: 'SETUP',
        sortable: true,
        sortDirection: sort_by === 'setup' ? sort : '',
        onSortDirection: handleSort,
        align: 'right',
        className: 'w-104',
        custom: (value: any) => {
          return <div>{currency(value).format()}</div>
        },
      }
    case 'VOLUME':
      return {
        key: 'volume_amount',
        name: 'VOLUME',
        sortable: true,
        sortDirection: sort_by === 'volume_amount' ? sort : '',
        onSortDirection: handleSort,
        align: 'right',
        className: 'w-30',
        custom: (value: any) => {
          return <div>{currency(value, { precision: 0 }).format()}</div>
        },
      }
    case 'SIGNUP':
      return {
        key: 'ready_at',
        name: 'SIGNUP',
        sortable: true,
        sortDirection: sort_by === 'ready_at' ? sort : '',
        onSortDirection: handleSort,
        align: 'right',
        className: 'w-120',
        custom: (value: any) => {
          return (
            <div className='flex justify-end'>
              {value ? (
                <DateAndTime
                  className='text-right text-light-500'
                  value={value}
                  parse='YYYY-MM-DD HH:mm:ss'
                  format='MMM DD, YYYY'
                  noExpired={true}
                />
              ) : (
                '-'
              )}
            </div>
          )
        },
      }
    case 'LAUNCH':
      return {
        key: 'done_at',
        name: 'LAUNCH',
        sortable: true,
        sortDirection: sort_by === 'done_at' ? sort : '',
        onSortDirection: handleSort,
        align: 'right',
        className: 'w-120',
        custom: (value: any) => {
          return (
            <div className='flex justify-end'>
              {value ? (
                <DateAndTime
                  className='text-right text-light-500'
                  value={value}
                  parse='YYYY-MM-DD HH:mm:ss'
                  format='MMM DD, YYYY'
                  noExpired={true}
                />
              ) : (
                '-'
              )}
            </div>
          )
        },
      }

    case 'CANCEL':
      return {
        key: 'cancel',
        name: 'CANCEL',
        sortable: true,
        sortDirection: sort_by === 'cancel' ? sort : '',
        onSortDirection: handleSort,
        align: 'right',
        className: 'w-120',
        custom: (value: any, item: any) => {
          const endAt = item?.location?.stripe_subscription_ends_at
          return (
            <div className='flex justify-end'>
              <DateAndTime
                className='text-right text-light-500'
                value={endAt || ''}
                parse='YYYY-MM-DD HH:mm:ss'
                format='MMM DD, YYYY'
                noExpired={true}
              />
            </div>
          )
        },
      }
    case 'STAGE':
      return {
        key: 'stage',
        name: 'STAGE',
        className: 'w-120',
        tdNoHidden: true,
        sortable: true,
        sortDirection: sort_by === 'stage' ? sort : '',
        onSortDirection: handleSort,
        align: 'right',
        custom: (value: any, row: any) => {
          return (
            <StageProgress
              className='ml-2'
              statusName={value}
              popTop={row.index >= 8}
              onboarding={row.onboarding}
              signupForm={row.signup_form}
            />
          )
        },
      }
    case 'COMMISSION':
      return {
        key: 'commission',
        name: 'COMMISSION',
        className: 'w-120',
        tdNoHidden: true,
        sortable: true,
        sortDirection: sort_by === 'commission' ? sort : '',
        onSortDirection: handleSort,
        align: 'right',
        custom: (value: any) => <div>{value}%</div>,
      }
    default:
      return { key: '', name: '' }
  }
}
