import { Routes, Route, useLocation } from 'react-router-dom'
import { SALES_AE } from 'routes/constant'
import SalesAEPage from './SalesPage'
import SalesDetail from './SalesDetail'

export default function SalesPage() {
  const { pathname } = useLocation()
  const key = pathname.includes(SALES_AE) ? 'AE' : 'SE'
  return (
    <Routes>
      <Route path='' element={<SalesAEPage key={key} salesType={key} />}>
        <Route path='detail/:id' element={<SalesDetail salesType={key} />} />
      </Route>
    </Routes>
  )
}
