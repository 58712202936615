import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { LinedGlobe, LinedStar } from '@rushable/icons'
import { useParams } from 'react-router-dom'

import Label from 'components/Label'
import UserTable from '../../components/UserTable'
import PremiumAddOnTable from '../../components/PremiumAddOnTable'
import EditInput from './EditInput'
import SuccessCall from './SuccessCall'

// 下面两个和 onboarding 共用组件
import CheckList from '../OnboardingTab/CheckList'
import Communication from '../OnboardingTab/Communication'
import {
  getSuccessInfoApi,
  updateSuccessApi,
  getCommentApi,
  getAccountDetailApi,
} from 'redux/account'
import { useAppDispatch } from 'redux/hooks'

export default function SuccessPage() {
  const urlParams = useParams()
  const dispatch = useAppDispatch()
  const [onboardingInfo, setOnboarding] = useState<Record<string, any>>({})
  const [accountUsers, setAccountUsers] = useState<any>([])
  const [commentInfo, setCommentInfo] = useState<any>({
    data: [],
    cursor: '',
    showCommentBtn: false,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getSuccessInfo()
    getCommentInfo()
  }, [])

  const getCommentInfo = async (cursor?: string) => {
    try {
      const res = await getCommentApi({
        account_id: urlParams.id!,
        cursor: cursor === '' ? cursor : commentInfo.cursor,
      })
      setCommentInfo({
        data: cursor === '' ? [...res.data] : [...commentInfo.data, ...res.data],
        cursor: res.next_cursor,
        showCommentBtn: !!res.next_cursor,
      })
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  const getSuccessInfo = async () => {
    try {
      const res = await getSuccessInfoApi(urlParams.id!)
      setOnboarding(res)
      setAccountUsers(res.users || [])
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const updateSuccess = async (params: any, errorCb?: () => void) => {
    try {
      const res = await updateSuccessApi(urlParams.id!, params)
      getSuccessInfo()
      toast.success(res.message)
    } catch (error: any) {
      toast.error(error.message)
      errorCb && errorCb()
    }
  }

  return (
    <div>
      <Label className='mb-2'>ACCOUNT USERS</Label>
      <UserTable
        className='py-2'
        type='success'
        items={accountUsers}
        onCheck={(checked: boolean, item: any, index: number) => {
          accountUsers[index].loading = true
          setAccountUsers([...accountUsers])
          updateSuccess(
            {
              employment_id: item.employment_id,
              person_of_contact: checked ? 1 : 0,
            },
            () => {
              // api 失败时停止loading
              accountUsers[index].loading = false
              setAccountUsers([...accountUsers])
            },
          )
        }}
      />
      <div className='flex space-x-8 my-8'>
        <EditInput
          type='website'
          icon={<LinedGlobe size={16} className='text-light-300' />}
          label='GBP WEBSITE TRACKING'
          value={
            onboardingInfo?.location_google_connection?.proposed_gbp_website_link || ''
          }
          onSuccess={() => getSuccessInfo()}
        />
        <EditInput
          type='ordering'
          icon={<LinedStar size={16} className='text-light-300' />}
          label='GBP ORDERING LINK TRACKING'
          value={
            onboardingInfo?.location_google_connection?.proposed_gbp_ordering_link || ''
          }
          onSuccess={() => getSuccessInfo()}
        />
      </div>
      <div className='flex space-x-8 my-8'>
        <EditInput
          type='pos_note'
          label='POS Notes'
          value={onboardingInfo?.pos_note || ''}
          onSuccess={() => {
            getSuccessInfo()
            // 刷新右侧sidebar数据
            dispatch(getAccountDetailApi(urlParams.id!))
          }}
        />
        <div className='flex-1' />
      </div>
      <PremiumAddOnTable
        title='SUBSCRIBED DEVICE'
        onboardingId={onboardingInfo.id}
        className='mb-8'
        onSuccess={getSuccessInfo}
        items={onboardingInfo.onboarding_logistics || []}
      />
      <div className='mt-8 flex'>
        <div className='flex-1 mr-6'>
          <SuccessCall
            onboardingInfo={onboardingInfo}
            updateSuccess={updateSuccess}
            onSuccess={getSuccessInfo}
          />
          <CheckList
            tab='Success'
            onboardingId={onboardingInfo.id}
            onboardingInfo={onboardingInfo}
            onSuccess={getSuccessInfo}
          />
        </div>
        <div className='flex-1'>
          <Communication
            tab='Success'
            onboardingId={onboardingInfo.id}
            attention={onboardingInfo.attention}
            logs={commentInfo.data}
            showCommentBtn={commentInfo.showCommentBtn}
            loading={loading}
            onSuccess={getSuccessInfo}
            onUpdateComment={value => {
              value !== '' && setLoading(true)
              getCommentInfo(value)
            }}
          />
        </div>
      </div>
    </div>
  )
}
