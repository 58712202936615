import cn from 'classnames'
import Table from 'components/Table'
import Switch from 'components/Switch'
import { formatPhoneNumberPart } from 'utils/digit'
import type { TColumnProp } from 'components/Table'

export type TUserItems = {
  id: number
  name: string
  role: string
  email: string
  phone: string
  language: string
  person_of_contact: number
  onboarding_point_of_contact: number
  success_point_of_contact: number
  loading?: boolean
}

export type TUserTableProps = {
  className?: string
  hasCheck?: boolean
  type: 'onboarding' | 'success'
  items: TUserItems[]
  disabled?: boolean
  onCheck?: (checked: boolean, item: any, index: number) => void
}

// 'brand_admin','location_owner','manager','staff','channel_partner','coordinator'
const roleMap: any = {
  brand_admin: 'Brand Admin',
  location_owner: 'Location Admin',
  manager: 'Manager',
  staff: 'Staff',
  channel_partner: 'Channel Partner',
  coordinator: 'Coordinator',
}

export default function UserTable({
  className,
  type,
  hasCheck = true,
  items,
  disabled,
  onCheck,
}: TUserTableProps): JSX.Element | null {
  if (!items || items.length === 0) {
    return null
  }
  let columns: TColumnProp[] = [
    {
      key: 'name',
      name: 'USER',
      custom: (value, item) => {
        return (
          <span>
            {item.first_name} {item.last_name}
          </span>
        )
      },
    },
    {
      key: 'role',
      name: 'ROLE',
      className: 'w-40',
      custom: value => roleMap[value],
    },
    {
      key: 'email',
      name: 'EMAIL',
    },
    {
      key: 'phone',
      name: 'CELLPHONE',
      className: 'w-152',
      custom: value => formatPhoneNumberPart(value),
    },
    {
      key: 'language',
      name: 'PRIMARY LANGUAGE',
      className: 'w-152',
      align: 'right',
    },
  ]
  if (hasCheck) {
    columns.push({
      key: 'person_of_contact',
      name: 'POC',
      className: 'w-24',
      align: 'right',
      custom: (value: boolean, item: any, index: number) => {
        const checkedKey =
          type === 'onboarding'
            ? 'onboarding_point_of_contact'
            : 'success_point_of_contact'
        return (
          <Switch
            className='justify-end'
            loading={item.loading}
            checked={!!item[checkedKey]}
            disabled={disabled}
            onChange={e => {
              onCheck && onCheck(e.target.checked, item, index)
            }}
          />
        )
      },
    })
  }
  return (
    <div
      className={cn(
        'ru-UserTable  border border-dark-100 rounded-lg',
        className,
      )}
    >
      <Table columns={columns} data={items} />
    </div>
  )
}
