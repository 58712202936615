import { useState } from 'react'
import { AngleRight, Loading } from '@rushable/icons'
import Modal from 'components/Modal'
import Button from 'components/Button'

import { createBlocklist, getStripeDisputes } from 'redux/dispute'
import { getAccess } from 'redux/common'
import { toast } from 'react-toastify'

const ButtonIcon = (loading: boolean) => {
  return loading ? (
    <Loading size={16} className='text-light-300' />
  ) : (
    <AngleRight size={16} className='text-light-300' />
  )
}

export type TDisputeHandlingModalProps = {
  open: boolean
  item: any
  toggle: () => void
  getTable: () => void
}

export default function DisputeHandlingModal({
  open,
  item,
  toggle,
  getTable,
}: TDisputeHandlingModalProps) {
  const [dashboardLoading, setDashboardLoading] = useState(false)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [customerLoading, setCustomerLoading] = useState(false)
  const customerBlocked = !!item?.order?.customer?.blocked_at
  const isRestaurant = item?.type === 'restaurant'

  const handleSeeOrderOnDash = async () => {
    setDashboardLoading(true)
    try {
      const res = await getAccess({ order_id: item?.order_id })
      setDashboardLoading(false)
      if (res && res.message === 'Success') {
        window.open(res.redirect)
      } else {
        setDashboardLoading(false)
        toast.error('Access Error!')
      }
    } catch (err: any) {
      setDashboardLoading(false)
      if (err.message) {
        toast.error(err.message)
      }
    }
  }

  const handleSeeDisputeOnStripe = async () => {
    setStripeLoading(true)
    try {
      const res = await getStripeDisputes(item.id)
      setStripeLoading(false)
      if (res?.stripe_dispute_link) {
        window.open(res.stripe_dispute_link)
      }
    } catch (err: any) {
      setStripeLoading(false)
      if (err.message) {
        toast.error(err.message)
      }
    }
  }

  const handleBlock = async () => {
    setCustomerLoading(true)
      try {
        const res = await createBlocklist({
          type: 'user',
          order_id: item?.order_id || '',
          customer_id: item?.order?.customer_id || '',
          reason: item?.reason || '',
        })
        if (res) {
          setCustomerLoading(false)
          if (res.message) {
            toast.success(res.message)
            getTable()
            toggle()
          }
        }
      } catch (err: any) {
        setCustomerLoading(false)
        if (err.message) {
          toast.error(err.message)
        }
      }
  }

  return (
    <Modal
      open={open}
      toggle={toggle}
      title='DISPUTE HANDLING'
      okText='UPDATE'
      cancelText='CLOSE'
      footer={
        <div className='flex w-full justify-end'>
          <Button theme='contained' color='secondary' onClick={toggle}>
            CLOSE
          </Button>
        </div>
      }
    >
      <div className='flex flex-col space-y-6'>
        {!isRestaurant && (
          <Button
            theme='outlined'
            color='secondary'
            className='w-full px-6 pt-3 pb-3'
            onClick={handleSeeOrderOnDash}
          >
            <div className='w-[444px] flex justify-between'>
              <div className='text-light-700 text-base'>
                🧾 See order detail in dashboard
              </div>
              {ButtonIcon(dashboardLoading)}
            </div>
          </Button>
        )}
        <Button
          theme='outlined'
          color='secondary'
          className='w-full px-6 pt-3 pb-3'
          onClick={handleSeeDisputeOnStripe}
        >
          <div className='w-[444px] flex justify-between'>
            <div className='text-light-700 text-base'>
              🗃️ See dispute detail on Stripe
            </div>
            {ButtonIcon(stripeLoading)}
          </div>
        </Button>
        {!customerBlocked && !isRestaurant ? (
          <Button
            theme='outlined'
            color='secondary'
            className='w-full px-6 pt-3 pb-3'
            onClick={handleBlock}
          >
            <div className='w-[444px] flex justify-between'>
              <div className='text-light-700 text-base'>
                ☠️ Block this customer and payment
              </div>
              {ButtonIcon(customerLoading)}
            </div>
          </Button>
        ) : null}
      </div>
    </Modal>
  )
}
