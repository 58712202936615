export const currentStepMap: any = {
  confirm_representative: 0,
  confirm_general_info: 1,
  confirm_work_with_stripe: 2,
  confirm_policy: 3,

  confirm_location: 4,
  confirm_legal_entity: 5,
  confirm_deposit: 6,
  confirm_subscription: 7,
  confirm_billing_account: 8,
  account_completed: 9,
  confirm_existing_users: 10,

  confirm_questionnaire: 11,
}
