import { useEffect, useState, useContext } from 'react'
import { Percentage } from '@rushable/icons'
import { toast } from 'react-toastify'

import SingleInput from 'components/SingleInput'
import SolutionEngineer from '../../components/SolutionEngineer'
import { Select, SearchSelect, BrandSelect } from 'components/Select'
import { useAppSelector } from 'redux/hooks'
import { getSalesCommissionApi, getReferralListApi, updateSEApi } from 'redux/account'
import useDebounce from 'hooks/useDebounce'

import SignupFormContext from './context'
import { LVAN_ID, PRIMARYLANGUAGE } from 'data/enum'
import { getLeadSource } from '../../helpers/constant'
import type { TProPackage } from 'types/proPackage'

type TSignupInfo = {
  brandProPackage: TProPackage | null
  formData: any
  signupForm?: Record<string, any> // singups api 详情
  formDisabled: boolean
  isAffiliate: boolean
  isTransferEdit: boolean
  setFormData: (newFormData: any) => void
  onBrandChange: (label: any, item: any) => void
  onChangeForm: (key: string, value: any) => void
}

export default function SignupInfo({
  brandProPackage,
  formData,
  signupForm,
  formDisabled,
  isAffiliate,
  isTransferEdit,
  setFormData,
  onBrandChange,
  onChangeForm,
}: TSignupInfo) {
  const authMap = useAppSelector(s => s.auth.authMap)
  const isMaster = authMap?.roleId === 1
  const context = useContext(SignupFormContext)
  const { editId, closer } = context
  const transferDisabled = formDisabled && !isTransferEdit
  // 预设的销售提成百分比
  const [salesPercent, setSalesPercent] = useState<any>({})

  // referrer list
  const [referralList, setReferralList] = useState<any[]>([])

  // 获取销售提成百分比预设数据
  const getSalesCommission = async () => {
    try {
      const res = await getSalesCommissionApi()
      setSalesPercent(res)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const getReferralList = async () => {
    try {
      const res = await getReferralListApi()
      setReferralList(res ?? [])
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const updateSE = (params: {
    disabled: boolean
    seId: string
    ae: string
    se: string
  }) => {
    // 当 signupform 可编辑时，走原来的 edit 流程
    if (!params.disabled) {
      return
    }
    updateSEApi(editId!, {
      solution_engineer_id: params.seId,
      ae_commission: params.ae,
      se_commission: params.se,
    })
      .then(res => toast.success(res.message))
      .catch(err => toast.error(err.message || 'Request error'))
  }
  const debounceUpdateSE = useDebounce(updateSE, 1000)
  // solution enigneer 相关表单
  // master 在 'draft','signup','onboarding' 这三种状态情况下 se 均可编辑
  const renderSE = () => {
    const isEditSe =
      signupForm?.status === 'draft' ||
      signupForm?.status === 'signup' ||
      signupForm?.status === 'onboarding'
    const isDisabled = isEditSe ? false : formDisabled
    return (
      <>
        <div className='flex mb-6'>
          <Select
            label='CLOSER'
            className='flex-1'
            isPerson
            value={closer.value}
            disabled={true}
            onChange={() => {}}
            options={[closer]}
          />
          {formData.source !== 'affiliate' && (
            <SingleInput
              className='w-136 ml-4'
              name='LAUNCH_COMMISSION_AE'
              label='LAUNCH COMMISSION'
              // master：draft、signup、onboarding 可以编辑
              disabled={isDisabled || !isMaster}
              iconAfter={<Percentage />}
              value={formData.ae_commission}
              onChange={e => {
                const value = e.target.value
                if (+value <= 100) {
                  onChangeForm('ae_commission', value)
                  debounceUpdateSE({
                    disabled: formDisabled,
                    seId: formData.solution_engineer_id,
                    ae: value,
                    se: formData.se_commission,
                  })
                }
              }}
            />
          )}
        </div>
        <div className='flex mb-6'>
          <SolutionEngineer
            data={formData}
            // 如果是 master 则在 launch 之前都可以编辑，否则按原流程仅在 signup 阶段可编辑
            disabled={isMaster ? isDisabled : formDisabled}
            onChange={value => {
              const se = value === null ? '0' : formData.se_commission
              setFormData({
                ...formData,
                solution_engineer_id: value,
                se_commission: se,
              })
              debounceUpdateSE({
                disabled: formDisabled,
                seId: value,
                ae: formData.ae_commission,
                se: se,
              })
            }}
          />
          {formData.source !== 'affiliate' && (
            <SingleInput
              className='w-136 ml-4'
              name='LAUNCH_COMMISSION_SE'
              label='LAUNCH COMMISSION'
              // master：draft、signup、onboarding 可以编辑
              disabled={isDisabled || !isMaster}
              iconAfter={<Percentage />}
              value={formData.se_commission}
              onChange={e => {
                if (formData.solution_engineer_id === null) {
                  return
                }
                const value = e.target.value
                if (+value <= 100) {
                  onChangeForm('se_commission', value)
                  debounceUpdateSE({
                    disabled: formDisabled,
                    seId: formData.solution_engineer_id,
                    ae: formData.ae_commission,
                    se: value,
                  })
                }
              }}
            />
          )}
        </div>
      </>
    )
  }

  useEffect(() => {
    getSalesCommission()
    getReferralList()
  }, [])
  return (
    <div className='flex-1 mr-8'>
      <BrandSelect
        className={brandProPackage ? 'mb-2' : 'mb-6'}
        label='BRAND NAME'
        placeholder='Select'
        value={formData.brand_name}
        paramsOptions={{ expand: ['pro_package'] }} // brands with pro package info
        disabled={formDisabled}
        apiType='signups'
        onChange={onBrandChange}
      />
      {brandProPackage && (
        <div className='mb-6 text-light-300 text-sm'>
          ⚡ This brand currently has active Pro Package
        </div>
      )}

      {!isAffiliate && (
        <>
          <div className='flex mb-6 items-end'>
            <Select
              className='flex-1'
              label='LEAD SOURCE'
              placeholder='Select'
              disabled={formDisabled}
              value={formData.source}
              options={getLeadSource(formData.source)}
              onChange={value => {
                setFormData({
                  ...formData,
                  source: value,
                  // || 后面的判断是用来兼容 self、sdr 的情况
                  ae_commission: salesPercent[value]?.[0] || signupForm?.ae_commission,
                  se_commission: salesPercent[value]?.[1] || signupForm?.se_commission,
                  solution_engineer_id:
                    value === 'affiliate' ? LVAN_ID : formData.solution_engineer_id,
                })
              }}
            />
            {formData.source === 'referral' && (
              <Select
                className='ml-4 flex-1'
                label=''
                options={referralList}
                placeholder='Referral Partner'
                disabled={formDisabled}
                value={formData.referrer_id}
                onChange={(label, item) => {
                  onChangeForm('referrer_id', item.value)
                }}
              />
            )}
          </div>
          <SingleInput
            value={formData.hubspot_deal_link || ''}
            disabled={formDisabled}
            className='mb-6'
            name='HUBSPOT_DEAL_LINK'
            label='HUBSPOT DEAL LINK'
            placeholder='Input'
            onChange={e => onChangeForm('hubspot_deal_link', e.target.value)}
          />
          {renderSE()}
        </>
      )}
      <div className='w-full my-8 border border-dark-100 border-dashed' />
      <SingleInput
        value={formData.user_email || ''}
        disabled={transferDisabled}
        className='mb-6'
        name='USER_EMAIL'
        label='USER EMAIL'
        placeholder='Input'
        onChange={e => onChangeForm('user_email', e.target.value)}
      />
      <SingleInput
        value={formData.user_phone || ''}
        disabled={transferDisabled}
        className='mb-6'
        name='USER_CELLPHONE'
        label='USER CELLPHONE'
        type='tel'
        placeholder='Input'
        onChangePhone={value => onChangeForm('user_phone', value)}
      />
      <SingleInput
        value={formData.user_first_name || ''}
        disabled={transferDisabled}
        className='mb-6'
        name='FIRST_NAME'
        label='FIRST NAME'
        placeholder='Input'
        onChange={e => onChangeForm('user_first_name', e.target.value)}
      />
      <SingleInput
        value={formData.user_last_name || ''}
        disabled={transferDisabled}
        className='mb-6'
        name='LAST_NAME'
        label='LAST NAME'
        placeholder='Input'
        onChange={e => onChangeForm('user_last_name', e.target.value)}
      />
      <Select
        label='PRIMARY LANGUAGE'
        placeholder='Select'
        disabled={transferDisabled}
        value={formData.user_primary_language}
        options={PRIMARYLANGUAGE}
        onChange={(value, item) => {
          onChangeForm('user_primary_language', value)
        }}
      />
      <div className='w-full my-8 border border-dark-100 border-dashed' />
      <SearchSelect
        className='mb-6'
        label='LOCATION ADDRESS LINE 1'
        placeholder='Search'
        disabled={formDisabled}
        value={formData.location_address_query}
        onChange={(label, item) => onChangeForm('location_address_query', label)}
      />
      <SingleInput
        className='mb-6'
        name='LOCATION_ADDRESS_LINE 2'
        label='LOCATION ADDRESS LINE 2'
        placeholder='Input'
        disabled={transferDisabled}
        value={formData.location_line_2 || ''}
        onChange={e => onChangeForm('location_line_2', e.target.value)}
      />
      <SingleInput
        className='mb-6'
        name='LOCATION_NICKNAME'
        label='LOCATION NICKNAME'
        placeholder='Input'
        disabled={transferDisabled}
        value={formData.location_name || ''}
        onChange={e => onChangeForm('location_name', e.target.value)}
      />
      <SingleInput
        value={formData.location_phone || ''}
        disabled={transferDisabled}
        className='mb-6'
        name='RESTAURANT_PHONE'
        label='RESTAURANT PHONE'
        type='tel'
        placeholder='Input'
        onChangePhone={value => onChangeForm('location_phone', value)}
      />
      <SingleInput
        className='mb-6'
        name='RESTAURANT_EMAIL'
        label='RESTAURANT EMAIL'
        placeholder='Input'
        disabled={transferDisabled}
        value={formData.location_email || ''} // 向后端询问字段名称
        onChange={e => onChangeForm('location_email', e.target.value)}
      />
      <div className='w-full my-8 border border-dark-100 border-dashed' />
      <SingleInput
        className='mb-6'
        name='SALES_TAX_RATE'
        label='SALES TAX RATE'
        placeholder='Input'
        disabled={transferDisabled}
        isDigit={true}
        decimalPlaces={3}
        iconAfter={<Percentage />}
        value={formData.sales_tax || ''}
        onChange={e => {
          const value = e.target.value
          if (+value <= 100) {
            // value 只能输入小数点后三位
            onChangeForm('sales_tax', value.replace(/(\.\d{3})\d+$/, '$1'))
          }
        }}
      />
      <SingleInput
        className='mb-6'
        name='LEGAL_ENTITY_NAME'
        label='LEGAL ENTITY NAME'
        placeholder='Input'
        disabled={transferDisabled}
        value={formData.legal_name || ''}
        onChange={e => onChangeForm('legal_name', e.target.value)}
      />
      <SingleInput
        className='mb-6'
        name='LEGAL_EIN'
        label='LEGAL EIN'
        placeholder='Input'
        disabled={transferDisabled}
        value={formData.business_ein || ''}
        onChange={e => onChangeForm('business_ein', e.target.value)}
      />
    </div>
  )
}
