import React, { useState } from 'react'
import cn from 'classnames'
import { components, OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import useDebounce from 'hooks/useDebounce'
import { LinedSearch, LinedMapPin } from '@rushable/icons'
import { customStyles, customTheme, IOptions } from './help'
import Label from 'components/Label'
import { quickSearchLocation } from 'redux/common/action'

export interface IFormAsyncSelectProps {
  className?: string
  label?: string | React.ReactElement
  disabled?: boolean
  placeholder?: string
  defaultValue?: any | any[]
  onClickOption: (item: any) => void
  requestApi?: (inputValue: string) => Promise<Array<IOptions>>
}

const getAsyncLocationData = (inputValue: string): Promise<any> => {
  return quickSearchLocation(inputValue)
}

const QuickSearchLocation = ({
  className,
  label,
  disabled,
  placeholder = '',
  onClickOption,
  requestApi = getAsyncLocationData,
}: IFormAsyncSelectProps) => {
  const [asyncOptions, setAsyncOptions] = useState([])
  const [inputValue, setInputValue] = useState('')

  // 自定义待选择列表
  const customSearchOption = (props: OptionProps<IOptions>) => {
    const { onMouseMove, onMouseOver, onClick, ...rest } = props.innerProps
    const newProps = { ...props, innerProps: rest }
    const { data } = props
    let el = ''
    try {
      // 在某些情况下 Reg 会报错
      // eg: (xxx) xxx-xxxx 这样格式的电话号码，只删除 “)” 时
      const reg = new RegExp(inputValue, 'gi')
      el = data.name?.replace?.(reg, function (txt: any) {
        return `<span style='color:#00a5eb'>${txt}</span>`
      })
    } catch (err) {
      el = data.name
    }
    const menu = (
      <div className='w-full'>
        <div
          className='px-4 py-1'
          dangerouslySetInnerHTML={{ __html: el as string }}
        />
        {data.locations?.map((v: any) => (
          <div
            key={v.account_id}
            onClick={() => onClickOption(v.account_id)}
            className='px-4 py-2 bg-dark-900 flex items-center hover:cursor-pointer'
          >
            <LinedMapPin size={16} className='mr-1' />
            <span className='flex-1 truncate'>{v.name}</span>
          </div>
        ))}
      </div>
    )
    // react-select 优化 https://www.botsplash.com/post/optimize-your-react-select-component-to-smoothly-render-10k-data
    return <components.Option {...newProps}>{menu}</components.Option>
  }

  const getOptions = (value: string, callback: any) => {
    requestApi(value)
      .then((res: any) => {
        callback(res)
        setAsyncOptions(res)
      })
      .catch(() => {
        callback([])
        setAsyncOptions([])
      })
  }

  const getOptionsDebounce = useDebounce(getOptions, 500)

  // @ts-ignore
  const handleInputChange = (e, { action }) => {
    if (action === 'input-change') {
      setInputValue(e)
    }
  }

  const loadOptions = (
    inputValue: string,
    callback: (options: IOptions[]) => void,
  ) => {
    if (!inputValue) {
      return
    }
    getOptionsDebounce(inputValue, callback)
  }
  return (
    <div className={cn(className)}>
      {label && <Label className='mb-2'>{label}</Label>}
      <AsyncSelect
        inputValue={inputValue}
        // menuIsOpen={true}
        isDisabled={disabled}
        blurInputOnSelect={true}
        cacheOptions={asyncOptions}
        defaultOptions={asyncOptions}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        backspaceRemovesValue={true}
        hideSelectedOptions={false}
        styles={{
          ...customStyles,
          option: (provided, state) => {
            return {
              ...provided,
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              lineHeight: '140%',
              color: '#74747F',
              backgroundColor: '#121217',
              borderTop: '1px solid #363636',
              ':active': {
                backgroundColor: '#121217',
              },
            }
          },
        }}
        theme={customTheme}
        components={{
          Option: customSearchOption,
          DropdownIndicator: () => <LinedSearch size={16} />,
        }}
      />
    </div>
  )
}
export default QuickSearchLocation
