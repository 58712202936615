import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'components/Button'
import SingleInput from 'components/SingleInput'
import { Select } from 'components/Select'
import BusinessHours from 'components/BusinessHours'
import ImportResult from './ImportResult'
import MenuLockModal from './MenuLockModal'
import { importMenuApi } from 'redux/account'
import { SCHEDULE_OPTIONS } from '../../helpers/constant'

type Tprops = {
  url: string
  collections: string[]
  onImport: () => void
}

export default function MenuCollectionItem({ url, collections, onImport }: Tprops) {
  const urlParams = useParams()
  const [menus, setMenus] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState({
    open: false,
    success: false,
    error: '',
  })
  const [lockOpen, setLockOpen] = useState(false)

  useEffect(() => {
    const menus = collections.map(item => ({
      collection: item,
      schedule: 'same_as_store',
      hours: undefined,
    }))
    setMenus(menus)
  }, [collections])

  const handleConfirm = async () => {
    const formData = new FormData()
    formData.append('url', url)

    menus.forEach((item: any, index) => {
      formData.append(`collections[${index}][name]`, item.collection || '')
      formData.append(`collections[${index}][availability]`, item.schedule || '')
      const hours = Object.values(item.hours || '')?.reduce(
        (acc: any, cur: any) => [...acc, ...cur],
        [],
      ) as any[]
      hours?.forEach((item2: any, index2: number) => {
        formData.append(`collections[${index}][open_hours][${index2}][day]`, item2.day)
      })
      hours?.forEach((item2: any, index2: number) => {
        formData.append(`collections[${index}][open_hours][${index2}][from]`, item2.from)
      })
      hours?.forEach((item2: any, index2: number) => {
        formData.append(`collections[${index}][open_hours][${index2}][to]`, item2.to)
      })
    })
    setLoading(true)
    try {
      await importMenuApi(urlParams.id!, formData)
      setResult({ open: true, success: true, error: '' })
      onImport()
    } catch (error: any) {
      const status = error?.status
      if (status === 423) {
        // menu lock
        setLockOpen(true)
      } else {
        setResult({ open: true, success: false, error: error.message })
      }
    }
    setLoading(false)
  }

  return (
    <>
      {menus.map((item: any, index: number) => {
        return (
          <div className='mt-6' key={index}>
            <div className='flex space-x-4 mb-2'>
              <SingleInput
                name='MENU_COLLECTION'
                value={item.collection}
                disabled
                label={item.collection}
              />
              <Select
                label='SCHEDULE'
                placeholder='Select'
                value={item.schedule}
                options={SCHEDULE_OPTIONS}
                onChange={value => {
                  menus[index].schedule = value
                  setMenus([...menus])
                }}
              />
            </div>
            {item.schedule === 'customize' && (
              <BusinessHours
                hoursInDay={item.hours}
                onChange={value => {
                  menus[index].hours = value
                  setMenus([...menus])
                }}
              />
            )}
          </div>
        )
      })}
      {!!menus.length && (
        <Button className='mt-4 w-full' onClick={handleConfirm} loading={loading}>
          CONFIRM TO IMPORT
        </Button>
      )}
      <ImportResult
        open={result.open}
        toggle={() => setResult(prev => ({ ...prev, open: false }))}
        success={result.success}
        errorMsg={result.error}
      />
      <MenuLockModal open={lockOpen} toggle={() => setLockOpen(!lockOpen)} />
    </>
  )
}
