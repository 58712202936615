import { AngleLeft } from '@rushable/icons'
import Table from 'components/Table'
import StageProgress from 'components/StageProgress'
import Pricing from 'components/Pricing'
import PaymentInfo from 'components/PaymentInfo'
import FeatureTracking from 'components/FeatureTracking'
import GBPTracking from 'components/GBPTracking'
import SaleComparison from 'components/SaleComparison'
import Button from 'components/Button'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import currency from 'currency.js'

type TAllTableProps = {
  loading: boolean
  total: number
  current: number
  pageSize: number
  data: any[]
  onPageNoChange: (page: number) => void
  handleFilterChange: (type: string, value: any) => void
  goToCommission: () => void
}

export default function AccountTable({
  loading,
  total,
  current,
  pageSize,
  data,
  onPageNoChange,
  handleFilterChange,
  goToCommission,
}: TAllTableProps): JSX.Element {
  const navigate = useNavigate()
  const authMap = useAppSelector(s => s.auth.authMap)
  const { allData } = useAppSelector(state => state.affiliate)
  const { sort_by, sort } = allData.filterForm

  const columns = [
    {
      key: 'location',
      name: 'BRAND',
      className: 'w-204',
      custom: (value: any, row: any) => {
        const name = value ? value?.brand.name : row?.signup_form?.brand_name
        return <div className='text-ellipsis'>{name || '-'}</div>
      },
    },
    {
      key: 'location',
      name: 'LOCATION',
      className: 'w-204',
      custom: (value: any, row: any) => {
        const name = value ? value?.name : row?.signup_form?.location_name
        return <div className='text-ellipsis'>{name || '-'}</div>
      },
    },
    {
      key: 'signup_form',
      name: 'PRICING',
      align: 'right',
      className: 'w-176',
      custom: (value: any, row: any) => {
        return (
          <div className='flex justify-end'>
            {!row.price && !row.convenience_fee ? (
              '-'
            ) : (
              <Pricing
                store={row.price ? currency(row.price).format() : '-'}
                userGroups={
                  row.convenience_fee
                    ? currency(row.convenience_fee).format()
                    : '-'
                }
              />
            )}
          </div>
        )
      },
    },
    {
      key: 'brand',
      name: 'PAYMENT',
      sortable: true,
      className: 'w-184',
      sortDirection: sort_by === 'last_4' ? sort : '',
      custom: (value: any, row: any) => {
        return (
          <div className='flex'>
            <PaymentInfo
              brand={value || 'unknown'}
              last4={row.last_4}
              expireDate={row.exp}
            />
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'payment',
          value,
        })
      },
    },
    {
      key: 'location',
      name: 'FEATURES',
      className: 'w-200',
      custom: (value: any, row: any) => {
        return (
          <div>
            <FeatureTracking
              pickup={
                !!value?.online_order_config?.order_pickup_config?.enabled
              }
              delivery={
                !!value?.online_order_config?.order_doordash_delivery_config
                  ?.enabled ||
                !!value?.online_order_config?.order_in_house_config?.enabled
              }
              dineIn={
                !!value?.online_order_config?.order_contactless_dinein_config
                  ?.enabled
              }
              email={!!value?.paper_plane}
              phone={
                !!row?.location?.location_business_setting
                  ?.marketing_phone_number
              }
              proPackageStatus={row?.pro_package_status || ''}
            />
          </div>
        )
      },
    },
    {
      key: 'GBP_ordering_tracking_is_on',
      name: 'GBP',
      className: 'w-96',
      sortable: true,
      sortDirection: sort_by === 'gbp' ? sort : '',
      custom: (value: any, row: any) => {
        return (
          <GBPTracking
            websiteLink={!!row.GBP_website_tracking_is_on}
            orderingLink={!!row.GBP_ordering_tracking_is_on}
          />
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'gbp',
          value,
        })
      },
    },
    {
      key: 'comparison',
      name: 'COMP',
      align: 'center',
      className: 'w-96',
      custom: (value: any) => {
        const comList = Object.values(value || {})
        const initArray = Array(4 - comList.length).fill(0)
        return <SaleComparison weeklySales={[...comList, ...initArray]} />
      },
    },
    {
      key: 'stage_days',
      name: 'STAGE DAYS',
      className: 'w-112',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'stage_days' ? sort : '',
      custom: (value: number) => {
        return <div>{Math.abs(value)} days</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'stage_days',
          value,
        })
      },
    },
    {
      key: 'stage',
      name: 'STAGE',
      align: 'right',
      className: 'w-112',
      tdNoHidden: true,
      sortable: true,
      sortDirection: sort_by === 'stage' ? sort : '',
      custom: (value: any, item: any, index: number) => {
        return (
          <StageProgress
            className='ml-auto'
            popTop={index >= 8}
            onboarding={item.onboarding}
            signupForm={item.signup_form}
            statusName={item.stage}
          />
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'stage',
          value,
        })
      },
    },
  ]
  const goDetail = (item: any) => {
    navigate(`/accounts/detail/${item.id}?from=affiliate`)
  }
  return (
    <div>
      <div className='flex justify-between mb-6 mt-2'>
        <div className='flex space-x-4'>
          {authMap?.type === 'rushable' && (
            <Button
              color='secondary'
              theme='outlined'
              onClick={() => navigate(-1)}
            >
              <div className='flex'>
                <AngleLeft size={12} className='mr-1' />
                GO BACK
              </div>
            </Button>
          )}
          <Button
            theme='outlined'
            color='secondary'
            onClick={() => goToCommission()}
          >
            My Commission
          </Button>
        </div>
        {authMap?.type === 'affiliate' && (
          <Button
            className='ml-4'
            color='secondary'
            as='Link'
            href='/accounts/detail/create?from=affiliate'
          >
            Initiate Signup
          </Button>
        )}
      </div>
      <Table
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
        clickedRow={goDetail}
      />
    </div>
  )
}
