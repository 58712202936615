import { useState } from 'react'

import { AdminLayout } from 'components/Layout'
import Button from 'components/Button'
import SingleInput from 'components/SingleInput'

import { getPotentialRestaurants } from 'redux/sales'

const INIT_FORMDATA = {
  zipcode: null,
  metro_area: null,
  google_review_votes: null,
  google_review_rating: null,
  doordash_review_votes: null,
  doordash_review_rating: null,
  ubereats_review_votes: null,
  ubereats_review_rating: null,
}
export default function LeadSourcing() {
  const [formData, setFormData] = useState<{
    zipcode: null | string
    metro_area: null | string
    google_review_votes: null | string
    google_review_rating: null | string
    doordash_review_votes: null | string
    doordash_review_rating: null | string
    ubereats_review_votes: null | string
    ubereats_review_rating: null | string
  }>(INIT_FORMDATA)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const handleFormDataChange = (key: string, value: string) => {
    setErrorMsg('')
    setSuccessMsg('')
    if (key === 'zipcode') {
      setFormData({
        ...formData,
        // 只可以输入 0-9、空格和英文逗号
        zipcode: value?.match(/[\d\s,]+/g)?.join('') || '',
      })
      return
    }
    setFormData({
      ...formData,
      [key]: value,
    })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      // 以逗号分隔为数组，并去掉每一项中的空格
      const params = {
        ...formData,
        zipcode: formData.zipcode?.split(',').map(v => v.replace(/\s+/g, '')) || [],
      }
      const res = await getPotentialRestaurants(params)
      setSuccessMsg(res.message)
      // 成功后清空 form 数据
      setFormData(INIT_FORMDATA)
    } catch (error: any) {
      setErrorMsg(error.message)
    } finally {
      setLoading(false)
    }
  }
  const { zipcode, metro_area } = formData
  const optionalItems = [
    {
      key: 'google_review_votes',
      value: formData.google_review_votes,
      name: 'Google review count',
      decimalPlaces: 0,
    },
    {
      key: 'google_review_rating',
      value: formData.google_review_rating,
      name: 'Google review rating',
      decimalPlaces: 2,
    },
    {
      key: 'doordash_review_votes',
      value: formData.doordash_review_votes,
      name: 'DD review count',
      decimalPlaces: 0,
    },
    {
      key: 'doordash_review_rating',
      value: formData.doordash_review_rating,
      name: 'DD review rating',
      decimalPlaces: 2,
    },
    {
      key: 'ubereats_review_votes',
      value: formData.ubereats_review_votes,
      name: 'UE review count',
      decimalPlaces: 0,
    },
    {
      key: 'ubereats_review_rating',
      value: formData.ubereats_review_rating,
      name: 'UE review rating',
      decimalPlaces: 2,
    },
  ]
  return (
    <AdminLayout>
      <div className='mt-88 w-full flex justify-center items-center'>
        <div className='w-400 flex flex-col space-y-4'>
          <SingleInput
            label='Zip Codes'
            value={zipcode || ''}
            name='LEAD_SOURCING_CODE'
            placeholder='Please enter zip codes and separated them with comma'
            onChange={e => handleFormDataChange('zipcode', e.target.value)}
          />
          <SingleInput
            label='Metro'
            value={metro_area || ''}
            name='METRO_AREA'
            placeholder='e.g: Houston'
            onChange={e => handleFormDataChange('metro_area', e.target.value)}
          />
          {optionalItems.map(item => {
            const { key, value, name, decimalPlaces } = item
            return (
              <div key={key} className='flex justify-between items-center my-4'>
                <span>{name}:</span>
                <SingleInput
                  value={value || ''}
                  textAlign='right'
                  isDigit={true}
                  decimalPlaces={decimalPlaces}
                  className='w-44 text-right'
                  name={key}
                  onChange={e => {
                    const value = Number(e.target.value) > 0 ? e.target.value : ''
                    handleFormDataChange(key, value)
                  }}
                />
              </div>
            )
          })}
          {errorMsg && <div className='w-full text-yellow'>{errorMsg}</div>}
          {successMsg && <div className='w-full text-green'>{successMsg}</div>}
          <Button
            loading={loading}
            disabled={!zipcode && !metro_area}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </div>
    </AdminLayout>
  )
}
