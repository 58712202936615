import { useState, useEffect } from 'react'
import { useAppSelector } from 'redux/hooks'

// 获取路有权限 hooks => 筛选出可以显示的菜单
export default function useRoutePermission(routes: TRouteType[]) {
  const authMap = useAppSelector(state => state.auth.authMap)
  const [navLinkMenu, setNavLinkMenu] = useState<TRouteType[]>([])
  useEffect(() => {
    if (!authMap) {
      return
    }
    const arr = routes.filter(item => {
      // 未设置权限意味着全部显示
      if (!item.permission) {
        return true
      }
      const roleId = authMap.roleId
      if (item.permission.roleId === roleId) {
        return true
      }
      const { min, max, crmTeamIds } = item.permission
      if (min && max && roleId >= min && roleId <= max) {
        return true
      }
      const crmTeamId = authMap.crmTeamId
      if (crmTeamIds && crmTeamIds.includes(crmTeamId)) {
        return true
      }
      return false
    })
    setNavLinkMenu(arr)
  }, [authMap, routes])

  return navLinkMenu
}
