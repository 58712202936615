import axiosInstance from 'utils/axios'

export async function getAESales(params: {
  month: string
  sort_by: string
  sort: string
}) {
  const response = await axiosInstance.request({
    url: 'sales/ae_performances',
    method: 'get',
    params,
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function getSESales(params: {
  month: string
  sort_by: string
  sort: string
}) {
  const response = await axiosInstance.request({
    url: 'sales/se_performances',
    method: 'get',
    params,
    cache: { ignoreCache: true },
  })
  return response.data
}

export type TDetailParams = {
  id: string
  month?: string
  sort_by?: string
  sort?: string
  page?: number
  begin_time?: string
  end_time?: string
}

export async function getBonus({ id, month }: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/bonus`,
    method: 'get',
    params: { month },
  })
  return response.data
}

export async function getNewSignups({ id, month, sort_by, sort, page }: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/new_signups`,
    method: 'get',
    params: { month, sort_by, sort, page },
  })
  return response.data
}

export async function getSetup({ id, month, sort_by, sort, page }: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/setup`,
    method: 'get',
    params: { month, sort_by, sort, page },
  })
  return response.data
}

export async function getCancellation({ id, month, sort_by, sort, page }: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/cancellation`,
    method: 'get',
    params: { month, sort_by, sort, page },
  })
  return response.data
}

export async function getEffortPoints({
  id,
  month,
  sort_by,
  sort,
  page,
  begin_time,
  end_time,
}: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/crm_effort_points`,
    method: 'get',
    cache: { ignoreCache: true },
    params: {
      month,
      sort_by,
      sort,
      page,
      begin_time,
      end_time,
    },
  })
  return response.data
}

export async function createOtherEffort(params: {
  crm_user_id: string
  description: string
  hours_spent: string
}) {
  const response = await axiosInstance.request({
    url: 'sales_performances/crm_effort_points',
    method: 'post',
    data: params,
  })
  return response.data
}

export async function deleteOtherEffort(crm_effort_point: number) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_effort_points/${crm_effort_point}`,
    method: 'delete',
  })
  return response.data
}

export async function getPotentialRestaurants(params: {
  zipcode: string[]
  metro_area: null | string
  google_review_votes: null | string
  google_review_rating: null | string
  doordash_review_votes: null | string
  doordash_review_rating: null | string
  ubereats_review_votes: null | string
  ubereats_review_rating: null | string
}) {
  const formatParams: any = {
    metro_area: params.metro_area,
    google_review_votes: params.google_review_votes,
    google_review_rating: params.google_review_rating,
    doordash_review_votes: params.doordash_review_votes,
    doordash_review_rating: params.doordash_review_rating,
    ubereats_review_votes: params.ubereats_review_votes,
    ubereats_review_rating: params.ubereats_review_rating,
  }
  params.zipcode.forEach((code, index) => {
    formatParams[`zipcodes[${index}]`] = code
  })
  const response = await axiosInstance.request({
    url: 'sales/lead_sourcing',
    method: 'get',
    params: formatParams,
    cache: { ignoreCache: true },
  })
  return response.data
}
