import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { AdminLayout, AdminPageTitle } from 'components/Layout'
import PageLoading from 'components/PageLoading'
import GroupFilter from './components/Group/GroupFilter'
import GroupTable from './components/Group/GroupTable'

import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  getGroupListApi,
  updateGroupItemsApi,
  setGroupFormData,
  setGroupTable,
} from 'redux/account/group'

import type { TGroupFormData } from './helpers'

export default function GroupSignup() {
  const dispatch = useAppDispatch()
  const { groupFormData } = useAppSelector(state => state.account)
  const [loading, setLoading] = useState(false)

  const getList = async (params: TGroupFormData, ignoreCache = false) => {
    try {
      setLoading(true)
      const res = await getGroupListApi(params, ignoreCache)
      dispatch(
        setGroupTable({
          total: res?.total || 0,
          data: res?.data || [],
          currentPage: res?.current_page || 1,
          perPage: res?.per_page || 20,
        }),
      )
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const updateGroupItem = async (
    params: {
      brandName: string
      signupFormIds: number[]
    },
    cb: () => void,
  ) => {
    try {
      const { brandName, signupFormIds } = params
      await updateGroupItemsApi({
        brand_name: brandName,
        signup_form_ids: signupFormIds.length > 0 ? signupFormIds : null,
      })
      getList(groupFormData, true)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    } finally {
      cb && cb()
    }
  }

  const handleFormDataChange = (type: string, value: any) => {
    var newFormData = { ...groupFormData }
    if (type === 'only_self') {
      newFormData = {
        ...newFormData,
        only_self: value ? '1' : '0',
        page: 1,
      }
    } else if (type === 'brand') {
      newFormData = {
        ...newFormData,
        brand_name: value.name,
        page: 1,
      }
    }
    dispatch(setGroupFormData(newFormData))
    getList(newFormData)
  }

  const onPageNoChange = (currentPage: number) => {
    if (currentPage === groupFormData.page) {
      return
    }
    const newFormData = {
      ...groupFormData,
      page: currentPage,
    }
    dispatch(setGroupFormData(newFormData))
    getList(newFormData)
  }

  useEffect(() => {
    getList(groupFormData)
  }, [])

  return (
    <AdminLayout showNavs={false}>
      <div
        style={{ maxHeight: loading ? 'calc(100vh - 80px)' : 'none' }}
        className={`relative ${loading && 'overflow-y-hidden'}`}
      >
        {loading && (
          <div className='bg-dark-900 w-full h-full absolute top-20 z-40'>
            <PageLoading />
          </div>
        )}
        <AdminPageTitle title='Group Signup' />
        <GroupFilter handleFormDataChange={handleFormDataChange} />
        <GroupTable onPageNoChange={onPageNoChange} updateGroupItem={updateGroupItem} />
      </div>
    </AdminLayout>
  )
}
