import { createReducer } from '@reduxjs/toolkit'
import { DOMAIN } from 'data/constant'
import { toast } from 'react-toastify'

import { getProfile } from './action'

type Tstate = {
  profileRequest: boolean
  profile: Tprofile | null
  authMap: TAuthMap | null
  profileErrors: string | null | undefined
}

const initialState: Tstate = {
  profileRequest: false,
  profile: null,
  authMap: null,
  profileErrors: null,
}

const formatUser = (profile: Tprofile) => {
  const { first_name, last_name, crm_user } = profile || {}
  const userPhoto = crm_user?.profile_photo ? `${DOMAIN}${crm_user?.profile_photo}` : ''
  const userName = `${first_name || ''} ${last_name || ''}`
  return {
    userPhoto,
    userName,
  }
}

export const authReducer = createReducer(initialState, builder => {
  builder
    .addCase(getProfile.pending, state => {
      state.profileRequest = true
    })
    .addCase(getProfile.fulfilled, (state, action) => {
      state.profileRequest = false
      state.profile = action.payload
        ? { ...action.payload, ...formatUser(action.payload) }
        : {}
      // 权限控制相关
      state.authMap = {
        type: action.payload?.crm_user?.crm_team?.type,
        roleId: action?.payload?.crm_user?.crm_role_id,
        crmTeamId: action.payload?.crm_user?.crm_team?.id,
      }
      state.profileErrors = null
    })
    .addCase(getProfile.rejected, (state, action) => {
      state.profileRequest = false
      toast.error(action.error.message)
      state.profileErrors = action.error.message
    })
})

export default authReducer
