import cn from 'classnames'
import { SolidStore, SolidUserGroup } from '@rushable/icons'

export type Iprops = {
  className?: string
  store?: string
  userGroups?: string
}

export default function Pricing({
  className,
  store,
  userGroups,
}: Iprops): JSX.Element {
  return (
    <div className={cn('flex text-sm', className)}>
      {store && (
        <div className='w-88 flex items-center justify-end mr-3'>
          <span className={cn('mr-1 min-w-[52px] text-ellipsis text-right')}>
            {store}
          </span>
          <SolidStore className='text-light-100' size={16} />
        </div>
      )}
      {userGroups && (
        <div className='w-56 flex items-center justify-end'>
          <span className='mr-1'>{userGroups}</span>
          <SolidUserGroup className='text-light-100' size={16} />
        </div>
      )}
    </div>
  )
}
