import { useState, useContext, useEffect, useCallback } from 'react'
import cn from 'classnames'
import currency from 'currency.js'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom'
import {
  DuoToneMoney,
  Check,
  LinedPenEdit,
  Dollar,
  SolidFile,
  AngleLeft,
  AngleRight,
  SolidTrashCan,
  SolidCopy,
} from '@rushable/icons'
import { toast } from 'react-toastify'

import Label from 'components/Label'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Drawer from 'components/Drawer'
import DragBar from 'components/DragBar'
import SingleInput from 'components/SingleInput'
import { Select } from 'components/Select'
import CopyableText from 'components/CopyableText'
import AlertText from 'components/AlertText'
import OnlinePresence from './SalesTable'

import { useCompare } from 'hooks/useDebounce'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { approveSignupsApi, setNeedReload, getProPackageApi } from 'redux/account'
import SignupFormContext from './context'
import { TProPackage } from 'types/proPackage'
import { TSignupStatus, Tfee } from '../../helpers'
import { ONBOARDING_ONBOARD_URL } from 'data/constant'

interface Iprops {
  className?: string
  brandProPackage: TProPackage | null
  formDisabled: boolean
  products: Tfee[] | []
  signupForm?: Record<string, any> // singups api 详情
  handleProductsChange: (info: Tfee[]) => void
  handleSubmit: (status: 'signup' | 'draft') => void
}

const INITINFO = {
  subAmount: '149.00',
  subDiscount: '0.00',
  dispatchFee: '1.50',
  dispatchFeeDiscount: '0.00',
  setupFee: '199.00',
  setupFeeDiscount: '0.00',
  isFreeWebsite: 1,
  includeProPackage: 1,
  proPackagePrice: 0,
  proPackageTrialMonths: 0,
  proPackageAmount: '100',
}

type TgetCalcInfo = {
  monthlySubFee: number
  monthlyFee: number
  convenienceFee: number
}

// Subscription discount  CONVENIENCE FEE
/**
 * 计算
 * monthlySubFee (the acutal paying amount of the monthly subscription fee)
 * monthlyFee (the total amount of the monthly subscription fee plus the pro package fee)
 * convenienceFee (the convenience fee)
 * @param Tfee info
 * @returns TgetCalcInfo { monthlySubFee, monthlyFee, convenienceFee }
 */
const getCalcInfo = (info: Tfee): TgetCalcInfo => {
  // Subscription discount= 白色块 = subscription amount - discount
  const monthlySubFee = currency(info.subAmount).subtract(info.subDiscount || 0).value
  const monthlyPro = info.includeProPackage ? Number(info.proPackageAmount) : 0
  const monthlyFee = currency(monthlySubFee).add(monthlyPro).value

  // 公式 =1.49-(黄色块-49)*0.00496
  const convenienceFee = currency(1.49).subtract(
    currency(info.subAmount).subtract(49).multiply(0.00496),
  ).value

  return {
    monthlySubFee,
    monthlyFee,
    convenienceFee,
  }
}

// SALES VOLUME
const getSalesTableData = (info: Tfee) => {
  const { monthlyFee, convenienceFee } = getCalcInfo(info)
  return [
    {
      item: 'Monthly fee volume',
      amount: currency(monthlyFee).format(),
      x: 'x12',
      volume: currency(monthlyFee).multiply(12).format(),
    },
    {
      item: 'Convenience fee volume',
      amount: currency(convenienceFee).format(),
      x: 'x720',
      volume: currency(convenienceFee).multiply(720).format(),
    },
    {
      item: 'Dispatch fee',
      amount: currency(info.dispatchFee).format(),
      x: 'x72',
      volume: currency(info.dispatchFee).multiply(72).format(),
    },
  ]
}

// Total volume
export const getSalesTotal = (info: Tfee = {} as any) => {
  const { monthlyFee, convenienceFee } = getCalcInfo(info)
  const annualFee = currency(monthlyFee).multiply(12)
  const dispatchFee = currency(info.dispatchFee).multiply(72)
  const convenienceFeeVolume = currency(convenienceFee).multiply(720)
  return annualFee.add(dispatchFee).add(convenienceFeeVolume).format()
}

const MIN = 49
const MAX = 349

export default function SignupProducts({
  className,
  brandProPackage,
  products,
  formDisabled,
  signupForm,
  handleProductsChange,
  handleSubmit,
}: Iprops) {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const params = useParams()
  const navigate = useNavigate()
  const context = useContext(SignupFormContext)
  const authMap = useAppSelector(s => s.auth.authMap)
  const isAffiliate =
    searchParams.get('from') === 'affiliate' ||
    !!(authMap?.roleId && authMap?.roleId >= 101 && authMap?.roleId <= 199)
  const [salesName, setSalesName] = useState('1')
  const [showSalesModal, setShowSalesModal] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [showFeeModal, setShowFeeModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const [feeInfo, setFeeInfo] = useState({ ...INITINFO })
  const [formStatus, setFormStatus] = useState(true)
  const [btnLoading, setBtnLoading] = useState({ draft: false, signup: false })
  // 默认 Pro Package 数据
  const [defaultProPackage, setDefaultProPackage] = useState<TProPackage | null>(null)

  const compareFunc = useCompare()
  const updateFeeInfo = useCallback(() => {
    const proPackagePrice = brandProPackage
      ? brandProPackage.unit_amount
      : defaultProPackage?.unit_amount || 0

    let info = {
      ...feeInfo,
      proPackagePrice,
    }
    if (products?.length) {
      info = {
        ...feeInfo,
        ...products[0],
        proPackagePrice,
      }
    }
    if (brandProPackage) {
      info.includeProPackage = 1
    }
    if (status === 'create' || status === 'draft') {
      // create 时强制免除 dispatch fee
      info.dispatchFee = '0.00'
      info.dispatchFeeDiscount = '1.50'
    } else {
      // api 返回的数据并没有 dispatch fee discount
      // 需要根据返回的 dispatch fee 和 isAffiliate 计算出 dispatch fee discount
      const defaultDispatchFee = isAffiliate ? 0.5 : 1.5
      info.dispatchFeeDiscount = currency(defaultDispatchFee, {
        symbol: '',
      })
        .subtract(info.dispatchFee)
        .format()
    }
    setFeeInfo(info)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, defaultProPackage, brandProPackage, isAffiliate])

  // 控制 confirm 按钮显示隐藏
  useEffect(() => {
    if (products?.length) {
      compareFunc(products[0], feeInfo, (flag: boolean) => {
        setFormStatus(flag)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, feeInfo])

  useEffect(() => {
    updateFeeInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, defaultProPackage, brandProPackage, isAffiliate])

  useEffect(() => {
    getDefaultProPackage()
  }, [])

  const handleSaveProduct = (isDelete = false) => {
    const arr = isDelete ? [] : [{ ...feeInfo }]
    handleProductsChange(arr)
    setShowDrawer(false)
  }
  const handleApprove = async () => {
    setLoading(true)
    try {
      const res = await approveSignupsApi(params.id!)
      toast.success(res.message)
      context.handleInit()
    } catch (error: any) {
      toast.error(error.message || 'Request error')
    }
    setLoading(false)
  }
  const { monthlySubFee, convenienceFee } = getCalcInfo(feeInfo)
  const status = context.status
  const showSubmitBtn =
    status === 'draft' ||
    status === 'signup' ||
    status === 'pending' ||
    status === 'create'
  const approvals = signupForm?.signup_form_approvals || []

  const getDefaultProPackage = async () => {
    try {
      const { unit_amount } = await getProPackageApi()
      setDefaultProPackage({ unit_amount: Number(unit_amount) })
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  // ESSENTIAL OFFERING / ENSSENTIAL ONLINE PRESENCE
  const getOfferingTableData = (info: Tfee, isEdit?: boolean) => {
    const { monthlySubFee, convenienceFee } = getCalcInfo(info)
    const list: {
      item: JSX.Element | string
      amount: JSX.Element | string
      occurrence: string
    }[] = [
      {
        item: 'Essential Subscription',
        amount: (
          <>
            {currency(info.subDiscount).value !== 0 && (
              <span className='line-through'>{currency(info.subAmount).format()}</span>
            )}{' '}
            <span>{currency(monthlySubFee).format()}</span>
          </>
        ),
        occurrence: 'per month',
      },
      {
        item: 'Convenience fee',
        amount: currency(convenienceFee).format(),
        occurrence: 'per order',
      },
      {
        item: 'Dispatch fee',
        amount: currency(info.dispatchFee).format(),
        occurrence: 'per delivery',
      },
      {
        item: 'Setup fee',
        amount: (
          <>
            {currency(info.setupFeeDiscount).value !== 0 && (
              <span className='line-through mr-1'>
                {currency(info.setupFee).format()}
              </span>
            )}
            <span>
              {currency(info.setupFee).subtract(info.setupFeeDiscount).format()}
            </span>
          </>
        ),
        occurrence: +info.setupFee === 199 ? 'at launch' : 'delayed',
      },
    ]
    if (info.includeProPackage) {
      const freeTrial = info.proPackageTrialMonths
      list.splice(1, 0, {
        item: (
          <div>
            Pro Package
            {freeTrial ? (
              <span
                className={`ml-1 px-1 py-0.5 text-xs rounded ${
                  isEdit ? 'bg-dark-300' : 'bg-dark-500'
                }`}
              >{`${
                freeTrial === 6
                  ? '6-month'
                  : freeTrial === 12
                  ? '1-year'
                  : `${freeTrial}-month`
              } free`}</span>
            ) : (
              ''
            )}
          </div>
        ),
        amount: currency(
          !signupForm?.status && info.proPackageTrialMonths ? 0 : info.proPackageAmount,
        ).format(),
        occurrence: 'per month',
      })
    }
    return list
  }

  const onProPackageSelectChange = (value: number) => {
    switch (value) {
      case 0:
        setFeeInfo(prev => ({
          ...prev,
          includeProPackage: 0,
          proPackageTrialMonths: 0,
          proPackageAmount: '0',
        }))
        break
      case 1:
        setFeeInfo(prev => ({
          ...prev,
          includeProPackage: 1,
          proPackageTrialMonths: 0,
          proPackageAmount: `${prev.proPackagePrice}`,
        }))
        break
      case 2:
        setFeeInfo(prev => ({
          ...prev,
          includeProPackage: 1,
          proPackageTrialMonths: 6,
          proPackageAmount: '0',
        }))
        break
      case 3:
        setFeeInfo(prev => ({
          ...prev,
          includeProPackage: 1,
          proPackageTrialMonths: 12,
          proPackageAmount: '0',
        }))
        break
    }
  }

  const getProPackageOptions = () => {
    const proPackageOptions = [
      { label: '1-year free trial', value: 3 },
      { label: '6-mouth free trial', value: 2 },
      { label: 'Yes', value: 1 },
      { label: 'No', value: 0 },
    ]
    if (!!brandProPackage && feeInfo.includeProPackage) {
      return proPackageOptions.slice(0, 3)
    }
    if (isAffiliate) {
      return proPackageOptions.slice(1, 4)
    }
    return proPackageOptions
  }

  // 保存操作
  const onSubmit = async (status: 'draft' | 'signup') => {
    setBtnLoading(prev => ({ ...prev, [status]: true }))
    try {
      await handleSubmit(status)
      // 创建或修改成功后，返回列表需要忽略缓存加载
      dispatch(setNeedReload(true))
      // 编辑情况下
      if (context.editId) {
        context.handleInit()
      } else {
        // 新增情况下
        navigate(-1)
      }
    } catch (err) {
      console.log(err)
    }
    setBtnLoading(prev => ({ ...prev, [status]: false }))
  }

  const renderOptButton = (status: TSignupStatus) => {
    const draft = (
      <Button
        loading={
          status === 'draft' || status === 'create' ? btnLoading.draft : btnLoading.signup
        }
        color='secondary'
        onClick={() => {
          let act: any = 'draft'
          if (status && status !== 'draft' && params.id !== 'create') {
            act = 'signup'
          }
          onSubmit(act)
        }}
        className='flex-1'
      >
        {params.id === 'create' ? 'SAVE DRAFT' : 'SAVE CHANGE'}
      </Button>
    )
    const signup = (
      <Button
        loading={btnLoading.signup}
        className='flex-1'
        onClick={() => onSubmit('signup')}
      >
        CREATE SIGNUP
      </Button>
    )
    const edit = (
      // 进入 hello 流程的不可被编辑
      <>
        {!signupForm?.current_step && (
          <Button
            color='secondary'
            theme='outlined'
            className='flex-1'
            onClick={() => context?.handleFormDisabled(false)}
          >
            EDIT
          </Button>
        )}
      </>
    )

    // 右上角操作按钮
    const optBtnMap = {
      create: (
        <>
          {draft} {signup}
        </>
      ),
      draft: (
        <>
          {formDisabled ? edit : draft}
          {signup}
        </>
      ),

      signup: (
        <>
          {formDisabled ? (
            <>
              {!signupForm?.current_step && <div className='flex flex-1'>{edit}</div>}
              <CopyableText
                className='flex-1'
                text={ONBOARDING_ONBOARD_URL + signupForm?.src}
              >
                <Button
                  className='flex-1'
                  color='secondary'
                  iconAfter={<SolidCopy size={12} />}
                >
                  COPY SIGNUP LINK
                </Button>
              </CopyableText>
            </>
          ) : (
            draft
          )}
        </>
      ),
      pending: (
        <>
          {formDisabled ? (
            <>
              {edit}
              <AlertText
                className='ml-4 flex-1'
                color='yellow'
                text='Approval Required'
              />
            </>
          ) : (
            draft
          )}
        </>
      ),
    }
    return optBtnMap[status]
  }

  return (
    <div className={cn(className)}>
      <Label className='mb-2'>SALES OFFERING</Label>
      {products?.length < 1 || !products ? (
        <Button
          className='w-full'
          theme='outlined'
          color='secondary'
          disabled={formDisabled}
          onClick={() => {
            setShowSalesModal(true)
            setFormStatus(true)
          }}
        >
          ADD PRODUCT
        </Button>
      ) : null}
      {!!products?.length && (
        <div className='p-4 my-6 bg-dark-500 rounded-lg'>
          <div className='flex items-center'>
            <DuoToneMoney size={24} />
            <span className='text-base text-light-700 ml-2'>Total sales volume</span>
            <span className='flex-1 text-right font-bold text-light-700 text-2xl'>
              {getSalesTotal(products[0])}
            </span>
          </div>
          {!!approvals?.length && (
            <div className='p-4 mt-3 border border-dark-100 rounded-lg'>
              <p className='text-xs text-light-300'>Items need approval or adjustment:</p>
              {approvals?.map((item: any, index: number) => {
                const isPass = !!item.approved_at && !!item.approved_by
                return (
                  <div className='my-2 flex justify-between' key={index}>
                    <span className='text-xs text-yellow'>{item.name}</span>
                    <Check className={isPass ? 'text-green' : ''} />
                  </div>
                )
              })}
              {signupForm?.isApprove && (
                <Button
                  className='w-full mt-4'
                  color='secondary'
                  loading={loading}
                  disabled={!formDisabled}
                  onClick={handleApprove}
                >
                  APPROVE
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {/* 目前仅有一种情况 ENSSENTIAL ONLINE PRESENCE 后续可能需要遍历多个 */}
      {/* 外部展现数据靠 products 提供，drawer 内的数据由组件内 feeinfo 提供 */}
      {/* create 时外部展现数据靠 feeinfo 提供 */}
      {products?.length >= 1 && (
        <OnlinePresence
          type='online'
          className='flex-1'
          data={getOfferingTableData(products[0])}
          label='ENSSENTIAL ONLINE PRESENCE'
          labelRight={
            <>
              {!formDisabled && (
                <Button
                  className='font-bold text-blue'
                  theme='text'
                  size='small'
                  iconBefore={<SolidFile size={12} />}
                  onClick={() => {
                    setShowDrawer(true)
                    setFormStatus(false)
                  }}
                >
                  EDIT OFFERING
                </Button>
              )}
            </>
          }
        />
      )}

      {showSubmitBtn ? (
        <>
          <div className='w-full my-6 border border-dark-100 border-dashed' />
          {!!signupForm?.group_signup_id && (
            <AlertText
              className='w-full mb-6'
              color='orange'
              position='left'
              text='This signup is grouped'
            />
          )}
          <div className='flex space-x-4'>{renderOptButton(status!)}</div>
        </>
      ) : null}

      <Modal
        title='SALES OFFERING'
        cancelText='CLOSE'
        open={showSalesModal}
        onOk={() => {
          setShowSalesModal(false)
          setTimeout(() => {
            setShowDrawer(true)
          }, 500)
        }}
        toggle={() => setShowSalesModal(false)}
      >
        <Select
          label='Label name'
          placeholder='Select...'
          value={salesName}
          onChange={(value, item) => {
            setSalesName(value)
          }}
          options={[{ label: 'Enssential Online Presence', value: '1' }]}
        />
      </Modal>

      <Drawer
        title='ESSENTIAL ONLINE PRESENCE'
        cancelText='CLOSE'
        maskClosable={false}
        open={showDrawer}
        footer={
          <div className='w-full flex justify-between'>
            <div className='flex'>
              {!!products?.length && (
                <Button
                  color='warning'
                  theme='text'
                  iconBefore={<SolidTrashCan size={12} />}
                  onClick={() => handleSaveProduct(true)}
                >
                  DELETE
                </Button>
              )}
            </div>
            <div className='flex'>
              <Button color='secondary' onClick={() => setShowDrawer(false)}>
                CLOSE
              </Button>
              {formStatus && (
                <Button className='ml-4' onClick={() => handleSaveProduct()}>
                  CONFIRM
                </Button>
              )}
            </div>
          </div>
        }
        toggle={() => setShowDrawer(false)}
      >
        <div className='mb-2 flex justify-between text-xs text-light-300'>
          <span>SUBSCRIPTION</span>
          <span>CONVENIENCE FEE</span>
        </div>
        <div className='flex justify-between font-bold text-base text-white'>
          <div className='flex items-center'>
            {currency(feeInfo.subDiscount).value !== 0 && (
              <span className='text-yellow line-through'>
                {currency(feeInfo.subAmount).format()}
              </span>
            )}
            <span className=' mx-1'>{currency(monthlySubFee).format()}/mo</span>
            <Button theme='text' color='secondary' onClick={() => setShowFeeModal(true)}>
              <LinedPenEdit size={16} />
            </Button>
          </div>
          <div className=''>{currency(convenienceFee).format()}/order</div>
        </div>
        <DragBar
          className='mt-2.5'
          min={
            currency(feeInfo.subDiscount).value > MIN
              ? currency(feeInfo.subDiscount).value + MIN
              : MIN
          }
          discount={currency(feeInfo.subDiscount).value}
          max={MAX}
          value={+feeInfo.subAmount}
          onChange={value => {
            setFeeInfo(prev => ({ ...prev, subAmount: value?.toFixed(2) }))
          }}
        />

        <div className='mt-18 mb-10 text-sm text-light-500'>
          {isAffiliate ? (
            <></>
          ) : (
            <>
              <div className='flex justify-between items-center'>
                <span>Discount: Essential subscription</span>
                <SingleInput
                  value={feeInfo.subDiscount}
                  iconBefore={<Dollar />}
                  textAlign='right'
                  decimalPlaces={2}
                  className='w-44 text-right'
                  name='subscript_discount'
                  placeholder='Input...'
                  onBlur={(e: any) => {
                    setFeeInfo(prev => ({
                      ...prev,
                      subDiscount: Number(e.target.value)?.toFixed(2),
                    }))
                  }}
                  onChange={(e: any) => {
                    const value = parseInt(e.target.value || 0)
                    if (value <= Number(feeInfo.subAmount)) {
                      setFeeInfo(prev => ({
                        ...prev,
                        subDiscount: value + '',
                      }))
                    }
                  }}
                />
              </div>
              <div className='flex justify-between items-center my-4'>
                <span>Discount: Dispatch fee</span>
                <SingleInput
                  value={feeInfo.dispatchFeeDiscount}
                  iconBefore={<Dollar />}
                  textAlign='right'
                  isDigit={true}
                  decimalPlaces={2}
                  className='w-44 text-right'
                  name='subscript_dispatchFee'
                  placeholder='Input...'
                  disabled={status === 'create' || status === 'draft'}
                  onChange={(e: any) => {
                    const defaultDispatchFee = isAffiliate ? 0.5 : 1.5
                    if (Number(e.target.value) <= Number(defaultDispatchFee)) {
                      setFeeInfo(prev => ({
                        ...prev,
                        dispatchFee: currency(defaultDispatchFee, {
                          symbol: '',
                        })
                          .subtract(e.target.value)
                          .format(),
                        dispatchFeeDiscount: e.target.value,
                      }))
                    }
                  }}
                />
              </div>
              <div className='flex justify-between items-center'>
                <span>Discount: Setup fee</span>
                <SingleInput
                  value={feeInfo.setupFeeDiscount}
                  iconBefore={<Dollar />}
                  textAlign='right'
                  isDigit={true}
                  decimalPlaces={2}
                  max={Number(feeInfo.setupFee)}
                  className='w-44 text-right'
                  name='subscript_setupFee'
                  placeholder='Input'
                  onChange={(e: any) => {
                    const value = e.target.value
                    if (+value <= Number(feeInfo.setupFee)) {
                      setFeeInfo(prev => ({
                        ...prev,
                        setupFeeDiscount: value,
                      }))
                    }
                  }}
                />
              </div>
            </>
          )}
          <div className='flex justify-between items-center my-4'>
            <p>
              <span>
                {brandProPackage
                  ? '⚡ This brand currently has active Pro Package'
                  : 'Pro Package'}
              </span>
              <span>{`(+$${currency(feeInfo.proPackagePrice).dollars()}/mo)`}</span>
            </p>
            <Select
              className='w-44'
              isRtl={!!brandProPackage && !feeInfo.includeProPackage}
              disabled={!!brandProPackage && !feeInfo.includeProPackage}
              value={
                feeInfo.includeProPackage
                  ? feeInfo.proPackageTrialMonths === 0
                    ? 1
                    : feeInfo.proPackageTrialMonths === 6
                    ? 2
                    : 3
                  : feeInfo.includeProPackage
              }
              onChange={(value, item) => onProPackageSelectChange(value)}
              options={getProPackageOptions()}
            />
          </div>
        </div>

        <OnlinePresence
          type='online'
          className='mb-6 mt-8'
          label='ESSENTIAL OFFERING'
          data={getOfferingTableData(feeInfo, true)}
        />
        {isAffiliate ? (
          <></>
        ) : (
          <OnlinePresence
            type='sales'
            className='mb-6'
            label='SALES VOLUME'
            data={getSalesTableData(feeInfo)}
            footer={
              <>
                <span>Total volume</span>
                <span>{getSalesTotal(feeInfo)}</span>
              </>
            }
          />
        )}
      </Drawer>

      {/* 修改 SUBSCRIPTION modal */}
      <Modal
        title='SUBSCRIPTION FEE'
        open={showFeeModal}
        toggle={() => setShowFeeModal(false)}
      >
        <Label className='mb-2'>SUBSCRIPTION FEE AMOUNT</Label>
        <div className='flex'>
          <Button
            theme='outlined'
            color='secondary'
            iconBefore={<AngleLeft />}
            onClick={() => {
              const min = MIN < +feeInfo.subDiscount ? +feeInfo.subDiscount : MIN
              setFeeInfo(prev => ({ ...prev, subAmount: min?.toFixed(2) }))
            }}
          >
            MIN
          </Button>
          <SingleInput
            value={feeInfo.subAmount}
            iconBefore={<Dollar />}
            textAlign='right'
            min={MIN}
            decimalPlaces={2}
            className='w-132 mx-4 text-right'
            name='subscript_subscription'
            placeholder='Input...'
            onBlur={(e: any) => {
              const value = +e.target.value
              const min = MIN > +feeInfo.subDiscount ? MIN : +feeInfo.subDiscount
              const num = value > min ? value : min
              setFeeInfo(prev => ({ ...prev, subAmount: num?.toFixed(2) }))
            }}
            onChange={(e: any) => {
              const value = parseInt(e.target.value || 0)
              if (value <= MAX) {
                setFeeInfo(prev => ({
                  ...prev,
                  subAmount: value + '',
                }))
              }
            }}
          />
          <Button
            theme='outlined'
            color='secondary'
            iconAfter={<AngleRight />}
            onClick={() =>
              setFeeInfo(prev => ({
                ...prev,
                subAmount: MAX.toFixed(2),
              }))
            }
          >
            MAX
          </Button>
        </div>
      </Modal>
    </div>
  )
}
