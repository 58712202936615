import { BrandSelect } from 'components/Select'
import Switch from 'components/Switch'

import { useAppSelector } from 'redux/hooks'
import { getBrands } from 'redux/account'

export type TAccountsFilterProps = {
  handleFormDataChange: (type: string, value: any) => void
}

export default function AccountsFilter({ handleFormDataChange }: TAccountsFilterProps) {
  const { groupFormData } = useAppSelector(state => state.account)
  const getAsyncBrandData = async (inputValue?: any): Promise<any> => {
    const res = await getBrands(inputValue || '')
    const brands = res.map((item: any) => {
      return { ...item, value: item.id }
    })
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(brands)
      }, 500)
    })
  }
  return (
    <div className='mx-10 my-8 flex justify-between'>
      <BrandSelect
        className='w-200'
        placeholder='Search brand'
        value={groupFormData.brand_name}
        isShowCreateBrand={false}
        isShowAllBrands={false}
        requestApi={getAsyncBrandData}
        onChange={(label, item) => {
          handleFormDataChange('brand', {
            id: item.id,
            name: label,
          })
        }}
      />
      <Switch
        checked={groupFormData.only_self === '1' ? true : false}
        onChange={e => {
          handleFormDataChange('only_self', e.target.checked)
        }}
      >
        Show my accounts only
      </Switch>
    </div>
  )
}
