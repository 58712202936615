import cn from 'classnames'
import { SolidStore, AngleDown, SolidCopy } from '@rushable/icons'

import Button from 'components/Button'
import CopyableText from 'components/CopyableText'
import GroupItemRow from './GroupItemRow'

import type { TGroupTableData } from '../../helpers'
import { ONBOARDING_ONBOARD_URL } from 'data/constant'
import { useEffect, useState } from 'react'

type TGroupItem = {
  open: boolean
  item: TGroupTableData
  handleOpenChange: () => void
  updateGroupItem: (
    params: {
      brandName: string
      signupFormIds: number[]
    },
    cb: () => void,
  ) => void
}

export default function GroupItem({
  open,
  item,
  handleOpenChange,
  updateGroupItem,
}: TGroupItem) {
  const [isEdit, setIsEdit] = useState(false)
  const [signupFormIds, setSignupFormIds] = useState<number[]>([])
  const [loading, setLoading] = useState(false)
  const {
    brand_name,
    grouped_location_count,
    location_count,
    grouped_link,
    same_brand_name_signup_forms: brandGroupList,
  } = item

  const editDisabled = brandGroupList?.some(v => v.stage === 'Signing Up')

  const handleUpdateGroupItem = () => {
    setLoading(true)
    updateGroupItem(
      {
        brandName: brand_name,
        signupFormIds: signupFormIds,
      },
      () => {
        setLoading(false)
        setIsEdit(false)
      },
    )
  }

  const handleBrandGroupIdChange = (id: number, check: boolean) => {
    if (check) {
      // 添加 ID 到 signupFormIds
      setSignupFormIds(signupFormIds.concat([id]))
    } else {
      // 删除 ID 到 signupFormIds
      const newIds = [...signupFormIds]
      const index = signupFormIds.findIndex(formId => formId === id)
      if (typeof index === 'number' && index >= 0) {
        newIds.splice(index, 1)
        setSignupFormIds(newIds)
      }
    }
  }

  useEffect(() => {
    if (!open) {
      setIsEdit(false)
    }
  }, [open])

  useEffect(() => {
    if (!isEdit) {
      const ids: number[] = []
      brandGroupList.forEach(brandGroupItem => {
        if (brandGroupItem.group_signup_id) {
          ids.push(brandGroupItem.id)
        }
      })
      setSignupFormIds(ids)
    }
  }, [isEdit, brandGroupList])

  return (
    <div className='border border-dark-100 rounded-2xl'>
      <div className='h-16 flex justify-between'>
        <div className='pl-6 flex items-center text-light-700 text-base'>
          <SolidStore className='text-light-100 mr-2' size={20} />
          {brand_name}
        </div>
        <div className='flex items-center'>
          {open ? (
            <Button
              className='mr-4'
              theme='outlined'
              color='secondary'
              disabled={editDisabled}
              onClick={() => setIsEdit(!isEdit)}
            >
              Edit Group
            </Button>
          ) : null}
          {!!grouped_link ? (
            <CopyableText className='flex-1' text={ONBOARDING_ONBOARD_URL + grouped_link}>
              <Button
                className='mr-4'
                color='secondary'
                iconAfter={<SolidCopy size={12} />}
              >
                Group Signup Link
              </Button>
            </CopyableText>
          ) : null}
          <div className='pr-6 text-light-700 text-base'>
            {grouped_location_count}/{location_count} grouped
          </div>
          <div
            className='h-full w-16 border-l border-dark-100 flex justify-center items-center cursor-pointer'
            onClick={handleOpenChange}
          >
            <AngleDown
              size={20}
              className={cn(
                'text-light-100 transition',
                open ? 'rotate-180' : 'rotate-0',
              )}
            />
          </div>
        </div>
      </div>
      {open ? (
        <div className='px-6 py-4 border-t border-dark-100 flex flex-col space-y-2'>
          {brandGroupList.map(brandGroupItem => {
            return (
              <GroupItemRow
                key={brandGroupItem.id}
                item={brandGroupItem}
                isEdit={isEdit}
                signupFormIds={signupFormIds}
                handleBrandGroupIdChange={handleBrandGroupIdChange}
              />
            )
          })}
          {isEdit && (
            <div className='mt-4 flex'>
              <Button loading={loading} onClick={handleUpdateGroupItem}>
                UPDATE
              </Button>
              <Button
                className='ml-4'
                theme='outlined'
                color='secondary'
                onClick={() => setIsEdit(!isEdit)}
              >
                CANCEL
              </Button>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}
