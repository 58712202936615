import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AdminLayout } from 'components/Layout'
import { Select, SearchSelect } from 'components/Select'
import { getReferrals, getReferrers, editReferrals } from 'redux/referral'
import ReferralTable from './components/ReferralTable'
import type { TReferral } from './helpers/constant'
import PageLoading from 'components/PageLoading'

export default function ReferralPage() {
  const [referral, setReferral] = useState<TReferral>({
    name: '',
    type: '',
  })
  const [searchValue, setSearchValue] = useState('All Referrers')
  const [selectValue, setSelectValue] = useState('qualified')
  const statusOptions = [
    { value: '', label: 'All Referrers' },
    { value: 'qualified', label: 'Qualified' },
    { value: 'sent', label: 'Sent' },
  ]
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [sentId, setSentId] = useState(0)

  const [filterForm, setFilterForm] = useState({
    referrer_id: '',
    status: 'qualified',
    count_order: '',
    page: 1,
    per_page: 40,
  })

  const handleFormChange = (type: string, value: any) => {
    const page = type === 'type' ? value : 1
    const newForm = {
      ...filterForm,
      page,
      [type]: value,
    }
    setFilterForm(newForm)
    getTable(newForm)
  }

  const getTable = async (
    form: any,
    newReferral?: {
      name: string
      type: string
    },
  ) => {
    // 请求 api
    setLoading(true)
    try {
      const params = newReferral ? newReferral : referral
      if (params.name && params.type) {
        form['sort_by'] = params.name
        form['sort'] = params.type
      }
      const res = await getReferrals(form)
      setData(res.data)
      setTotal(res.total)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }
  const onPageNoChange = (page: number) => {
    handleFormChange('page', page)
  }

  const changeRowStatus = async (status: boolean, item: any) => {
    setSentId(item.id)
    try {
      const params = {
        id: item.id,
        status: status ? 'sent' : 'qualified',
      }
      await editReferrals(params)
      getTable(filterForm)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setSentId(0)
  }

  useEffect(() => {
    getTable(filterForm)
  }, [])

  const handleBlocklistChange = (newReferral: TReferral) => {
    setReferral(newReferral)
    getTable(
      {
        ...filterForm,
      },
      newReferral,
    )
  }

  const getSearchReferrers = async (inputValue: string): Promise<any> => {
    const res = await getReferrers({ keyword: inputValue })
    let referrerOptions = [{ value: '', label: 'All Referrers' }]
    res.forEach((item: { id: string; name: string }) => {
      referrerOptions.push({
        value: item.id,
        label: item.name,
      })
    })
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(referrerOptions)
      }, 500)
    })
  }
  return (
    <AdminLayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className='px-10 py-8'>
          <div className='flex space-x-4'>
            <SearchSelect
              className='w-[216px]'
              placeholder='Search'
              value={searchValue}
              options={[{ value: '', label: 'All Referrers' }]}
              onChange={(value, item) => {
                setSearchValue(value)
                handleFormChange('referrer_id', item.value)
              }}
              requestApi={value => getSearchReferrers(value)}
            ></SearchSelect>
            <Select
              className='w-[216px]'
              placeholder='Select'
              value={selectValue}
              onChange={(value, item) => {
                setSelectValue(value)
                handleFormChange('status', value)
              }}
              options={statusOptions}
            ></Select>
          </div>
          <div className='mt-8'>
            <ReferralTable
              total={total}
              current={filterForm.page}
              pageSize={filterForm.per_page}
              referral={referral}
              sentId={sentId}
              handleReferralChange={handleBlocklistChange}
              onPageNoChange={onPageNoChange}
              changeStatus={(value: boolean, row: any) => changeRowStatus(value, row)}
              data={data}
            />
          </div>
        </div>
      )}
    </AdminLayout>
  )
}
