// 路由
export const LOGIN = '/login'
export const PROFILE = '/profile'
// nav menu
export const ORDERS = '/orders'
export const ACCOUNTS = '/accounts'
export const MAP = '/map'
export const REFERRAL = '/referral'
export const AFFILIATE = '/affiliate'
export const DISPUTE = '/dispute'
export const CREDIT = '/credit'
export const BLOCKLIST = '/blocklist'
export const DOMAINS = '/domains'
export const SALES_AE = '/sales-ae'
export const SALES_SE = '/sales-se'
export const LEAD_SOURCING = '/lead-sourcing'
export const PAYOUT = '/payout'
export const LOGISTICS = '/logistics'
export const OWNERSHIP_TRANSFER = '/ownership-transfer'
