import axiosInstance from 'utils/axios'

export async function getReferrals(params: {
  referrer_id?: string
  status?: string
  count_order?: string
  page?: number
}) {
  const response = await axiosInstance.request({
    url: 'referrals',
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getReferrers(params: any) {
  const response = await axiosInstance.request({
    url: 'referrals/referrers',
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
  console.log(response)
  return response.data
}

export async function editReferrals(params: { id: number; status: string }) {
  const response = await axiosInstance.request({
    url: `referrals/edit/${params.id}`,
    method: 'post',
    data: { status: params.status },
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
