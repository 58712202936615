import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import { AdminLayout } from 'components/Layout'
import StatsByPeriodTable from './components/SalesTable'
import { getAESales, getSESales } from 'redux/sales'
import { DatePicker } from 'components/DatePicker'
import type { TSalesType, TTableForm } from './helpers/constant'

export default function SalesAEPage({ salesType }: { salesType: TSalesType }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState<TTableForm>({
    option: moment().format('YYYY-MM'),
    sort_by: '',
    sort: '',
  })
  const [data, setData] = useState([])

  useEffect(() => {
    getTable()
  }, [form])

  const handleFilterChange = (type: string, value: any) => {
    let param
    if (type === 'sort') {
      param = {
        sort_by: value.value ? value.type : '',
        sort: value.value,
      }
    } else {
      param = { [type]: value }
    }
    setForm({ ...form, ...param })
  }

  const getTable = async () => {
    setLoading(true)
    try {
      const api = salesType === 'AE' ? getAESales : getSESales
      const res = await api({
        sort_by: form.sort_by,
        sort: form.sort,
        month: form.option,
      })
      setData(res)
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setLoading(false)
    }
  }
  const goDetail = (item: any) => {
    navigate(`${pathname}/detail/${item.closer_id}?option=${form.option}`)
  }

  if (pathname.includes('detail')) {
    return <Outlet />
  }

  return (
    <AdminLayout>
      <div className='px-10 py-8'>
        <div className='flex space-x-4'>
          <DatePicker
            className='w-60'
            picker='month'
            allowClear={false}
            format='MMM, YYYY'
            value={moment(form.option, 'YYYY-MM')}
            onChange={value =>
              handleFilterChange('option', moment(value).format('YYYY-MM'))
            }
          />
        </div>
        <div className='mt-8'>
          <StatsByPeriodTable
            salesType={salesType}
            loading={loading}
            form={form}
            data={data}
            handleChange={handleFilterChange}
            goDetail={goDetail}
          />
        </div>
      </div>
    </AdminLayout>
  )
}
