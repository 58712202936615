import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import { AdminLayout } from 'components/Layout'
import { IOptions } from 'components/Select/help'
import CreditDrawer from './components/CreditDrawer'
import CreditTable from './components/CreditTable'
import SearchFilter from './components/SearchFilter'
import { TFilterForm } from './helpers/constant'
import { getCredits, getCreditDetail } from 'redux/credit'
import useDebounce from 'hooks/useDebounce'
import type { TCredit } from './helpers/constant'
import PageLoading from 'components/PageLoading'

export default function CreditPage() {
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [filterForm, setFilterForm] = useState<TFilterForm>({
    status: '',
    brand_id: '',
    brand_name: '',
    location_id: '',
    order_id: '',
    page: 1,
    per_page: 40,
  })
  const [locationOptions, setLocationOptions] = useState<IOptions[]>([
    { value: '', label: 'All Locations' },
  ])
  const [creditModal, setCreditModal] = useState<any>({
    open: false,
    data: null,
    type: null,
  })

  const [credit, setCredit] = useState<TCredit>({
    name: '',
    type: '',
  })

  const handleFormChange = (type: string, value: any) => {
    const page = type === 'page' ? value : 1
    const newForm = {
      ...filterForm,
      page,
      [type]: value,
    }
    setFilterForm(newForm)
    if (type === 'order_id') {
      handleOrderId(newForm)
    } else {
      getTable(newForm)
    }
  }

  const handleBrandChange = (item: any) => {
    const newForm = {
      ...filterForm,
      location_id: '',
      brand_name: item.name || '',
      brand_id: item.id || '',
    }
    const { locations } = item
    if (locations && locations.length > 0) {
      setLocationOptions(
        [{ value: '', label: 'All Locations' }].concat(
          locations.map((loc: any) => {
            return {
              label: loc.name,
              value: loc.id,
            }
          }),
        ),
      )
    }
    setFilterForm(newForm)
    getTable(newForm)
  }

  const handleOrderId = useDebounce((form: any) => {
    getTable(form)
  }, 500)

  const handleCreditChange = (newCredit: TCredit) => {
    setCredit(newCredit)
    getTable(
      {
        ...filterForm,
      },
      newCredit,
    )
  }

  const getTable = async (
    form: any,
    newCredit?: {
      name: string
      type: string
    },
  ) => {
    // 请求 api
    setLoading(true)
    try {
      const paramsCredit = newCredit ? newCredit : credit
      if (paramsCredit.name && paramsCredit.type) {
        form['sort_by'] = paramsCredit.name
        form['sort'] = paramsCredit.type
      }
      const res = await getCredits(form)
      setData(res.data)
      setTotal(res.total)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }

  const onPageNoChange = (page: number) => {
    handleFormChange('page', page)
  }

  const openCreditModal = async (id: string) => {
    try {
      const res = await getCreditDetail(id)
      setCreditModal({ open: true, data: res, type: 'edit' })
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }

  useEffect(() => {
    getTable(filterForm)
  }, [])

  return (
    <AdminLayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className='px-10 py-8'>
          <div className=' mb-8 flex justify-between'>
            <SearchFilter
              filterForm={filterForm}
              locationOptions={locationOptions}
              handleFormChange={handleFormChange}
              changeForm={handleBrandChange}
            />
            <Button
              color='secondary'
              theme='contained'
              onClick={() =>
                setCreditModal({ open: true, data: null, type: 'create' })
              }
            >
              ISSUE CREDIT
            </Button>
          </div>

          <CreditTable
            total={total}
            current={filterForm.page}
            pageSize={filterForm.per_page}
            data={data}
            credit={credit}
            handleCreditChange={handleCreditChange}
            onPageNoChange={onPageNoChange}
            openCreditModal={id => {
              setCreditModal({ open: true, data: null, type: 'edit' })
              openCreditModal(id)
            }}
          />
          <CreditDrawer
            creditModal={creditModal}
            formDisabled={creditModal.type === 'edit'}
            toggle={() =>
              setCreditModal({ open: false, data: null, type: null })
            }
            handleUpdate={() => {
              getTable(filterForm)
              setCreditModal({ open: false, data: null, type: null })
            }}
          />
        </div>
      )}
    </AdminLayout>
  )
}
