import cn from 'classnames'
import type { TStageName } from 'types'

export type TStatus =
  | 'draft'
  | 'need_approval'
  | 'ready'
  | 'signing_up'
  | 'onboarding'
  | 'live'
  | 'cancelling'
  | 'canceled'
  | 'withdrawn'

export type TAccountStageProps = {
  className?: string
  statusName?: TStageName
  status: TStatus
  step?: string | number
}

const stageOptions = {
  draft: {
    name: 'Draft',
    bgc: 'bg-dark-300',
    border: 'bg-light-700',
    fill: true,
  },
  need_approval: {
    name: 'Need Approval',
    bgc: 'bg-dark-300',
    border: 'bg-light-700',
    fill: true,
  },
  ready: {
    name: 'Ready',
    bgc: 'bg-orange-16',
    border: 'bg-orange',
    fill: false,
  },
  signing_up: {
    name: 'Signing Up',
    bgc: 'bg-orange-16',
    border: 'bg-orange',
    fill: false,
  },
  onboarding: {
    name: 'Onboarding',
    bgc: 'bg-blue-16',
    border: 'bg-blue',
    fill: false,
  },
  live: {
    name: 'Live',
    bgc: 'bg-green-16',
    border: 'bg-green',
    fill: true,
  },
  cancelling: {
    name: 'Cancelling',
    bgc: 'bg-yellow-16',
    border: 'bg-yellow',
    fill: false,
  },
  canceled: {
    name: 'Churned',
    bgc: 'bg-red-16',
    border: 'bg-red',
    fill: true,
  },
  withdrawn: {
    name: 'Withdrawal',
    bgc: 'bg-purple-16',
    border: 'bg-purple',
    fill: false,
  },
}

const stageOptions2 = {
  Draft: {
    name: 'Draft',
    bgc: 'bg-dark-300',
    border: 'bg-light-700',
    fill: true,
  },
  'Need Approval': {
    name: 'Need Approval',
    bgc: 'bg-dark-300',
    border: 'bg-light-700',
    fill: true,
  },
  Ready: {
    name: 'Ready',
    bgc: 'bg-orange-16',
    border: 'bg-orange',
    fill: false,
  },
  'Signing Up': {
    name: 'Signing Up',
    bgc: 'bg-orange-16',
    border: 'bg-orange',
    fill: false,
  },
  Onboarding: {
    name: 'Onboarding',
    bgc: 'bg-blue-16',
    border: 'bg-blue',
    fill: false,
  },
  Live: {
    name: 'Live',
    bgc: 'bg-green-16',
    border: 'bg-green',
    fill: true,
  },
  Cancelling: {
    name: 'Cancelling',
    bgc: 'bg-yellow-16',
    border: 'bg-yellow',
    fill: false,
  },
  Churned: {
    name: 'Churned',
    bgc: 'bg-red-16',
    border: 'bg-red',
    fill: false,
  },
  Withdrawal: {
    name: 'Withdrawal',
    bgc: 'bg-purple-16',
    border: 'bg-purple',
    fill: true,
  },
}

export default function AccountStage({
  className,
  statusName,
  status,
  step,
}: TAccountStageProps): JSX.Element {
  let stageOption: any = {}
  if (statusName) {
    stageOption = stageOptions2[statusName]
  } else {
    stageOption = stageOptions[status]
  }

  return (
    <div className={cn('ru-AccountStage', className)}>
      <div
        className={cn(
          'relative w-full h-[22px] rounded-sm text-xl  font-bold inline-flex justify-center items-center overflow-hidden',
          stageOption?.bgc,
        )}
      >
        <span className='scale-50 origin-center text-cream whitespace-nowrap'>
          {statusName || stageOption?.name}
        </span>
        <div
          className={cn('absolute left-0 bottom-0 w-full h-0.5', stageOption?.border)}
          style={{
            width: stageOption?.fill ? '100%' : `${step}%`,
          }}
        />
      </div>
    </div>
  )
}
