import { useNavigate } from 'react-router-dom'
import { QuickSearchLocation } from 'components/Select'
import Switch from 'components/Switch'

import { useAppSelector } from 'redux/hooks'

export type TLogisticsFilterProp = {
  handleFormChange: (key: string, value: any) => void
}

export default function LogisticsFilter({
  handleFormChange,
}: TLogisticsFilterProp): JSX.Element {
  const { hideShipped } = useAppSelector(state => state.logistics)
  const navigate = useNavigate()
  return (
    <div className='flex justify-between'>
      <QuickSearchLocation
        className='ml-4 w-60'
        placeholder='Quick search: brand, phone'
        onClickOption={v => {
          navigate(`/accounts/detail/${v}?from=logistics`)
        }}
      />
      <Switch
        checked={hideShipped === '1' ? true : false}
        onChange={e => {
          handleFormChange('hide_shipped', e.target.checked)
        }}
      >
        Hide Shipped requests
      </Switch>
    </div>
  )
}
