import { ReactElement, Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import cn from 'classnames'

export type TDrawerProp = {
  className?: string
  open: boolean
  maskClosable: boolean
  position?: 'left' | 'right' | 'top' | 'bottom'
  toggle: () => void
  children: ReactElement | ReactElement[]
}

export default function DrawerCore({
  className,
  open,
  maskClosable,
  toggle,
  children,
  position = 'right',
}: TDrawerProp): JSX.Element {
  let positionClass = 'inset-y-0 right-0'
  let enterFromClass = 'translate-x-full'
  let enterToClass = 'translate-x-0'
  switch (position) {
    case 'left':
      positionClass = 'inset-y-0 left-0'
      enterFromClass = '-translate-x-full'
      enterToClass = 'translate-x-0'
      break
    case 'top':
      positionClass = 'inset-x-0 top-0'
      enterFromClass = '-translate-y-full'
      enterToClass = 'translate-y-0'
      break
    case 'bottom':
      positionClass = 'inset-x-0 bottom-0'
      enterFromClass = 'translate-y-full'
      enterToClass = 'translate-y-0'
      break
    default:
      break
  }

  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-[150]'
        onClose={() => {
          maskClosable && toggle()
        }}
      >
        <div className='absolute inset-0 overflow-hidden'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-dark-900 bg-opacity-80' />
          </Transition.Child>
          <div
            className={cn('fixed max-w-full max-h-full flex', positionClass)}
          >
            <Transition.Child
              as={Fragment}
              enter='transform transition ease-in-out duration-150'
              enterFrom={enterFromClass}
              enterTo={enterToClass}
              leave='transform transition ease-in-out duration-150'
              leaveFrom={enterToClass}
              leaveTo={enterFromClass}
            >
              <Dialog.Panel>
                <div
                  className={cn('h-full', className)}
                  style={{
                    filter: 'drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.24))',
                  }}
                >
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
