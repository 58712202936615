import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axios'

import type { TMode } from 'components/StageSelect'
import type {
  TFilterParams,
  TCount,
  TOrder,
  TData,
} from 'pages/Accounts/helpers'

export const emptyAccountDetail = createAction('account/emptyDetail')

export const setOnlySelf = createAction<string>('account/setOnlySelf')
export const setMode = createAction<TMode>('account/setMode')
export const setFilterCount = createAction<number>('account/setFilterCount')
export const setFilterParams = createAction<TFilterParams>('account/setFilterParams')
export const setPage = createAction<number>('account/setPage')
export const setPageSize = createAction<number>('account/setPageSize')
export const setTotal = createAction<number>('account/setTotal')
export const setData = createAction<TData>('account/setData')
export const setCounts = createAction<TCount>('account/setCounts')
export const setOrder = createAction<TOrder>('account/setOrder')
export const setStartState = createAction<any>('account/setStartState')
export const setNeedReload = createAction<boolean>('account/setNeedReload')

export type TAccountsParams = {
  account_status: string
  begin_time: string
  end_time: string
  only_self: string
  page: number
}

export async function getAccounts(params: any, ignoreCache: boolean = false) {
  const response = await axiosInstance.request({
    url: 'accounts',
    method: 'get',
    params: {
      ...params,
      stage: params.stage === 'all' ? '' : params.stage,
    },
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

export async function getBrands(brand_name: string) {
  const response = await axiosInstance.request({
    url: 'accounts/brands',
    method: 'get',
    params: {
      brand_name,
    },
  })
  return response.data
}

export async function getOnboardingCount() {
  const response = await axiosInstance.request({
    url: 'accounts/count',
    method: 'get',
  })
  return response.data
}

export async function getSaleTeams(keyword?: string) {
  const response = await axiosInstance.request({
    url: 'accounts/crm_teams',
    method: 'get',
    params: {
      keyword,
    },
  })
  return response.data
}

export async function getSaleUsers({
  keyword,
  crm_team_id,
}: {
  keyword?: string
  crm_team_id?: string
}) {
  const response = await axiosInstance.request({
    url: 'accounts/crm_users',
    method: 'get',
    params: {
      keyword,
      crm_team_id,
    },
  })
  return response.data
}

export async function getAssignee() {
  const response = await axiosInstance.request({
    url: 'accounts/assignee',
    method: 'get',
  })
  return response.data
}

export const getAccountDetailApi = createAsyncThunk(
  'accounts/detail',
  async (id: string) => {
    const response = await axiosInstance.request({
      url: `accounts/${id}`,
      method: 'get',
      cache: { ignoreCache: true },
    })
    return response.data
  },
)

// 没有在用
// export async function getBrandProPackageApi(brand_id: number) {
//   const response = await axiosInstance.request({
//     url: `brands/${brand_id}/has_pro_package`,
//     method: 'get',
//     cache: {
//       ignoreCache: true,
//     },
//   })
//   return response.data
// }

export async function getProPackageApi() {
  const response = await axiosInstance.request({
    url: 'accounts/pro_package_info',
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
