/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement } from 'react'
import cn from 'classnames'

import { Dialog } from '@headlessui/react'
import Button, { TButtonProp } from 'components/Button'

import ModalCore from './core'

export interface TModalProp {
  className?: string
  open: boolean
  maskClosable?: boolean
  toggle: () => void
  children?: ReactElement | ReactElement[]
  title?: string | ReactElement
  footer?: null | ReactElement | ReactElement[]
  footerLeftElement?: ReactElement | ReactElement[]
  onOk?: () => void
  onCancel?: () => void
  isCancelBtn?: boolean
  okText?: string
  cancelText?: string
  okButtonProps?: Omit<TButtonProp, 'children' | 'onClick'>
  cancelButtonProps?: Omit<TButtonProp, 'children' | 'onClick'>
}

export default function Modal({
  className,
  open,
  maskClosable = true,
  title,
  toggle,
  footer,
  footerLeftElement,
  children,
  okText = 'CONFIRM',
  cancelText = 'CANCEL',
  isCancelBtn = true,
  okButtonProps,
  cancelButtonProps,
  onOk = toggle,
  onCancel = toggle,
}: TModalProp) {
  const getDefaultFooter = () => {
    return (
      <>
        <div>{footerLeftElement}</div>
        <div className='flex'>
          {isCancelBtn && (
            <Button
              className='mr-4'
              color='secondary'
              {...cancelButtonProps}
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          )}
          <Button {...okButtonProps} onClick={onOk}>
            {okText}
          </Button>
        </div>
      </>
    )
  }

  return (
    <ModalCore
      className={cn(
        'w-560 border rounded-2xl bg-dark-500 border-dark-100 ',
        className,
      )}
      open={open}
      maskClosable={maskClosable}
      toggle={toggle}
    >
      <>
        {title && (
          <Dialog.Title
            as='h3'
            className='py-6 text-base font-bold text-light-700 text-center border-b border-dark-100'
          >
            {title}
          </Dialog.Title>
        )}
      </>
      <>{children && <div className='p-8'>{children}</div>}</>
      <>
        {footer !== null && (
          <div className='flex justify-between items-center py-6 px-8 border-t border-dark-100'>
            {footer || getDefaultFooter()}
          </div>
        )}
      </>
    </ModalCore>
  )
}

// const handleEnter = (e: KeyboardEvent) => {
//   if (e.key === 'Enter') {
//     e.preventDefault()
//     if (!(okButtonProps?.loading || okButtonProps?.disabled)) {
//       onOk()
//     }
//   }
// }
// useEffect(() => {
//   if (!open) {
//     return
//   }
//   document.addEventListener('keydown', handleEnter)
//   return () => {
//     document.removeEventListener('keydown', handleEnter)
//   }
// }, [open])
