export type TSalesType = 'AE' | 'SE'

export const PACING = {
  on_track: { name: 'On Track', color: 'green' },
  progressing: { name: 'Progressing', color: 'orange' },
  off_track: { name: 'Off Track', color: 'red' },
}

export type TPacing = keyof typeof PACING

export type TTableForm = {
  page?: number
  total?: number
  pageSize?: number
  option: string
  sort_by: string
  sort: string
}

export type TTabKey = 'launch' | 'setup_fee' | 'early_cancel' | 'effort_points'

export type TBonus = {
  adjustment_amount: string
  cancellation_quantity: string
  closer_id: number
  self_gen_bonus: string
  self_gen_quantity: string
  setup_fee_amount: string
  setup_fee_bonus: string
  total_bonus: string
  volume_amount: string
  launch_bonus: string
  effort: string
  effort_bonus: string
  effort_bonus_coe: number
  volume_commission_amount: string
  setup_fee_commission_amount: string
}

export type TCount = {
  activity_name: string
  id: number
  quantity: number
  type: string
  unit_pt: number
  value: string
}
