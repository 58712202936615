import React, { useEffect, useState } from 'react'

import cn from 'classnames'
import { Loading } from '@rushable/icons'
import Pagination from 'components/Pagination'
import THDropdown from 'components/THDropdown'

import styles from './index.module.scss'

export type TRowProp = {
  [index: string]: any
}

export type TCustomValueProp = any

export type TOnSort = (key: string, value: { label: string; value: string }) => void

export type TColumnProp = {
  key: string
  name: string
  align?: string
  custom?: (value: TCustomValueProp, row: TRowProp, index: number) => JSX.Element | null
  className?: string
  tdClassName?: string
  thClassName?: string
  stickyClassName?: string
  sortable?: boolean
  sortDirection?: string
  customSortValue?: string
  sortOptions?: { label: string; value: string }[]
  tdNoHidden?: boolean
  sticky?: boolean
  onSortDirection?: TOnSort
}

export type TTableProp = {
  loading?: boolean
  sticky?: boolean
  resizePageSize?: number
  paginationShow?: boolean
  total?: number
  current?: number
  columns: Array<TColumnProp>
  data: Array<TRowProp>
  containerClassName?: string
  className?: string
  bodyClassName?: string
  trClassName?: string
  theadClassName?: string
  clickedRow?: (row: TRowProp) => void
  theadTrClassName?: string
  theadTextClassName?: string
  onPageNoChange?: (value: any) => void
}

export default function Table({
  loading = false,
  sticky = false,
  resizePageSize,
  paginationShow = true,
  total = 0,
  current = 1,
  columns,
  data,
  containerClassName = '',
  className = 'min-w-full',
  bodyClassName,
  trClassName,
  theadClassName,
  clickedRow,
  theadTrClassName,
  theadTextClassName,
  onPageNoChange,
}: TTableProp): JSX.Element {
  const [openTHDropdown, setOpenTHDropdown] = useState({
    open: false,
    key: '',
    name: '',
  })
  const [pageSize, setPageSize] = useState(20)
  const [tableData, setTableData] = useState(Array<TRowProp>())
  const [hoverItem, setHoverItem] = useState<any>({
    rowIndex: -1,
    isHover: false,
  })

  useEffect(() => {
    setTableData(data)
  }, [data])

  useEffect(() => {
    if (resizePageSize) {
      setPageSize(resizePageSize)
    }
  }, [resizePageSize])

  return (
    <>
      <div className={cn(containerClassName)}>
        <table
          className={cn(
            className,
            styles.table,
            styles.fixedHeader,
            loading && 'pointer-events-none',
          )}
        >
          <thead
            className={cn(
              'text-xs border-b border-dashed border-dark-100',
              theadClassName,
            )}
          >
            <tr className={theadTrClassName}>
              {columns.map((column: TColumnProp, index: number) => (
                <th
                  key={`th-${column.name}-${column.key}`}
                  scope='col'
                  className={cn(
                    column.className,
                    column.sticky && 'sticky z-10',
                    column.thClassName ??
                      `text-xs text-light-300  ${!column.sortable && 'px-4 py-2 '} `,
                  )}
                  style={{
                    left: column.sticky ? column.stickyClassName : undefined,
                  }}
                >
                  {column.sortable ? (
                    <THDropdown
                      open={
                        openTHDropdown.open &&
                        column.key === openTHDropdown.key &&
                        column.name === openTHDropdown.name
                      }
                      className={cn(
                        column.align === 'right'
                          ? 'justify-end'
                          : column.align === 'center'
                          ? 'justify-center'
                          : '',
                      )}
                      name={column.name}
                      sort={column.sortDirection}
                      customValue={column.customSortValue}
                      options={column.sortOptions}
                      onOptionChange={value => {
                        column.onSortDirection?.(column.key, value)
                        setOpenTHDropdown({ open: false, key: '', name: '' })
                      }}
                      openTHDropdown={() => {
                        let dropdownOpen = false
                        if (!openTHDropdown.open) {
                          dropdownOpen = true
                        } else {
                          dropdownOpen =
                            openTHDropdown.name === column.name ? false : true
                        }
                        setOpenTHDropdown({
                          open: dropdownOpen,
                          key: column.key,
                          name: column.name,
                        })
                      }}
                      closeTHDropdown={() => {
                        setOpenTHDropdown({ open: false, key: '', name: '' })
                      }}
                    />
                  ) : (
                    <div
                      className={cn(
                        theadTextClassName,
                        column.align ? 'text-' + column.align : '',
                      )}
                    >
                      {column.name}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={cn('mt-4 relative flex flex-col space-y-2', bodyClassName)}>
            {loading && (
              <tr className='w-full h-full'>
                <td className='w-full h-full flex items-center'>
                  <div className={`flex items-center ${sticky && 'sticky left-4'}`}>
                    <Loading className='text-light-500 mr-2 animate-spin' size={16} />
                    <span className='text-light-500'>Loading</span>
                  </div>
                </td>
              </tr>
            )}

            {tableData.length > 0 ? (
              <>
                {tableData.map((row: TRowProp, rowIndex: number) => (
                  <tr
                    key={`tr-${rowIndex}`}
                    className={cn(
                      'hover:bg-dark-500 hover:cursor-pointer',
                      { 'opacity-40': loading },
                      { 'cursor-pointer': !!clickedRow },
                      trClassName,
                    )}
                    onMouseOut={() => setHoverItem({ rowIndex: -1, isHover: false })}
                    onMouseOver={() => setHoverItem({ rowIndex, isHover: true })}
                    onClick={() => {
                      clickedRow && clickedRow(row)
                    }}
                  >
                    {columns.map((column: TColumnProp, index: number) => (
                      <td
                        key={index}
                        className={cn(
                          column.className,
                          column.tdClassName ??
                            `text-light-500 text-sm ${
                              hoverItem?.rowIndex === rowIndex &&
                              hoverItem.isHover &&
                              'bg-dark-500'
                            }`,
                          column.sticky ? 'sticky z-10' : 'relative',
                        )}
                        style={{
                          left: column.sticky ? column.stickyClassName : undefined,
                        }}
                      >
                        <div
                          className={cn(
                            column.tdNoHidden ? '' : styles.tdHidden,
                            column.align ? 'text-' + column.align : '',
                          )}
                        >
                          {column.custom
                            ? column.custom(
                                row[column.key],
                                Object.assign({ id: rowIndex }, row),
                                rowIndex,
                              )
                            : row[column.key]}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <tr className={cn(trClassName)}>
                {!loading && <td colSpan={columns.length}>No data</td>}
              </tr>
            )}
          </tbody>
        </table>
        {total > pageSize && paginationShow ? (
          <Pagination
            className={`py-4 border-t border-dashed border-dark-100 ${
              sticky && 'sticky left-0'
            }`}
            current={current}
            total={total}
            pageSize={pageSize}
            onChange={onPageNoChange}
          />
        ) : null}
      </div>
    </>
  )
}
