import { useState, useEffect, ReactElement } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'

import { AdminLayout, AdminPageTitle } from 'components/Layout'
import Tabs from 'components/Tabs'
import { getBonus } from 'redux/sales'

import BonusCalculationSideBar from './components/BonusCalculationSideBar'
import BonusCalculationTable from './components/BonusCalculationTable'
import EffortPointsCalTable from './components/EffortPointsCalTable'

import type { TSalesType, TTabKey } from './helpers/constant'

export default function SalesDetail({ salesType }: { salesType: TSalesType }) {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const option = searchParams.get('option')
  const [closer, setCloser] = useState('')
  const [effortSideBar, setEffortSideBar] = useState<ReactElement>()
  const [bonus, setBonus] = useState<any>({})
  const [tabValue, setTabValue] = useState<TTabKey>('launch')

  useEffect(() => {
    if (id && option) {
      getBonusData()
    }
  }, [])

  const getBonusData = async () => {
    try {
      const res = await getBonus({ id: id!, month: option! })
      const fname = res?.closer?.user?.first_name ?? ''
      const lname = res?.closer?.user?.last_name ?? ''
      setCloser(`${fname} ${lname}`)
      setBonus(res.bonus || {})
    } catch (err: any) {
      toast.error(err?.message)
    }
  }

  const handleGetEffortSide = (el: ReactElement) => {
    setEffortSideBar(el)
  }

  const renderSideBar = () => {
    if (tabValue === 'effort_points') {
      return effortSideBar
    }
    return <BonusCalculationSideBar bonus={bonus} />
  }

  const timeMonth = moment(option, 'YYYY-MM').format('MMM YYYY')

  return (
    <AdminLayout showNavs={false}>
      <AdminPageTitle title={`${closer} • ${timeMonth}`} />
      <div className='flex'>
        {renderSideBar()}
        <div className='flex-1 mt-4 mb-10 px-10'>
          <Tabs activeKey={tabValue} isCache={true} onChange={setTabValue}>
            <Tabs.Panel tabKey='launch' tab='Launch'>
              <BonusCalculationTable tabKey='launch' />
            </Tabs.Panel>
            <Tabs.Panel tabKey='setup_fee' tab='Setup Fee'>
              <BonusCalculationTable tabKey='setup_fee' />
            </Tabs.Panel>
            <Tabs.Panel tabKey='early_cancel' tab='Early Cancellation'>
              <BonusCalculationTable tabKey='early_cancel' />
            </Tabs.Panel>
            <Tabs.Panel
              tabKey='effort_points'
              tab='Effort Points'
              isHidden={salesType === 'SE'}
            >
              <EffortPointsCalTable renderSide={handleGetEffortSide} />
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
    </AdminLayout>
  )
}
