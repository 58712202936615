import currency from 'currency.js'
import PersonName from 'components/PersonName'
import PaymentInfo from 'components/PaymentInfo'
import StageProgress from 'components/StageProgress'
import DateAndTime from 'components/DateAndTime'
import GBPTracking from 'components/GBPTracking'
import Pricing from 'components/Pricing'
import type { TOrder } from '../../helpers'
import { RUSHABLE_MONTHLY_PLAN_ID, RUSHABLE_ANNUAL_PLAN_ID } from 'utils/constant'

export type TSortableProps = {
  className?: string
  sortData: TOrder
  name?: string
  period?: string
  handleOrderChange: (newOrder: TOrder) => void
  handlePeriodChange?: (newPeriod: string) => void
}

export function pricingRender({
  className,
  sortData,
  handleOrderChange,
}: TSortableProps) {
  return {
    key: 'pricing',
    name: 'PRICING',
    className,
    sortable: true,
    align: 'right',
    sortDirection: sortData.sort_by === 'pricing' ? sortData.sort : '',
    onSortDirection: (key: string, item: { label: string; value: string }) => {
      const { value } = item
      handleOrderChange({
        sort_by: 'pricing',
        sort: value,
      })
    },
    custom: (item: any) => {
      const { rushable_plan_id, price, convenience_fee } = item
      if (rushable_plan_id) {
        if (rushable_plan_id === Number(RUSHABLE_MONTHLY_PLAN_ID)) {
          return '$119.00/mo'
        }
        if (rushable_plan_id === Number(RUSHABLE_ANNUAL_PLAN_ID)) {
          return '$1180.00/yr'
        }
      }
      return (
        <div className='flex justify-end'>
          {!price && !convenience_fee ? (
            '-'
          ) : (
            <Pricing
              store={price ? currency(price).format() : '-'}
              userGroups={convenience_fee ? currency(convenience_fee).format() : '-'}
            />
          )}
        </div>
      )
    },
  }
}

export function closerRender(className?: string, PersonClassName?: string) {
  return {
    key: 'closer',
    name: 'CLOSER',
    className,
    custom: (item: any) => {
      return (
        <div className='flex items-center'>
          <PersonName className={PersonClassName} avatar={item.avatar} name={item.name} />
        </div>
      )
    },
  }
}

export function paymentRender({
  className,
  sortData,
  handleOrderChange,
}: TSortableProps) {
  return {
    key: 'payment',
    name: 'PAYMENT',
    className,
    sortable: true,
    sortDirection: sortData.sort_by === 'payment' ? sortData.sort : '',
    onSortDirection: (key: string, item: { label: string; value: string }) => {
      const { value } = item
      handleOrderChange({
        sort_by: 'payment',
        sort: value,
      })
    },
    custom: (value: any) => {
      return (
        <PaymentInfo
          last4={value.last_4}
          brand={value.brand || 'unknown'}
          expireDate={value.exp}
        />
      )
    },
  }
}

export function stageDaysRender({
  className,
  sortData,
  handleOrderChange,
}: TSortableProps) {
  return {
    key: 'stage_days',
    name: 'STAGE DAYS',
    className,
    align: 'right',
    sortable: true,
    sortDirection: sortData.sort_by === 'stage_days' ? sortData.sort : '',
    onSortDirection: (key: string, item: { label: string; value: string }) => {
      const { value } = item
      handleOrderChange({
        sort_by: 'stage_days',
        sort: value,
      })
    },
    custom: (value: any) => {
      return <div className='w-full'>{value ? `${value} days` : '-'}</div>
    },
  }
}

export function stageRender({ className, sortData, handleOrderChange }: TSortableProps) {
  return {
    key: 'onboarding',
    name: 'STAGE',
    className,
    align: 'right',
    tdNoHidden: true,
    sortable: true,
    sortDirection: sortData.sort_by === 'stage' ? sortData.sort : '',
    onSortDirection: (key: string, item: { label: string; value: string }) => {
      const { value } = item
      handleOrderChange({
        sort_by: 'stage',
        sort: value,
      })
    },
    custom: (value: any, item: any) => {
      return (
        <StageProgress
          className='ml-auto'
          popTop={item.index >= 8}
          onboarding={item.onboarding}
          signupForm={item.signup_form}
          statusName={item.stage}
          isOwnershipTransfer={item.is_ownership_transfer}
        />
      )
    },
  }
}

export function followupRender({
  className,
  sortData,
  name,
  handleOrderChange,
}: TSortableProps) {
  return {
    key: 'onboarding_followup',
    name,
    className,
    align: 'right',
    sortable: true,
    sortDirection: sortData.sort_by === 'onboarding_followup' ? sortData.sort : '',
    onSortDirection: (key: string, item: { label: string; value: string }) => {
      const { value } = item
      handleOrderChange({
        sort_by: 'onboarding_followup',
        sort: value,
      })
    },
    custom: (value: any) => {
      return (
        <div className='w-full'>
          {value?.time ? (
            <DateAndTime
              value={value.time}
              parse='YYYY-MM-DD HH:mm:ss'
              format='MMM DD @ hh:mm A'
              timezone={value.timezone}
            />
          ) : (
            '-'
          )}
        </div>
      )
    },
  }
}

export function successFollowupRender({
  className,
  sortData,
  name,
  handleOrderChange,
}: TSortableProps) {
  return {
    key: 'success_followup',
    name,
    className,
    align: 'right',
    sortable: true,
    sortDirection: sortData.sort_by === 'success_followup' ? sortData.sort : '',
    onSortDirection: (key: string, item: { label: string; value: string }) => {
      const { value } = item
      handleOrderChange({
        sort_by: 'success_followup',
        sort: value,
      })
    },
    custom: (value: any) => {
      return (
        <div className='w-full'>
          {value?.time ? (
            <DateAndTime
              value={value.time}
              parse='YYYY-MM-DD HH:mm:ss'
              format='MMM DD @ hh:mm A'
              timezone={value.timezone}
            />
          ) : (
            '-'
          )}
        </div>
      )
    },
  }
}

export function gbpRender({ className, sortData, handleOrderChange }: TSortableProps) {
  return {
    key: 'gbp',
    name: 'GBP',
    className,
    sortable: true,
    sortDirection: sortData.sort_by === 'gbp' ? sortData.sort : '',
    onSortDirection: (key: string, item: { label: string; value: string }) => {
      const { value } = item
      handleOrderChange({
        sort_by: 'gbp',
        sort: value,
      })
    },
    custom: (value: any) => {
      return (
        <GBPTracking
          className='justify-start'
          websiteLink={value.websiteLink}
          orderingLink={value.orderingLink}
        />
      )
    },
  }
}

export function weekSalesRender({
  className,
  sortData,
  handleOrderChange,
}: TSortableProps) {
  return {
    key: 'week_sales',
    name: '4 WEEK',
    className,
    align: 'right',
    tdNoHidden: true,
    sortable: true,
    sortDirection: sortData.sort_by === '4_week' ? sortData.sort : '',
    onSortDirection: (key: string, item: { label: string; value: string }) => {
      const { value } = item
      handleOrderChange({
        sort_by: '4_week',
        sort: value,
      })
    },
    custom: (value: any) => {
      return <div className='w-full flex justify-end'>{currency(value).format()}</div>
    },
  }
}
