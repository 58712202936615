import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import { affiliateReducer } from './affiliate'
import { authReducer } from './auth'
import { accountDetailReducer } from './account'
import { payoutsReducer } from './payouts'
import { LogisticsReducer } from './logistics'

const store = configureStore({
  reducer: {
    auth: authReducer,
    account: accountDetailReducer,
    payouts: payoutsReducer,
    affiliate: affiliateReducer,
    logistics: LogisticsReducer,
  },
  devTools: process.env.NODE_ENV === 'development' ? true : false,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {pastelID: PastelIDState, errorModal: IErrorModalState, ...}
export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store
