import SingleInput from 'components/SingleInput'
import { Select, BrandSelect } from 'components/Select'
import { IOptions } from 'components/Select/help'
import { LinedSearch } from '@rushable/icons'
import { TFilterForm, statusOptions } from '../helpers/constant'

export type TSearchFilterProps = {
  filterForm: TFilterForm
  locationOptions: IOptions[]
  handleFormChange: (type: string, value: string | number) => void
  changeForm: (item: any) => void
}

export default function SearchFilter({
  filterForm,
  locationOptions,
  handleFormChange,
  changeForm,
}: TSearchFilterProps) {
  const { status, brand_name, location_id, order_id } = filterForm
  return (
    <div className='flex space-x-4'>
      <Select
        className='w-60'
        options={statusOptions}
        value={status}
        onChange={value => handleFormChange('status', value)}
      />
      <BrandSelect
        className='w-60'
        placeholder='Search'
        value={brand_name}
        apiType='credits'
        paramsOptions={{ type: 'list' }}
        isShowLocation={false}
        isShowAllBrands={false}
        onChange={(item: any, rest: any) => changeForm(rest)}
      />
      {brand_name && brand_name !== 'All Brands' ? (
        <Select
          key={location_id}
          className='w-60'
          options={locationOptions}
          value={location_id}
          onChange={value => handleFormChange('location_id', value)}
        />
      ) : null}
      <SingleInput
        name='Order_ID'
        className='w-60'
        placeholder='Search Order ID'
        iconAfter={<LinedSearch className='text-light-300' size={16} />}
        value={order_id}
        onChange={e => {
          handleFormChange('order_id', e.target.value)
        }}
      />
    </div>
  )
}
