import React from 'react'
import cn from 'classnames'
import { SolidHandRaise, SolidGoogle } from '@rushable/icons'

export type TAttentionStatusProps = {
  className?: string
  handRaise: boolean
  google: boolean
}

export default function AttentionStatus({
  className,
  handRaise,
  google,
}: TAttentionStatusProps): JSX.Element {
  const cls = className?.includes('w-') ? className : `w-full ${className}`
  return (
    <div className={cn('ru-AttentionStatus flex  items-center', cls)}>
      <SolidHandRaise
        size={20}
        className={cn(handRaise ? 'text-yellow' : 'text-dark-100')}
      />
      <SolidGoogle
        size={20}
        className={cn('ml-2', google ? 'text-yellow' : 'text-dark-100')}
      />
    </div>
  )
}
