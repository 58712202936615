import { useEffect, useState } from 'react'
import { Select } from 'components/Select'
import { getSEListApi } from 'redux/account'
import { toast } from 'react-toastify'

type TSE = {
  data: any
  disabled: boolean
  onChange: (value: string) => void
}
export default function SolutionEngineer(props: TSE) {
  const { data, disabled, onChange } = props
  // SE 列表
  const [seList, setSeList] = useState<any[]>([])
  useEffect(() => {
    getSEList()
  }, [])

  // 获取 solution engineer 列表
  const getSEList = async () => {
    try {
      const res = await getSEListApi()

      const list = res.map((v: any) => ({
        img_url: v.profile_photo,
        value: v.id,
        label: `${v.first_name} ${v.last_name}`,
      }))
      const none = {
        img_url: '',
        value: 'none',
        label: 'None',
      }
      setSeList([...list, none])
    } catch (error: any) {
      toast.error(error.message)
    }
  }
  // 为了解决 select 组件 option value 不能为 null 的情况
  const value = data.solution_engineer_id === null ? 'none' : data.solution_engineer_id
  return (
    <Select
      label='SOLUTION ENGINEER'
      className='flex-1'
      isPerson
      value={value}
      disabled={disabled || data.source === 'affiliate'}
      onChange={value => onChange(value === 'none' ? null : value)}
      options={seList}
    />
  )
}
