import React from 'react'
import { RangePicker } from 'components/DatePicker'
import SingleInput from 'components/SingleInput'
import { LinedSearch } from '@rushable/icons'

export type TBlocklistFilterProp = {
  filterForm: any
  handleFormChange: (type: string, value: string | number) => void
}

export default function BlocklistFilter({
  filterForm,
  handleFormChange,
}: TBlocklistFilterProp): JSX.Element {
  const { keyword } = filterForm

  return (
    <div className='flex space-x-4'>
      <RangePicker
        className='w-216'
        placeholder='Date Range'
        onChange={(e: any) => {
          handleFormChange('dates', e)
        }}
      />
      <SingleInput
        name='Keyword'
        className='w-216'
        placeholder='Customer Name'
        iconAfter={<LinedSearch className='text-light-300' size={16} />}
        value={keyword}
        onChange={e => {
          handleFormChange('keyword', e.target.value)
        }}
      />
    </div>
  )
}
