import cn from 'classnames'
import { Loading } from '@rushable/icons'

export type Iprops = {
  className?: string
  show?: boolean
}

export default function PageLoading({
  className,
  show = true,
}: Iprops): JSX.Element | null {
  if (!show) {
    return null
  }
  return (
    <div className={cn(className || 'flex mt-20 justify-center')}>
      <Loading size={24} />
    </div>
  )
}
