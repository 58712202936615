import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import { Select } from 'components/Select'
import Label from 'components/Label'
import { DateAndTimePicker } from 'components/DatePicker'
import useDebounce from 'hooks/useDebounce'
import PremiumAddOnTable from '../../components/PremiumAddOnTable'
import UserTable from '../../components/UserTable'

import CheckList from './CheckList'
import Communication from './Communication'
import { DOMAIN, RUSHABLE_TIMEZONE } from 'data/constant'
import {
  getOnboardingInfoApi,
  getAssigneeListApi,
  updateOnboardingApi,
  TupdateOnboarding,
  getCommentApi,
} from 'redux/account'
import moment from 'moment'
import SingleInput from 'components/SingleInput'

const DISABLEDEDIT = ['canceled', 'done']

export default function OnboardingTab() {
  const urlParams = useParams()
  const [onboardingInfo, setOnboarding] = useState<any>({})
  const [commentInfo, setCommentInfo] = useState<any>({
    data: [],
    cursor: '',
    showCommentBtn: false,
  })
  const [loading, setLoading] = useState(false)
  const [shippingAddress, setShippingAddress] = useState('')
  const [assigneeOptions, setAssigneeOptions] = useState([])
  const [assignee, setAssignee] = useState('')
  const [accountUsers, setAccountUsers] = useState<any>([])
  const [followupTime, setFollowupTime] = useState({
    local: '',
    rushable: '',
    tz: '',
  })

  useEffect(() => {
    getAssigneeList()
    getOnboardingInfo()
    getCommentInfo()
  }, [])

  const getCommentInfo = async (cursor?: string) => {
    try {
      const res = await getCommentApi({
        account_id: urlParams.id!,
        cursor: cursor === '' ? cursor : commentInfo.cursor,
      })
      setCommentInfo({
        data: cursor === '' ? [...res.data] : [...commentInfo.data, ...res.data],
        cursor: res.next_cursor,
        showCommentBtn: !!res.next_cursor,
      })
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  const getAssigneeList = async () => {
    try {
      const res = await getAssigneeListApi()
      const list = res.map((item: any) => ({
        img_url: item.profile_photo ? `${DOMAIN}${item.profile_photo}` : null,
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
      }))
      setAssigneeOptions(list)
    } catch (error: any) {
      console.log(error)
      toast.error(error.message)
    }
  }

  const getOnboardingInfo = async () => {
    try {
      const res = await getOnboardingInfoApi(urlParams.id!)
      initData(res)
    } catch (error: any) {
      console.log(error)
      toast.error(error.message)
    }
  }

  const initData = (res: any) => {
    // SHIPPING ADDRESS
    setShippingAddress(res.shipping_address ?? '')
    setAssignee(res.project_lead?.id)
    setAccountUsers(res.users || [])
    setOnboarding(res)

    const timezone = res.address?.timezone || RUSHABLE_TIMEZONE
    const time = res.followup_time
    setFollowupTime({
      local: time && moment(time).tz(timezone).format('YYYY-MM-DD HH:mm:ss'),
      rushable: time,
      tz: timezone,
    })
  }

  const updateOnboarding = async (
    params: TupdateOnboarding,
    id = onboardingInfo.id,
    errorCb?: () => void,
  ) => {
    try {
      const res = await updateOnboardingApi(id, params)
      toast.success(res.message)
      getOnboardingInfo()
    } catch (error: any) {
      toast.error(error.message)
      errorCb && errorCb()
    }
  }
  const debounceUpdateOnboarding = useDebounce(updateOnboarding, onboardingInfo.id)
  // canceled done 状态下禁止编辑 (包括api返回数据之前)
  const formDisabled =
    DISABLEDEDIT.includes(onboardingInfo.status) || !onboardingInfo.status
  return (
    <div>
      <div className='mb-8'>
        <Label>ACCOUNT USERS</Label>
        <UserTable
          className='mt-2 py-2'
          type='onboarding'
          disabled={formDisabled}
          items={accountUsers}
          onCheck={(checked: boolean, item: any, index: number) => {
            accountUsers[index].loading = true
            setAccountUsers([...accountUsers])
            updateOnboarding(
              { employment_id: item.employment_id, person_of_contact: checked ? 1 : 0 },
              onboardingInfo.id,
              () => {
                // api 失败时停止loading
                accountUsers[index].loading = false
                setAccountUsers([...accountUsers])
              },
            )
          }}
        />
      </div>
      <div className='mb-8 flex space-x-8'>
        <SingleInput
          label='SHIPPING ADDRESS'
          name='SHIPPING_ADDRESS'
          className='flex-1'
          placeholder='Search'
          disabled={formDisabled}
          value={shippingAddress}
          onChange={e => {
            const value = e.target.value
            setShippingAddress(value)
            debounceUpdateOnboarding({ shipping_address: value }, onboardingInfo.id)
          }}
        />
        <Select
          label='ONBOARDING ASSIGNEE'
          className='flex-1'
          placeholder='Select'
          isPerson
          value={assignee}
          disabled={formDisabled}
          options={assigneeOptions}
          onChange={value => {
            setAssignee(value)
            updateOnboarding({ assignee_id: value })
          }}
        />
      </div>
      <PremiumAddOnTable
        title='LOGISTICS'
        onboardingId={onboardingInfo.id}
        className='mb-8'
        disabled={formDisabled}
        onSuccess={getOnboardingInfo}
        items={onboardingInfo.onboarding_logistics || []}
      />
      <div className='mb-8 flex justify-between space-x-8'>
        <div className='flex-1'>
          <CheckList
            disabled={formDisabled}
            onboardingId={onboardingInfo.id}
            onboardingInfo={onboardingInfo}
            onSuccess={getOnboardingInfo}
          />
        </div>
        <div className='flex-1'>
          <div className='flex space-x-4 mb-6'>
            <div className='flex-1'>
              <Label className='mb-2'>LOCAL FOLLOWUP TIME</Label>
              <DateAndTimePicker
                value={followupTime.local}
                disabledDate={current => current && current < moment().endOf('day')}
                timezone={followupTime.tz}
                noTransformTz={true}
                disabled={formDisabled}
                onChange={(date: any, dateString: any) => {
                  const rushableTime = dateString
                    ? moment(dateString)
                        .tz(RUSHABLE_TIMEZONE)
                        .format('YYYY-MM-DD HH:mm:ss')
                    : ''

                  setFollowupTime(prev => ({
                    ...prev,
                    local: dateString || '',
                    rushable: rushableTime,
                  }))

                  updateOnboarding({ followup_time: rushableTime })
                }}
                showTime={{
                  use12Hours: true,
                  minuteStep: 15,
                  format: 'HH:mm',
                }}
              />
            </div>
            <div className='flex-1'>
              <Label className='mb-2'>RUSHABLE FOLLOWUP TIME</Label>
              <DateAndTimePicker
                value={followupTime.rushable}
                timezone={RUSHABLE_TIMEZONE}
                noTransformTz={true}
                disabled={formDisabled}
                onChange={(date: any, dateString: any) => {
                  const localTime = dateString
                    ? moment(dateString).tz(followupTime.tz).format('YYYY-MM-DD HH:mm:ss')
                    : ''
                  setFollowupTime(prev => ({
                    ...prev,
                    local: localTime,
                    rushable: dateString,
                  }))
                  updateOnboarding({ followup_time: dateString })
                }}
                showTime={{
                  use12Hours: true,
                  minuteStep: 15,
                  format: 'HH:mm',
                }}
              />
            </div>
          </div>
          <Communication
            disabled={formDisabled}
            onboardingId={onboardingInfo.id}
            attention={onboardingInfo.attention}
            logs={commentInfo.data}
            showCommentBtn={commentInfo.showCommentBtn}
            loading={loading}
            onSuccess={getOnboardingInfo}
            onUpdateComment={value => {
              value !== '' && setLoading(true)
              getCommentInfo(value)
            }}
          />
        </div>
      </div>
    </div>
  )
}
