import { Select, BrandSelect } from 'components/Select'

import { STATUS_OPTIONS } from '../helpers/constant'
import { TFilterForm } from '../helpers/types'

export type TSearchFilterProps = {
  filterForm: TFilterForm
  handleFormChange: (type: string, value: string | number) => void
}

export default function SearchFilter({
  filterForm,
  handleFormChange,
}: TSearchFilterProps) {
  const { status, brand_name } = filterForm

  const onChange = (item: any) => {
    handleFormChange('brand_name', item)
  }

  return (
    <div className='mb-8 flex space-x-4'>
      <Select
        className='w-200'
        placeholder='Select status'
        options={STATUS_OPTIONS}
        value={status}
        onChange={value => handleFormChange('status', value)}
      />
      <BrandSelect
        className='w-200'
        placeholder='Search brand'
        value={brand_name}
        apiType='disputes'
        isShowLocation={false}
        isShowAllBrands={false}
        onChange={onChange}
      />
    </div>
  )
}
