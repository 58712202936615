import { useEffect, useState } from 'react'
import moment from 'moment'
import { PopupModal } from 'react-calendly'
import { useAppSelector } from 'redux/hooks'

import Label from 'components/Label'
import { RUSHABLE_TIMEZONE } from 'data/constant'

import { DateAndTimePicker } from 'components/DatePicker'

interface Tprops {
  onboardingInfo: Record<string, any>
  updateSuccess: (params: any, errorCb?: () => void) => Promise<void>
  onSuccess: () => void
}
export default function SuccessCall({
  onboardingInfo,
  onSuccess,
  updateSuccess,
}: Tprops) {
  const detail = useAppSelector(s => s.account.detail)
  const [calendlyOpen, setCalendary] = useState(false)
  const [successCallTime, setSuccessCallTime] = useState({
    local: '',
    rushable: '',
  })

  const brandName = detail?.location?.brand?.name || detail?.signup_form?.brand_name
  const locationName = detail?.location?.name || detail?.signup_form?.location_name
  const poc = onboardingInfo.users?.find((v: any) => !!v.success_point_of_contact)
  const localTimezone = onboardingInfo?.address?.timezone || RUSHABLE_TIMEZONE

  useEffect(() => {
    const time = onboardingInfo?.success_call_time
    if (time) {
      setSuccessCallTime({
        local: time && moment(time).tz(localTimezone).format('YYYY-MM-DD HH:mm:ss'),
        rushable: time,
      })
    }
  }, [onboardingInfo?.success_call_time])

  return (
    <>
      <div className='flex'>
        <div className='mr-4 flex-1'>
          <Label className='mb-2'>LOCAL SUCCESS CALL TIME</Label>
          <DateAndTimePicker
            value={successCallTime.local}
            timezone={localTimezone}
            noTransformTz
            showTime={{
              use12Hours: true,
              minuteStep: 15,
              format: 'HH:mm',
            }}
            onChange={(date: any, dateString: any) => {
              const rushableTime = dateString
                ? moment(dateString).tz(RUSHABLE_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')
                : ''

              setSuccessCallTime(prev => ({
                ...prev,
                local: dateString || '',
                rushable: rushableTime,
              }))
              updateSuccess({ success_call_time: rushableTime })
            }}
          />
        </div>
        <div className='flex-1'>
          <Label className='mb-2'>RUSHABLE SUCCESS CALL TIME</Label>
          <DateAndTimePicker
            value={successCallTime.rushable}
            timezone={RUSHABLE_TIMEZONE}
            showTime={{
              use12Hours: true,
              minuteStep: 15,
              format: 'HH:mm',
            }}
            onChange={(date: any, dateString: any) => {
              const localTime = dateString
                ? moment(dateString).tz(localTimezone).format('YYYY-MM-DD HH:mm:ss')
                : ''
              setSuccessCallTime(prev => ({
                ...prev,
                local: localTime,
                rushable: dateString,
              }))
              updateSuccess({ success_call_time: dateString })
            }}
          />
        </div>
      </div>

      <PopupModal
        url={onboardingInfo?.welcome_call_links}
        // url={'https://calendly.com/lei-18/30min'}
        prefill={{
          // '默认填成 POC 名字'
          name: poc ? `${poc.first_name} ${poc.last_name}` : '',
          // '默认填成 POC email'
          email: poc ? poc.email : '',
          customAnswers: {
            // 'Restaurant Name: 默认填成 Brand Name @ Location Name'
            a1: `${brandName}@${locationName}`,
            // 'Additional Note: 空'
            a2: '',
            // 'Send text message to: 默认填成 POC cellphone'
            a3: poc ? poc.phone : '',
          },
        }}
        onModalClose={() => {
          setCalendary(false)
          onSuccess()
        }}
        open={calendlyOpen}
        rootElement={document.getElementById('root')!}
      />
    </>
  )
}
