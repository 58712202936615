import { createReducer } from '@reduxjs/toolkit'
import {
  setHideShipped,
  setPage,
  setPageSize,
  setTotal,
  setData,
  setOrder,
} from './action'

import { TSort, TData } from 'pages/Logistics/helpers/constant'

type TState = {
  page: number
  pageSize: number
  total: number
  keyword: string
  hideShipped: string
  sortData: TSort
  data: TData
}

const initialState: TState = {
  page: 1,
  pageSize: 20,
  total: 0,
  keyword: '',
  hideShipped: '1',
  sortData: {
    sort_by: '',
    sort: '',
  },
  data: [],
}

export const LogisticsReducer = createReducer(initialState, builder => {
  builder
    .addCase(setHideShipped, (state, action) => {
      state.hideShipped = action.payload
    })
    .addCase(setPage, (state, action) => {
      state.page = action.payload
    })
    .addCase(setPageSize, (state, action) => {
      state.pageSize = action.payload
    })
    .addCase(setTotal, (state, action) => {
      state.total = action.payload
    })
    .addCase(setData, (state, action) => {
      state.data = action.payload
    })
    .addCase(setOrder, (state, action) => {
      state.sortData = action.payload
    })
})

export default LogisticsReducer
