import Pagination from 'components/Pagination'
import GroupItem from './GroupItem'

import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setGroupOpenIndex } from 'redux/account/group'

type TGroupTableProps = {
  onPageNoChange: (currentPage: number) => void
  updateGroupItem: (
    params: {
      brandName: string
      signupFormIds: number[]
    },
    cb: () => void,
  ) => void
}

export default function GroupTable({
  onPageNoChange,
  updateGroupItem,
}: TGroupTableProps) {
  const dispatch = useAppDispatch()
  const { groupTable, groupOpenIndex } = useAppSelector(state => state.account)
  const { total, data, currentPage, perPage } = groupTable
  const handleOpenChange = (index: number) => {
    dispatch(setGroupOpenIndex(index === groupOpenIndex ? null : index))
  }
  return (
    <div
      className='mx-10 flex flex-col justify-between'
      style={{
        height: 'calc(100vh - 177px)',
      }}
    >
      <div className='flex flex-col space-y-6'>
        {data.length === 0 ? (
          <div>No data</div>
        ) : (
          <>
            {data.map((item, index) => {
              return (
                <GroupItem
                  key={`${item.brand_name}-${index}`}
                  open={index === groupOpenIndex}
                  item={item}
                  handleOpenChange={() => handleOpenChange(index)}
                  updateGroupItem={updateGroupItem}
                />
              )
            })}
          </>
        )}
      </div>
      <div className='pt-4 pb-10 border-t border-dark-100 border-dashed'>
        <Pagination
          current={currentPage}
          total={total}
          pageSize={perPage}
          onChange={onPageNoChange}
        />
      </div>
    </div>
  )
}
