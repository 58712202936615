import React, { useState } from 'react'
import cn from 'classnames'
import { SolidCopy } from '@rushable/icons'

import { copyText } from 'utils/tools'

export type TCopyableTextProps = {
  className?: string
  text: string
  children?: React.ReactElement
}

export default function CopyableText({ className, text, children }: TCopyableTextProps) {
  const [show, setShow] = useState(false)
  const [success, setSuccess] = useState(true)
  const handleCopy = () => {
    copyText(
      text,
      () => {
        setSuccess(true)
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 1300)
      },
      () => {
        setSuccess(false)
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 1300)
      },
    )
  }
  return (
    <div
      className={cn(
        'ru-CopyableText w-full relative flex items-center cursor-pointer',
        className,
      )}
      onClick={() => handleCopy()}
    >
      {children || (
        <>
          {<SolidCopy size={20} className='mr-2 text-light-100' />}
          <span
            className={cn(
              'select-none relative z-10',
              'text-sm text-light-500',
              'text-ellipsis',
            )}
          >
            {text}
          </span>
        </>
      )}
      <span
        className={cn(
          'absolute text-xs font-medium ease-in-out duration-300 left-1/2 -translate-x-1/2',
          success ? 'text-green' : 'text-red',
          show ? 'opacity-1 -top-7' : 'opacity-0 top-0',
        )}
      >
        {success ? 'Copied!' : 'Copied Error!'}
      </span>
    </div>
  )
}
