import { createAction } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axios'

import type { TGroupFormData, TGroupTable } from 'pages/Accounts/helpers/index'
// group 相关

export const setGroupFormData = createAction<TGroupFormData>('group/setGroupFormData')
export const setGroupTable = createAction<TGroupTable>('group/setGroupTable')
export const setGroupOpenIndex = createAction<number | null>('group/setGroupOpenIndex')

// get group list
export async function getGroupListApi(
  params: TGroupFormData,
  ignoreCache: boolean = false,
) {
  const response = await axiosInstance.request({
    url: 'accounts/group_signups',
    method: 'get',
    params,
    cache: {
      ignoreCache,
    },
  })
  return response.data
}

// update group item
export async function updateGroupItemsApi(params: Record<string, any>) {
  const response = await axiosInstance.request({
    url: 'accounts/group_signups',
    method: 'post',
    data: params,
  })
  return response.data
}
