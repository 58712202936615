import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AdminLayout } from 'components/Layout'
import SearchFilter from './components/SearchFilter'
import OwnershipTable from './components/OwnershipTable'
import { getTransferListApi } from 'redux/account/ownershipTransfer'

import { TFilterForm } from './helpers/types'
import PageLoading from 'components/PageLoading'

export default function OwnershipTransferPage() {
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(40)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const [filterForm, setFilterForm] = useState<TFilterForm>({
    status: '',
    brand_name: '',
    page: 1,
    sort: '',
    sort_by: '',
  })

  const handleFormChange = (type: string, value: any) => {
    const page = type === 'page' ? value : 1
    let newForm: TFilterForm = { ...filterForm }
    if (type === 'brand_name') {
      newForm = { ...filterForm, page, brand_name: value }
    } else if (type === 'sort') {
      newForm = { ...filterForm, page, sort: value.value, sort_by: value.type }
    } else {
      newForm = { ...filterForm, page, [type]: value }
    }
    setFilterForm(newForm)
    getTable(newForm)
  }
  const getTable = async (form?: TFilterForm) => {
    const nowForm = form ? form : filterForm
    // 请求 api
    setLoading(true)
    try {
      const res = await getTransferListApi({
        ...nowForm,
        brand_name: nowForm.brand_name === 'All Brands' ? '' : nowForm.brand_name,
        status: nowForm.status === 'all' ? '' : nowForm.status,
      })
      setLoading(false)
      setData(res.data)
      setTotal(res.total)
      setPageSize(res.per_page)
    } catch (e: any) {
      setLoading(false)
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }
  const onPageNoChange = (page: number) => {
    handleFormChange('page', page)
  }

  useEffect(() => {
    getTable(filterForm)
  }, [])
  return (
    <AdminLayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className='px-10 py-8'>
          <SearchFilter filterForm={filterForm} handleFormChange={handleFormChange} />
          <OwnershipTable
            filterForm={filterForm}
            handleFormChange={handleFormChange}
            total={total}
            current={filterForm.page}
            pageSize={pageSize}
            data={data}
            onPageNoChange={onPageNoChange}
          />
        </div>
      )}
    </AdminLayout>
  )
}
