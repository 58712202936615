import React, { useEffect, useState } from 'react'

import Button from 'components/Button'
import BadgeText from 'components/BadgeText'
import Drawer from 'components/Drawer'
import SingleInput from 'components/SingleInput'
import { Select, BrandSelect } from 'components/Select'
import { toast } from 'react-toastify'
import { getBrands, handleCheck, handlePurchase, getTLDs } from 'redux/domains'
import { currencyFormatter } from 'utils/digit'

export type TDomainsRightProp = {
  open: boolean
  toggle: (purchase?: boolean) => void
}

export default function DomainsRight({ open, toggle }: TDomainsRightProp): JSX.Element {
  const [available, setAvailable] = useState(false)
  const [price, setPrice] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkLoading, setCheckLoading] = useState(false)
  const [filterForm, setFilterForm] = useState<any>({
    brand_id: '',
    brand_name: '',
    hostname: '',
    tld: '',
  })
  const [TLDOption, setTLDOption] = useState([])

  useEffect(() => {
    if (open) {
      getDomainTLD()
    } else {
      setAvailable(false)
    }
  }, [open])

  const handleFormChange = (type: string, value: any) => {
    let newForm = { ...filterForm }
    newForm = {
      ...filterForm,
      [type]: value,
    }
    setFilterForm(newForm)
  }

  const getAsyncBrandData = async (inputValue?: any): Promise<any> => {
    const res = await getBrands(inputValue || '')
    const brands = res.map((item: any) => {
      return { ...item, value: item.id }
    })
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(brands)
      }, 500)
    })
  }

  const checkStatus = async () => {
    setCheckLoading(true)
    try {
      const res = await handleCheck({
        domain_name: filterForm.hostname,
        tld: filterForm.tld,
      })
      if (res.availability) {
        setAvailable(res.availability)
      } else {
        setAvailable(res.available)
        setPrice(res.price)
      }
      setIsEdit(true)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setCheckLoading(false)
  }

  const purchaseDomain = async () => {
    setLoading(true)
    try {
      const res = await handlePurchase({
        domain_name: filterForm.hostname,
        brand_id: filterForm.brand_id,
        tld: filterForm.tld,
      })
      toast.success(res.message)
      handleToggle(true)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }

  const getDomainTLD = async () => {
    try {
      const res = await getTLDs()
      let tldList: any = []
      res.forEach((v: any) => {
        tldList.push({ label: v, value: v.slice(1, v.length) })
      })
      setTLDOption(tldList)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }

  const handleToggle = (value: boolean = false) => {
    setIsEdit(false)
    setFilterForm({ brand_id: '', brand_name: '', hostname: '', tld: '' })
    toggle(value)
  }

  return (
    <div>
      <Drawer
        title='PURCHASE DOMAIN'
        open={open}
        isCancelBtn
        cancelText='CLOSE'
        okText='CONFIRM TO PURCHASE'
        okButtonProps={{
          disabled: !available || Number(price) >= 15,
          loading: loading,
        }}
        onOk={() => purchaseDomain()}
        toggle={() => handleToggle()}
      >
        <div className='flex flex-col'>
          <BrandSelect
            label='SELECT BRAND'
            placeholder='Search brand'
            value={filterForm.brand_name}
            onChange={(value, item) => {
              setFilterForm({
                ...filterForm,
                brand_name: value,
                brand_id: item.id,
              })
            }}
            isShowCreateBrand={false}
            requestApi={getAsyncBrandData}
          />
          <div className='mt-6 flex items-end space-x-4'>
            <SingleInput
              disabled={isEdit}
              value={filterForm.hostname}
              name='SEARCH_DOMAIN'
              label='SEARCH DOMAIN'
              placeholder='Input'
              onChange={event => handleFormChange('hostname', event.target.value)}
            />
            <Select
              disabled={isEdit}
              className='w-[169px]'
              label={'SELECT TLD'}
              value={filterForm.tld}
              onChange={(value, item) => {
                handleFormChange('tld', value)
              }}
              options={TLDOption}
            ></Select>
            <Button
              loading={checkLoading}
              theme='outlined'
              color='secondary'
              onClick={() => {
                if (isEdit) {
                  setIsEdit(false)
                  handleFormChange('hostname', '')
                } else {
                  checkStatus()
                }
              }}
            >
              {isEdit ? 'EDIT' : 'CHECK'}
            </Button>
          </div>
          {isEdit && (
            <div className='mt-2 flex space-x-2'>
              <BadgeText
                color={available ? 'green' : 'red'}
                value={available ? 'Available' : 'Unavailable'}
              />
              {!!price && (
                <BadgeText
                  color={Number(price) < 15 ? 'green' : 'red'}
                  value={currencyFormatter(Number(price))}
                />
              )}
            </div>
          )}
        </div>
      </Drawer>
    </div>
  )
}
