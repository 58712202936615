import { SolidLink, SolidFile, LinedTrash, Loading } from '@rushable/icons'
import Button from 'components/Button'
import IconButton from 'components/IconButton'

type TmenuItem = {
  id: number
  type: 'link' | 'file'
  src: string
  link: string
}

type Tprops = {
  item: TmenuItem
  hasDelete?: boolean
  deleteLoading?: boolean
  onDeleteFile?: (item: TmenuItem) => void
}

export default function MenuItem({
  item,
  hasDelete = true,
  onDeleteFile,
  deleteLoading = false,
}: Tprops) {
  return (
    <div className='w-full px-4 py-3 my-2 border border-dark-100 rounded-lg flex items-center justify-between'>
      <div className='flex'>
        {item.type === 'file' ? (
          <SolidFile className='text-light-100' size={16} />
        ) : (
          <SolidLink className='text-light-100' size={16} />
        )}
      </div>
      <div className='pl-2 flex-1 truncate w-300'>{item.src}</div>
      <div className='flex'>
        <Button color='primary' theme='text' href={item.link} target='_blank'>
          OPEN
        </Button>
        {hasDelete && (
          <IconButton
            className='ml-6'
            icon={
              deleteLoading ? (
                <Loading size={16} />
              ) : (
                <LinedTrash size={16} className='text-light-500' />
              )
            }
            hasHover={false}
            onClick={() => onDeleteFile?.(item)}
          />
        )}
      </div>
    </div>
  )
}
