import { useAppSelector } from 'redux/hooks'
import { useNavigate } from 'react-router-dom'
import Table, { TRowProp } from 'components/Table'
import BadgeText from 'components/BadgeText'
import PersonName from 'components/PersonName'
import StageProgress from 'components/StageProgress'
import moment from 'moment'

export type TLogisticsTableProp = {
  className?: string
  loading: boolean
  handleFormChange: (key: string, value: any) => void
  clickedRow: (row: TRowProp) => void
}

export default function LogisticsTable({
  className = '',
  loading,
  handleFormChange,
  clickedRow,
}: TLogisticsTableProp): JSX.Element {
  const navigate = useNavigate()
  const { page, total, pageSize, data, sortData } = useAppSelector(
    state => state.logistics,
  )
  const { sort_by, sort } = sortData
  const columns = [
    {
      key: 'onboarding_logistics_status',
      name: 'STATUS',
      className: 'w-[104px]',
      sortable: true,
      sortDirection: sort_by === 'status' ? sort : '',
      onSortDirection: (key: string, item: { label: string; value: string }) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'status',
          value,
        })
      },
      custom: (value: string) => {
        let color: any = ''
        let text: string = ''
        switch (value) {
          case 'approved':
            color = 'blue'
            text = 'Approved'
            break
          case 'new':
            color = 'yellow'
            text = 'New'
            break
          case 'shipped':
            color = 'green'
            text = 'Shipped'
            break
        }
        return <BadgeText value={text} color={color} />
      },
    },
    {
      key: 'item',
      name: 'ITEM',
      className: 'min-w-[200px]',
      custom: (value: any, item: any) => {
        return (
          <div
            className='text-ellipsis hover:text-blue'
            onClick={() =>
              navigate(`/accounts/detail/${item?.account_id}?from=logistics`)
            }
          >
            {value}
          </div>
        )
      },
    },
    {
      key: 'brand_name',
      name: 'BRAND',
      className: 'min-w-40',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value}</div>
      },
    },
    {
      key: 'location_name',
      name: 'LOCATION',
      className: 'min-w-40',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value}</div>
      },
    },
    {
      key: 'requested_at',
      name: 'REQUEST DATE',
      className: 'w-32',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'request_date' ? sort : '',
      onSortDirection: (key: string, item: { label: string; value: string }) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'request_date',
          value,
        })
      },
      custom: (value: string) => {
        return <div>{moment(value || '').format('MMM DD, YYYY')}</div>
      },
    },
    {
      key: 'request_by',
      name: 'REQUESTED BY',
      sortable: true,
      sortDirection: sort_by === 'requested_by' ? sort : '',
      onSortDirection: (key: string, item: { label: string; value: string }) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'requested_by',
          value,
        })
      },
      custom: (value: any) => {
        if (!value) {
          return <div>-</div>
        }
        const profile_photo = value?.profile_photo || ''
        const first_name = value?.first_name || ''
        const last_name = value?.last_name || ''
        return (
          <div className='flex items-center'>
            <PersonName
              className='w-full'
              avatar={profile_photo}
              name={first_name || last_name ? `${first_name} ${last_name}` : '-'}
            />
          </div>
        )
      },
    },
    {
      key: 'approved_by',
      name: 'APPROVED BY',
      sortable: true,
      sortDirection: sort_by === 'approved_by' ? sort : '',
      onSortDirection: (key: string, item: { label: string; value: string }) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'approved_by',
          value,
        })
      },
      custom: (value: any) => {
        const profile_photo = value?.profile_photo || ''
        const first_name = value?.first_name || ''
        const last_name = value?.last_name || ''
        return (
          <div className='flex items-center'>
            <PersonName
              className='w-full'
              avatar={profile_photo}
              name={first_name || last_name ? `${first_name} ${last_name}` : '-'}
            />
          </div>
        )
      },
    },
    {
      key: 'shipped_by',
      name: 'PROCESSED BY',
      sortable: true,
      sortDirection: sort_by === 'shipped_by' ? sort : '',
      onSortDirection: (key: string, item: { label: string; value: string }) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'shipped_by',
          value,
        })
      },
      custom: (value: any) => {
        const profile_photo = value?.profile_photo || ''
        const first_name = value?.first_name || '-'
        const last_name = value?.last_name || ''
        return (
          <div className='flex items-center'>
            <PersonName
              className='w-full'
              avatar={profile_photo}
              name={first_name || last_name ? `${first_name} ${last_name}` : '-'}
            />
          </div>
        )
      },
    },
    {
      key: 'stage',
      name: 'STAGE',
      className: 'w-28',
      tdNoHidden: true,
      sortable: true,
      align: 'right',
      sortDirection: sort_by === 'stage' ? sort : '',
      onSortDirection: (key: string, item: { label: string; value: string }) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'stage',
          value,
        })
      },
      custom: (value: any, item: any, index: number) => {
        return (
          <StageProgress
            className='cursor-pointer'
            popTop={index >= 8}
            onboarding={item?.onboarding || {}}
            signupForm={item?.signup_form || {}}
            statusName={item.stage}
          />
        )
      },
    },
  ]

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={page}
        resizePageSize={pageSize}
        onPageNoChange={value => handleFormChange?.('page', value)}
        clickedRow={clickedRow}
      />
    </div>
  )
}
