/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { Element, scroller } from 'react-scroll'
import cn from 'classnames'
import currency from 'currency.js'
import moment from 'moment'
import { toast } from 'react-toastify'
import {
  SolidRecepit,
  AngleRight,
  SolidUtensils,
  Loading,
} from '@rushable/icons'
import SingleInput from 'components/SingleInput'
import { Select } from 'components/Select'
import Button from 'components/Button'
import useDebounce from 'hooks/useDebounce'
import MapComps, { Tpoint } from './components/Map'

import { getMapBrandsApi } from 'redux/map'

interface Tmappage {
  territory: Record<string, any>
  territoryList: any[]
}

export default function MapPage({ territory, territoryList }: Tmappage) {
  const [keyword, setKeyword] = useState('')
  const [top10Avg, setTop10Avg] = useState('')
  const [options, setOptions] = useState<any[]>([])
  const [curTerritory, setCurTerritory] = useState<any>(territory)
  const [restaurants, setRestaurants] = useState<any[]>([])
  const [curPoint, setCurPoint] = useState<Tpoint | null>(null)
  const [points, setPoints] = useState<Tpoint[]>([])
  const [loading, setLoading] = useState(false)
  // 储存原始数据
  const restaurantRef = useRef<any>([])

  // 过滤出 brand
  const filterRes = useDebounce(key => {
    const filter = restaurantRef.current.filter((item: any) => {
      const name = item.name?.toLowerCase()
      const cuisine = item.cuisine?.toLowerCase()
      const sKey = key?.toLowerCase()
      if (name?.includes(sKey) || cuisine?.includes(sKey)) {
        return true
      }
      return false
    })
    setRestaurants(filter)
    renderMappin(filter)
  }, 500)

  // 初始化地区列表
  useEffect(() => {
    const list = territoryList.map((item: any) => ({
      label: item.name,
      value: item.name,
      ...item,
    }))
    const option = { label: 'Custom Territory', value: 'Custom Territory' }
    setOptions([option, ...list])
  }, [territoryList.length])

  useEffect(() => {
    getMapBrands()
  }, [curTerritory])

  const getMapBrands = async () => {
    try {
      const res = await getMapBrandsApi({
        address_query: curTerritory.name,
        latitude: curTerritory.latitude,
        longitude: curTerritory.longitude,
      })
      initMapData(res)
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  const initMapData = (res: any) => {
    // 餐厅列表格式化
    const list = res.list?.map((item: any) => {
      const done_at = item?.onboarding?.done_at
      const diffDay = done_at ? moment().diff(done_at, 'days', true) : 0
      const order_amount_text = item.order_amount
        ? currency(item.order_amount, { precision: 0 }).format()
        : '-'
      const cuisine = item.location?.brand?.brand_cuisines?.map(
        (v: any) => v?.cuisine_type?.name,
      )
      return {
        brandId: item.id,
        location_id: item.location_id,
        order_amount: item.order_amount,
        order_amount_text,
        order_count: item.order_count || 0,
        diffDay: diffDay < 30 ? Math.ceil(diffDay) : 0,
        address: item?.location?.address || {},
        name: item?.location?.brand?.name || '',
        cuisine: cuisine?.join(', '),
        hostname: item?.location?.brand?.domains?.[0]?.hostname || '',
        onboarding: item?.onboarding?.status !== 'done' || false,
      }
    })
    restaurantRef.current = list
    setRestaurants(list)
    renderMappin(list)
    // 后端已排好序
    const top10 = list.reduce((acc: string, cur: any, index: number) => {
      return index > 9 ? acc : currency(acc).add(cur.order_amount || 0).value
    }, 0)
    setTop10Avg(currency(top10).divide(10).format())

    if (res.select?.latitude && res.select?.longitude) {
      setCurPoint({
        latitude: res.select.latitude,
        longitude: res.select.longitude,
        city: res.select.city,
      })
    }
  }

  // 点击左侧brand列表，右侧地图变化中心点、高亮点
  const handleClickRestaurant = (item: any, index: number) => {
    renderActiveRestaurant(index)
    const centerPoint = {
      latitude: item.address.latitude,
      longitude: item.address.longitude,
      city: item.address.city,
    }
    setCurPoint(centerPoint)
    renderActiveMappin(index)
  }

  // 图钉高亮
  const renderActiveMappin = (index: number) => {
    points.forEach(v => (v.checked = false))
    points[index].checked = true
    setPoints([...points])
  }

  // restaurants 高亮
  const renderActiveRestaurant = (index: number) => {
    restaurants.forEach(item => (item.isActive = false))
    restaurants[index].isActive = true
    setRestaurants([...restaurants])
  }

  // 点击图标指定brand滚动到顶部
  const handleClickPin = (item: Tpoint, index: number) => {
    renderActiveRestaurant(index)
    renderActiveMappin(index)
    scroller.scrollTo(`element-${item.brandId}`, {
      duration: 500,
      delay: 0,
      smooth: true,
      containerId: 'ContainerElementID',
      offset: 0, // Scrolls to element + 50 pixels down the page
    })
  }

  const renderMappin = (list: any[]) => {
    const pointsData = list.map(item => ({
      brandId: item.brandId,
      latitude: item.address?.latitude,
      longitude: item.address?.longitude,
      city: item.address?.city,
      onboarding: item?.onboarding, // true 绿色 false 蓝色
    }))
    setPoints(pointsData)
  }

  return (
    <div className='flex flex-col' style={{ height: 'calc(100vh - 78px)' }}>
      <div className='flex items-center px-10 py-8'>
        <SingleInput
          placeholder='Keyword: brand or cuisine'
          name='brand_cuisines'
          className='w-60	mr-4'
          value={keyword}
          onChange={e => {
            const value = e.target.value
            setKeyword(value)
            filterRes(value)
          }}
        />
        <Select
          className='w-60'
          placeholder='Select...'
          value={curTerritory.name}
          options={options}
          onChange={(value, item) => {
            setCurTerritory(item)
            setLoading(true)
          }}
        />
        {loading && (
          <div className='ml-4 flex items-center'>
            <Loading className='mr-1' />
            Loading...
          </div>
        )}
        <div className='flex-1 text-right text-light-300'>
          {top10Avg} average monthly revenue from the top{' '}
          {restaurants.length > 10 ? 10 : restaurants.length}{' '}
          {`restaurant${restaurants.length > 1 ? 's' : ''}`} near this area
        </div>
      </div>
      <div className='flex-1 flex overflow-hidden	'>
        <div
          className='w-400 overflow-y-auto border-t border-dark-100'
          id='ContainerElementID'
        >
          {restaurants.map((item, index: number) => {
            return (
              <Element key={item.brandId} name={`element-${item.brandId}`}>
                <div
                  className={cn(
                    'pl-10 py-6 border-b border-dark-100 cursor-pointer',
                    { 'bg-dark-500': item.isActive },
                  )}
                  onClick={() => handleClickRestaurant(item, index)}
                >
                  <div className='flex mb-1'>
                    <h3 className=' text-light-700 text-base'>{item.name}</h3>
                    {!!item.diffDay && (
                      <div className='mx-2 flex items-center rounded text-xs px-2 bg-dark-100 text-light-700 whitespace-nowrap h-21'>
                        {item.diffDay} Days
                      </div>
                    )}
                    {item.onboarding && (
                      <div className='mx-2 flex items-center rounded text-xs px-2  bg-blue-16 text-blue h-21'>
                        Onboarding
                      </div>
                    )}
                  </div>
                  <div className='flex'>
                    <div className='flex items-center min-w-20 mr-2'>
                      <SolidRecepit className='text-light-100' size={16} />
                      <div className='ml-1 text-sm'>
                        {item.order_amount_text}
                      </div>
                    </div>
                    <div className='flex items-center'>
                      <SolidUtensils className='text-light-100' size={16} />
                      <div className='ml-1 text-sm'>{item.cuisine || '-'}</div>
                    </div>
                  </div>
                  {item.isActive && (
                    <div className='mt-2 flex item-center text-sm text-light-700'>
                      <Button
                        className='ml-1'
                        theme='text'
                        color='secondary'
                        as='a'
                        href={item.hostname}
                        target='_blank'
                      >
                        VISIT WEBSITE
                      </Button>
                      <AngleRight size={12} />
                    </div>
                  )}
                </div>
              </Element>
            )
          })}
        </div>
        <div className='relative flex-1'>
          <div className='absolute z-50 inset-x-40  mt-2 flex justify-center'>
            <div className='shadow-black	border border-dark-100 px-4 py-2 rounded-lg bg-dark-500 text-light-700'>
              {points.length} {`restaurant${restaurants.length > 1 ? 's' : ''}`}{' '}
              near {curPoint?.city}
            </div>
          </div>
          <MapComps
            center={curPoint!}
            points={points}
            onChange={(item, index) => handleClickPin(item, index)}
            onSearch={point => {
              setCurTerritory(point)
              setCurTerritory({ ...point, name: 'Custom Territory' })
            }}
          />
        </div>
      </div>
    </div>
  )
}
