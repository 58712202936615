import { useState } from 'react'
import { AngleDown } from '@rushable/icons'
import { NavLink, useLocation } from 'react-router-dom'

import useRoutePermission from 'hooks/useRoutePermissions'
import { SUPPORTS, SALES } from 'routes/baseRoute'

const HOVERCONFIG = {
  Sales: SALES,
  Support: SUPPORTS,
}

type TnavHover = {
  navName: 'Support' | 'Sales'
}

export default function NavHover({ navName }: TnavHover) {
  const { pathname } = useLocation()
  const [show, setShow] = useState(false)
  const menus = useRoutePermission(HOVERCONFIG[navName])
  const menuName = menus?.find(v => v.path === pathname)?.name || ''
  return (
    <div
      className='relative mr-6'
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      <div className='cursor-pointer'>
        <span>{navName}</span>
        {!!menuName && <span className='ml-1 text-light-700'>({menuName})</span>}
        <AngleDown size={8} className='text-light-300 ml-1' />
      </div>
      <ul
        className='w-40 px-4 py-3 absolute bg-dark-500 border border-dark-100 rounded-lg'
        style={{
          boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.24)',
          display: show ? 'block' : 'none',
        }}
      >
        {menus.map(item => {
          return (
            <li key={item.path} className='py-1'>
              <NavLink
                to={item.path}
                className={({ isActive }) => (isActive ? 'text-light-700' : '')}
              >
                {item.name}
              </NavLink>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
