import cn from 'classnames'
import { SolidPhone } from '@rushable/icons'

import SingleInput from 'components/SingleInput'

type TPhoneFilterProps = {
  value: string
  onChange: (item: any, rest: any) => void
}

export default function PhoneFilter({ value, onChange }: TPhoneFilterProps) {
  return (
    <div className={cn('ru-ConditionsItemForm flex justify-between')}>
      <div className='flex items-center'>
        <SolidPhone size={16} className='text-light-100' />
        <span className='ml-2 mr-6 w-40'>Phone number</span>
      </div>
      <div className='flex-1'>
        <SingleInput
          value={value}
          name='Phone number'
          type='tel'
          placeholder='Input'
          onChangePhone={value => onChange(value, value)}
        />
      </div>
    </div>
  )
}
