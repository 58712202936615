import axiosInstance from 'utils/axios'

// Ownership Transfer 相关

// Ownership Transfer Initiate
export async function initiateTransferApi(params: Record<string, any>, id?: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/ownership_transfer/initiate`,
    method: 'post',
    data: params,
  })
  return response.data
}

// Ownership Transfer Update
export async function updateTransferApi(params: Record<string, any>, id?: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/ownership_transfer/update`,
    method: 'post',
    data: params,
  })
  return response.data
}

// Ownership Transfer Cancel
export async function cancelTransferApi(id: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/ownership_transfer/cancel`,
    method: 'post',
  })
  return response.data
}

// Get Ownership Transfer list
export async function getTransferListApi(params: Record<string, any>) {
  const response = await axiosInstance.request({
    url: 'ownership_transfers',
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
