import React from 'react'
import cn from 'classnames'
import { SolidSmsText } from '@rushable/icons'
import DateAndTime from 'components/DateAndTime'
import IconButton from 'components/IconButton'

export type TSmsItemProps = {
  source: 'incoming' | 'outgoing'
  name: string
  updateAt: string
  msg: string
}

export default function SmsItem({
  source,
  name,
  updateAt,
  msg,
}: TSmsItemProps): JSX.Element {
  return (
    <div
      className={cn(
        'flex-1 px-4 py-2 border rounded-lg',
        source === 'outgoing'
          ? 'border-dark-500 bg-dark-500'
          : 'border-blue-16 bg-blue-16',
      )}
    >
      <div className='flex justify-between mb-2'>
        <div className='flex flex-1 items-center'>
          <IconButton
            className='mr-1'
            icon={<SolidSmsText size={16} className='text-light-500' />}
            size='small'
            hasHover={false}
          />
          <span className='text-xs text-light-500 pt-0.5'>{name}</span>
        </div>
        <DateAndTime
          className='h-5 text-light-100 text-xs px-0'
          value={updateAt}
          format='YYYY MMM DD @ hh:mm A'
        />
      </div>
      <div className='text-xs text-light-500 text-ellipsis-2'>{msg}</div>
    </div>
  )
}
