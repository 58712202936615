import React, { useState } from 'react'
import cn from 'classnames'
import moment from 'moment'
import { currentStepMap } from './helpers/constant'
import AccountStage from 'components/AccountStage'
import StageProgressTooltip from 'components/StageProgressTooltip'
import { RUSHABLE_MONTHLY_PLAN_ID, RUSHABLE_ANNUAL_PLAN_ID } from 'utils/constant'
import type { TStatus } from 'components/AccountStage'
import type { TOnboarding, TSignUpForm, TStageName } from 'types'

export type TStatusProcessProps = {
  className?: string
  statusName?: TStageName // 优先传入 statusName，若不传入则走内部判断逻辑
  onboarding: TOnboarding
  signupForm: TSignUpForm
  popTop?: boolean
  popLeft?: boolean
  isOwnershipTransfer?: number
}

export default function StageProgress({
  className,
  statusName,
  onboarding,
  signupForm,
  popTop = true,
  popLeft = true,
  isOwnershipTransfer,
}: TStatusProcessProps): JSX.Element | null {
  const [open, setOpen] = useState(false)
  if (!onboarding && !statusName) {
    return null
  }
  const current_step = signupForm ? signupForm.current_step ?? '' : ''

  let signUpArr = [
    {
      checked: currentStepMap[current_step] > 0,
      text: 'User account creation',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 1,
      text: 'Rushable features',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 2,
      text: 'Payment processing',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 3,
      text: 'Legal protection',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 4,
      text: 'Review store information',
      checkedColor: '',
      borderTop: true,
    },
    {
      checked: currentStepMap[current_step] > 5,
      text: 'Verify legal entity',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 6,
      text: 'Add deposit account',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 7,
      text: 'Review subscription',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 8,
      text: 'Add billing account',
      checkedColor: '',
    },
  ]

  signUpArr = isOwnershipTransfer
    ? signUpArr.concat([
        {
          checked: currentStepMap[current_step] > 10,
          text: 'Review existing users',
          checkedColor: '',
        },
        {
          checked: currentStepMap[current_step] > 11,
          text: 'About us content submission',
          checkedColor: '',
          borderTop: true,
        },
      ])
    : signUpArr.concat([
        {
          checked: currentStepMap[current_step] > 11,
          text: 'About us content submission',
          checkedColor: '',
          borderTop: true,
        },
      ])

  const onboardArr = onboarding?.onboarding_tasks?.length
    ? onboarding?.onboarding_tasks.map(tasks => {
        return {
          checked: !!tasks.completed_at,
          text: tasks.name,
          checkedColor: '',
        }
      })
    : []

  const nowStatusAndStep = () => {
    const status = onboarding?.status
    if (!status) {
      return {
        status: '',
        step: 0,
      }
    }
    const { location, rushable_plan_id } = signupForm
    const onboarding_done_at = onboarding?.done_at
    if (location && onboarding_done_at) {
      const { stripe_subscription_ends_at = '' } = location
      if (!stripe_subscription_ends_at) {
        return {
          status: 'live',
          step: 100,
        }
      }
      if (moment().isBefore(stripe_subscription_ends_at)) {
        let count = 0
        // 月付
        if (rushable_plan_id) {
          if (rushable_plan_id === Number(RUSHABLE_MONTHLY_PLAN_ID)) {
            const days = moment(stripe_subscription_ends_at).diff(moment(), 'days') + 1
            count = parseInt((((31 - days) * 100) / 31).toString())
          }
          // 年付
          if (rushable_plan_id === Number(RUSHABLE_ANNUAL_PLAN_ID)) {
            const days = moment(stripe_subscription_ends_at).diff(moment(), 'days') + 1
            count = parseInt((((366 - days) * 100) / 366).toString())
          }
          return {
            status: 'cancelling',
            step: count,
          }
        } else {
          const days = moment(stripe_subscription_ends_at).diff(moment(), 'days') + 1
          count = parseInt((((31 - days) * 100) / 31).toString())
          return {
            status: 'cancelling',
            step: count,
          }
        }
      }
      if (moment().isAfter(stripe_subscription_ends_at)) {
        return {
          status: 'canceled',
          step: 100,
        }
      }
    }
    if (status === 'draft') {
      return {
        status: 'draft',
        step: 100,
      }
    }
    if (status === 'signup_ready') {
      let count = 0
      signUpArr.forEach(item => {
        if (item.checked) {
          count += 1
        }
      })
      if (count === 0) {
        return {
          status: 'ready',
          step: 0,
        }
      } else {
        return {
          status: 'signing_up',
          step: (count * 100) / 10,
        }
      }
    }
    if (status === 'signup_done') {
      if (onboardArr.length === 0) {
        return {
          status: 'onboarding',
          step: 0,
        }
      } else {
        let count = 0
        onboardArr.forEach(item => {
          if (item.checked) {
            count += 1
          }
        })
        return {
          status: 'onboarding',
          step: (count * 100) / onboardArr.length,
        }
      }
    }
    if (status === 'canceled') {
      return {
        status: 'canceled',
        step: 100,
      }
    }
    return {
      status: 'draft',
      step: 100,
    }
  }
  const { status: nowStatus, step } = nowStatusAndStep()
  return (
    <div
      className={cn('relative w-20', className)}
      onMouseOver={() => {
        if (statusName !== 'Live' && nowStatus) {
          setOpen(true)
        }
      }}
      onMouseOut={() => setOpen(false)}
    >
      <AccountStage status={nowStatus as TStatus} step={step} statusName={statusName} />
      <StageProgressTooltip
        className={cn(
          'absolute z-50',
          popTop ? 'bottom-0' : 'top-0',
          popLeft ? 'right-20' : 'left-20',
        )}
        open={open}
        status={nowStatus as TStatus}
        signUpArr={signUpArr}
        onboardArr={onboardArr}
      />
    </div>
  )
}
