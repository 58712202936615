import { useState, useEffect } from 'react'
import { SolidTrashCan } from '@rushable/icons'
import { toast } from 'react-toastify'
import Modal from 'components/Modal'
import Button from 'components/Button'
import SingleInput from 'components/SingleInput'
import { createTaskApi, updateTaskApi, deleteTaskApi } from 'redux/account'

export type TTaskModal = {
  onboardingId: string
  task?: Record<string, any>
  tab: 'Onboarding' | 'Success'
  open: boolean
  toggle: () => void
  onSuccess: () => void
}

export default function EditTaskModal({
  onboardingId,
  task,
  tab,
  open,
  toggle,
  onSuccess,
}: TTaskModal) {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState({ ok: false, delete: false })

  useEffect(() => {
    if (open) {
      setValue(task?.name || '')
    }
  }, [open])

  const handleSubmit = async () => {
    setLoading(prev => ({ ...prev, ok: true }))
    const params = {
      description: value,
      completed: task?.id ? true : undefined,
      type: tab === 'Onboarding' ? 'onboarding' : 'success',
    }
    try {
      let res = null
      if (task?.id) {
        res = await updateTaskApi(onboardingId, task.id, params)
      } else {
        res = await createTaskApi(onboardingId, params)
      }
      toast.success(res.message)
      onSuccess()
      toggle()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, ok: false }))
  }

  const handleDelete = async (id: string) => {
    setLoading(prev => ({ ...prev, delete: true }))
    try {
      const res = await deleteTaskApi(onboardingId, id)
      toast.success(res.message)
      onSuccess()
      toggle()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, delete: false }))
  }

  return (
    <Modal
      open={open}
      toggle={toggle}
      title={`${task?.id ? 'EDIT' : 'CREATE'} TASK`}
      okText={`${task?.id ? 'UPDATE' : 'CONFIRM'}`}
      cancelText='CLOSE'
      onOk={handleSubmit}
      okButtonProps={{ loading: loading.ok, disabled: loading.delete }}
      footerLeftElement={
        <>
          {task?.id && (
            <Button
              color='warning'
              loading={loading.delete}
              disabled={loading.ok}
              iconBefore={<SolidTrashCan size={12} />}
              theme='text'
              onClick={() => handleDelete(task?.id)}
            >
              DELETE
            </Button>
          )}
        </>
      }
    >
      <div className='w-full text-left'>
        <SingleInput
          name='DESCRIPTION'
          label='DESCRIPTION'
          placeholder='Input'
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </div>
    </Modal>
  )
}
