import { ReactElement, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { AngleRight, Loading, SolidTrashCan } from '@rushable/icons'
import Button from 'components/Button'
import Table, { TOnSort } from 'components/Table'
import DateAndTime from 'components/DateAndTime'
import BadgeText from 'components/BadgeText'

import { deleteOtherEffort, getEffortPoints, TDetailParams } from 'redux/sales/action'
import { toast } from 'react-toastify'
import EffortPointsCalSideBar from './EffortPointsCalSideBar'

import { RUSHABLE_TIMEZONE } from 'data/constant'

type TSortData = {
  sort_by: string
  sort: string
}

type TProps = {
  renderSide: (el: ReactElement) => void
}

type TDelLoading = {
  show: boolean
  id: null | number
}

export default function EffortPointsCalTable(props: TProps) {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const option = searchParams.get('option')
  const [timeOption, setTimeOption] = useState({ start: '', end: '' })
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(40)
  const [total, setTotal] = useState(0)
  const [sortData, setSortData] = useState<TSortData>({ sort_by: '', sort: '' })
  const [loading, setLoading] = useState(false)

  const [delLoading, setDelLoading] = useState<TDelLoading>({
    show: false,
    id: null,
  })

  useEffect(() => {
    getEffortPointsData()
  }, [sortData, page, timeOption])

  const getEffortPointsData = async () => {
    try {
      setLoading(true)
      const params: TDetailParams = {
        id: id!,
        sort_by: sortData.sort_by,
        sort: sortData.sort,
        page,
      }

      if (timeOption.start && timeOption.end) {
        params.begin_time = timeOption.start
        params.end_time = timeOption.end
      } else {
        params.month = option!
      }
      const res = await getEffortPoints(params)
      setTotal(res?.detail?.total || 0)
      setPageSize(res?.detail?.per_page || 0)
      setTableData(res?.detail?.data || [])
      getSideBarEl(res)
    } catch (err: any) {
    } finally {
      setLoading(false)
    }
  }

  const getSideBarEl = (res: any) => {
    const el = (
      <EffortPointsCalSideBar
        total={res.point_total || 0}
        count={res.count || []}
        refreshList={(start, end) => {
          if (start && end) {
            setTimeOption({ start, end })
          } else {
            getEffortPointsData()
          }
        }}
      />
    )
    props.renderSide(el)
  }

  const handleDeleteItem = async (itemId: number) => {
    try {
      setDelLoading({ show: true, id: itemId })
      const res = await deleteOtherEffort(itemId)
      toast.success(res.message || 'delete success.')
      getEffortPointsData()
      setDelLoading({ show: false, id: null })
    } catch (err: any) {
      setDelLoading({ show: false, id: null })
      toast.error(err.message || 'delete error.')
    }
  }

  const handleSort: TOnSort = (key, item) => {
    const { value } = item
    setSortData({ sort_by: item.value ? key : '', sort: value })
  }

  const getColumns = () => {
    const { sort_by, sort } = sortData
    return [
      {
        key: 'activity',
        name: 'ACTIVITY',
        sortable: true,
        sortDirection: sort_by === 'activity' ? sort : '',
        onSortDirection: handleSort,
        custom: (value: any, item: any) => item.activity_name || '',
      },
      {
        key: 'value',
        name: 'EFFORT',
        className: 'w-104',
        custom: (value: any) => <BadgeText value={`+ ${value} pt`} />,
      },
      {
        key: 'asso_contact',
        name: 'ASSO. CONTACT',
        sortable: true,
        sortDirection: sort_by === 'asso_contact' ? sort : '',
        onSortDirection: handleSort,
        custom: (value: any, row: any) => {
          return (
            <div className={`${row.type === 'other' && 'text-yellow'}`}>
              {value ?? '-'}
            </div>
          )
        },
      },
      {
        key: 'asso_company',
        name: 'ASSO. COMPANY',
        sortable: true,
        sortDirection: sort_by === 'asso_company' ? sort : '',
        onSortDirection: handleSort,
        custom: (value: any, row: any) => {
          return (
            <div className={`${row.type === 'other' && 'text-yellow'}`}>
              {value ?? '-'}
            </div>
          )
        },
      },
      {
        key: 'done_at',
        name: 'DATE & TIME',
        sortable: true,
        sortDirection: sort_by === 'done_at' ? sort : '',
        onSortDirection: handleSort,
        align: 'right',
        className: 'w-48',
        custom: (value: any) => {
          return (
            <div className='flex justify-end'>
              <DateAndTime
                className='text-light-500'
                parse='YYYY-MM-DD HH:mm:ss'
                format='MMM DD @ hh:mm A'
                timezone={RUSHABLE_TIMEZONE}
                noTransformTz
                noExpired
                value={value}
              />
            </div>
          )
        },
      },
      {
        key: 'type',
        name: 'VIEW DETAIL',
        align: 'right',
        className: 'w-120',
        custom: (value: string, row: any) => {
          const isLoading = delLoading.show && delLoading.id === row.id
          if (value === 'other') {
            return (
              <div className='flex justify-end'>
                <Button
                  size='small'
                  theme='text'
                  disabled={isLoading}
                  onClick={() => handleDeleteItem(row.id)}
                >
                  <div className={'text-red flex space-x-1 items-center'}>
                    <span>Remove</span>
                    {isLoading ? <Loading size={12} /> : <SolidTrashCan size={12} />}
                  </div>
                </Button>
              </div>
            )
          }
          return (
            <div className='flex justify-end'>
              <Button
                size='small'
                theme='text'
                href={row?.source_link || ''}
                target='_blank'
              >
                <div className={'text-blue flex space-x-1 items-center'}>
                  <span>
                    {value === 'demo' || value === 'deal' ? 'Deal' : 'Engagement'}
                  </span>
                  <AngleRight size={12} />
                </div>
              </Button>
            </div>
          )
        },
      },
    ]
  }

  return (
    <div className='mt-8'>
      <Table
        loading={loading}
        current={page}
        columns={getColumns()}
        data={tableData}
        total={total}
        resizePageSize={pageSize}
        onPageNoChange={value => setPage(value)}
      />
    </div>
  )
}
