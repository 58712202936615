import { useEffect, useState } from 'react'
import {
  SolidUserCircled,
  SolidPhone,
  SolidEmail,
  SolidMapPin,
} from '@rushable/icons'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import Draw from 'components/Drawer'
import SingleInput from 'components/SingleInput'
import Modal from 'components/Modal'
import BadgeText from 'components/BadgeText'
import { UploadCrop } from 'components/Upload'
import { useAppDispatch } from 'redux/hooks'
import { formatPhoneNumber } from 'utils/digit'
import { removeAccessToken } from 'utils/auth'

import {
  getProfile,
  logOutApi,
  updateProfileApi,
  Teditprofile,
} from 'redux/auth'

export type TAdminPageTitleProp = {
  profile: Tprofile | null
  avatar: string | null
  show?: boolean
  toggle: () => void
}

const INITPWDINFO = {
  oldPwd: '',
  newPwd: '',
  repeatPwd: '',
}

export default function Profile({
  profile,
  avatar,
  show = false,
  toggle,
}: TAdminPageTitleProp): JSX.Element {
  const dispatch = useAppDispatch()
  const [callLink, setCallLink] = useState('')
  const [showpwdModal, setShowpwdModal] = useState(false)
  const [loading, setLoading] = useState({
    logout: false,
    confirm: false,
    pwdConfirm: false,
  })
  const [pwdInfo, setPwdInfo] = useState(INITPWDINFO)

  useEffect(() => {
    if (show) {
      setCallLink(profile?.crm_user?.call_link || '')
    }
  }, [show])

  const handleUpdateProfile = async (params: Teditprofile) => {
    // 修改密码确认
    const confirmType = !!params.new_password ? 'pwdConfirm' : 'confirm'
    setLoading(prev => ({ ...prev, [confirmType]: true }))
    try {
      const res = await updateProfileApi(params)
      toast.success(res.message)
      dispatch(getProfile())
      setShowpwdModal(false)
      if (confirmType === 'confirm') {
        toggle()
      }
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, [confirmType]: false }))
  }

  const handleLogout = async () => {
    setLoading(prev => ({ ...prev, logout: true }))
    try {
      const res = await logOutApi()
      toast.success(res.message)
      removeAccessToken()
      window.location.replace('/login')
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, logout: false }))
  }

  const { first_name, last_name, phone, email, crm_user } = profile || {}
  const userName = `${first_name || ''} ${last_name || ''}`
  const userPosition = crm_user?.crm_user_positions || []
  const address = crm_user?.crm_sales_territory || {}
  return (
    <>
      <Draw
        title='YOUR PROFILE'
        cancelText='CLOSE'
        open={show}
        toggle={toggle}
        okButtonProps={{ loading: loading.confirm }}
        onOk={() => handleUpdateProfile({ call_link: callLink })}
        footerLeftElement={
          <Button
            theme='text'
            color='warning'
            loading={loading.logout}
            onClick={handleLogout}
          >
            LOG OUT
          </Button>
        }
      >
        {avatar ? (
          <div className='inline-flex rounded-full overflow-hidden'>
            <img width={64} src={avatar} alt='' />
          </div>
        ) : (
          <SolidUserCircled size={64} />
        )}

        <div className='my-2'>
          <div className='text-2xl text-light-700 font-bold'>{userName}</div>
          <div className='text-light-300'>
            {profile?.crm_user?.crm_role?.display_name || '-'}
          </div>
        </div>
        <>
          {!!userPosition.length && (
            <div className='flex flex-wrap gap-2 mb-8'>
              {userPosition.map((item, index) => (
                <BadgeText key={index} value={item.name} />
              ))}
            </div>
          )}
        </>
        <div>
          <div className='flex items-center'>
            <SolidPhone size={16} />
            <span className='ml-2'>{formatPhoneNumber(phone)}</span>
          </div>
          <div className='my-2 flex items-center'>
            <SolidEmail size={16} />
            <span className='ml-2'>{email}</span>
          </div>
          <div className='flex items-center'>
            <SolidMapPin size={16} />
            <span className='ml-2'>
              {address.name ? `${address.name}` : '-'}
              {address.state ? `, ${address.state}` : ''}
              {address.country ? `, ${address.country}` : ''}
            </span>
          </div>
        </div>
        <SingleInput
          className='my-8'
          value={callLink}
          name='SCHEDULE_A_CALL_LINK_OPTIONAL'
          label='SCHEDULE A CALL LINK (OPTIONAL)'
          placeholder='Input'
          onChange={(e: any) => setCallLink(e.target.value)}
        />
        <div className='flex'>
          <div className='flex-1'>
            <Button
              className='w-full'
              theme='outlined'
              color='secondary'
              onClick={() => {
                setPwdInfo(INITPWDINFO)
                setShowpwdModal(true)
              }}
            >
              RESET PASSWORD
            </Button>
          </div>
          <UploadCrop
            className='flex-1 ml-4'
            title='UPLOAD AVATAR'
            onUploadApi={blob =>
              updateProfileApi({ photo: blob })
                .then(res => {
                  toast.success(res.message)
                  dispatch(getProfile())
                  return Promise.resolve(res)
                })
                .catch(err => {
                  toast.error(err.message)
                  return Promise.reject(err)
                })
            }
          >
            <Button className='w-full' theme='outlined' color='secondary'>
              UPLOAD AVATAR
            </Button>
          </UploadCrop>
        </div>
      </Draw>
      <Modal
        title='RESET PASSWORD'
        open={showpwdModal}
        toggle={() => setShowpwdModal(false)}
        okButtonProps={{
          loading: loading.pwdConfirm,
          disabled: !pwdInfo.oldPwd || !pwdInfo.newPwd || !pwdInfo.repeatPwd,
        }}
        onOk={() => {
          handleUpdateProfile({
            old_password: pwdInfo.oldPwd,
            new_password: pwdInfo.newPwd,
            new_password_confirmation: pwdInfo.repeatPwd,
          })
        }}
      >
        <SingleInput
          value={pwdInfo.oldPwd}
          name='OLD_PASSWORD'
          label='OLD PASSWORD'
          placeholder='Input'
          autoComplete='off'
          inputClassName='input-password'
          onChange={(e: any) =>
            setPwdInfo(prev => ({ ...prev, oldPwd: e.target.value }))
          }
        />
        <SingleInput
          className='py-6'
          value={pwdInfo.newPwd}
          name='NEW_PASSWORD'
          label='NEW PASSWORD'
          placeholder='Input'
          inputClassName='input-password'
          onChange={(e: any) =>
            setPwdInfo(prev => ({ ...prev, newPwd: e.target.value }))
          }
        />
        <SingleInput
          value={pwdInfo.repeatPwd}
          name='CONFIRM_NEW_PASSWORD'
          label='CONFIRM NEW PASSWORD'
          placeholder='Input'
          inputClassName='input-password'
          onChange={(e: any) =>
            setPwdInfo(prev => ({ ...prev, repeatPwd: e.target.value }))
          }
        />
      </Modal>
    </>
  )
}
