import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'

import { AdminLayout, AdminPageTitle } from 'components/Layout'
import AccountSideBar from '../../components/AccountSideBar'
import SignupForm from './SignupForm'

import { useAppSelector } from 'redux/hooks'
import { getSignupInfoApi } from 'redux/account'

import SignupFormContext from './context'
import { TSignupStatus } from '../../helpers'

type Iprops = {
  type: 'tabs' | 'form' // tabs: signsup 阶段已完成，form: 正在进行 signsup 阶段
  signupId?: string
  accountDetail?: any
}

export default function TabSignup({ type, signupId, accountDetail }: Iprops) {
  const profile = useAppSelector(s => s.auth.profile)
  const params = useParams()
  const [status, setStatus] = useState<TSignupStatus>()
  const [formDisabled, setFormDisabled] = useState(params.id !== 'create')
  const [signupForms, setSignupForms] = useState({
    // 当前 signup form
    currentSignupForm: null,
    // 正在 transfer 的 signup form
    transferringSignupForm: null,
    // 已完成的 transfer 的 signup forms
    transferredSignupForms: null,
  })
  // sales ae
  const [closer, setCloser] = useState({ value: '', label: '', img_url: '' })
  useEffect(() => {
    setFormDisabled(params.id !== 'create')
    setStatus(params.id === 'create' ? 'create' : undefined)
  }, [params.id])

  useEffect(() => {
    if (signupId) {
      getSignupInfo(signupId)
    }
  }, [signupId])

  const getSignupInfo = async (signupId: string) => {
    try {
      const res = await getSignupInfoApi(signupId)
      const isApprove = getNeedApprove(res.signup_form?.signup_form_approvals || [])
      const closer = res.onboarding?.crm_closer
      setCloser({
        value: closer?.id || '',
        img_url: closer?.profile_photo || '',
        label: `${closer?.user?.first_name} ${closer?.user?.last_name}`,
      })
      const ownershipTransfers = res.account_entities
      const commissionData = {
        // sales AE/SE 的相关信息从 onboarding 里面取
        ae_commission: res.onboarding.ae_commission,
        se_commission: res.onboarding.se_commission,
        solution_engineer_id: res.onboarding.solution_engineer_id,
      }
      const signupForms: any = {
        currentSignupForm: {
          ...{
            ...res.signup_form,
            ...commissionData,
          },
          transferLocationName: res?.location?.name || '',
          isApprove,
          status: res.status,
        },
        transferringSignupForm: null,
        transferredSignupForms: null,
      }
      if (ownershipTransfers && ownershipTransfers?.length > 0) {
        // 正在进行中的 transferring
        const transferringForm = ownershipTransfers.find(
          (v: any) => v?.status !== 'completed',
        )
        // 已经完成的 transferred
        const transferredForms = ownershipTransfers.filter(
          (v: any) => v?.status === 'completed',
        )
        if (transferringForm) {
          const crm_user = transferringForm?.crm_user
          signupForms.transferringSignupForm = {
            ...{
              ...transferringForm.signup_form,
              ...commissionData,
            },
            transfer: {
              hasOwnershipTransfer: true,
              status: transferringForm.status,
              transferBy: `${crm_user?.user?.first_name || ''}${
                crm_user?.user?.last_name ? ` ${crm_user?.user?.last_name}` : ''
              }`,
              profilePhoto: crm_user?.profile_photo || '',
              timezone: transferringForm.signup_form?.location_address?.timezone || '',
            },
            isApprove,
            status: res.status,
          }
        }
        if (transferredForms && transferredForms.length > 0) {
          const sortTransferredForms = transferredForms
            .sort((a: any, b: any) => {
              // 按时间排序，距今最近的在最后
              return moment(a?.from).diff(moment(b?.from))
            })
            .map((form: any, index: number) => {
              const crm_user = form?.crm_user
              const transfer = {
                hasOwnershipTransfer: false,
                status: form.status,
                transferBy: `${crm_user?.user?.first_name || ''}${
                  crm_user?.user?.last_name ? ` ${crm_user?.user?.last_name}` : ''
                }`,
                profilePhoto: crm_user?.profile_photo || '',
                timezone: form.signup_form?.location_address?.timezone || '',
                fromAt: form.from,
                // index === 0 代表 from 时间最靠前，也就是最初的 signup_from 数据
                numberOfOwner: index,
              }
              return {
                ...{
                  ...form.signup_form,
                  ...commissionData,
                },
                transfer,
                isApprove,
                status: res.status,
              }
            })
          signupForms.transferredSignupForms = sortTransferredForms?.filter(
            // 排除当前生效的 Current transfer
            (v: any) => v?.id !== res?.signup_form_id,
          )
          // 当前生效的 Current transfer
          const transferCurrentForms = sortTransferredForms.find(
            (v: any) => v?.id === res?.signup_form_id,
          )
          if (transferCurrentForms) {
            signupForms.currentSignupForm = {
              ...signupForms.currentSignupForm,
              transfer: transferCurrentForms.transfer,
            }
          }
        }
      }
      setSignupForms(signupForms)
      if (res.status === 'draft') {
        setStatus('draft')
      }
      if (res.status === 'signup') {
        setStatus('signup')
        if (isApprove) {
          setStatus('pending')
        }
      }
      setFormDisabled(true)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  // 是否全部审核完成
  const getNeedApprove = (approves: any[]) => {
    return !approves.every(item => !!item.approved_at && !!item.approved_by)
  }

  const closerInfo = signupId
    ? closer
    : {
        value: profile?.crm_user.id || '',
        label: profile?.userName || '',
        img_url: profile?.userPhoto || '',
      }

  return (
    <SignupFormContext.Provider
      value={{
        status,
        signupForms,
        editId: signupId,
        closer: closerInfo,
        // approve 完成之后刷新接口
        handleInit: () => getSignupInfo(signupId!),
        // 修改 formDisabled
        handleFormDisabled: (newDisabled: boolean) => setFormDisabled(newDisabled),
      }}
    >
      {type === 'form' ? (
        <AdminLayout showNavs={false}>
          <AdminPageTitle title='ACCOUNT DETAIL' />
          <div className='flex'>
            <AccountSideBar type='Signup' data={accountDetail} />
            <div className='flex-1 mt-6 mb-10 px-10'>
              <SignupForm formDisabled={formDisabled} />
            </div>
          </div>
        </AdminLayout>
      ) : (
        // 当 signup 阶段完成时展示该组件
        <SignupForm formDisabled={true} />
      )}
    </SignupFormContext.Provider>
  )
}
