import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { RequireAuth } from 'components/Layout'
import { PAGEROUTES } from './baseRoute'

export default function RouteComponents() {
  return (
    <BrowserRouter>
      <Routes>
        {PAGEROUTES.map(({ component: Component, path, auth }) => {
          const comps = auth ? (
            <RequireAuth>
              <Component />
            </RequireAuth>
          ) : (
            <Component />
          )
          return <Route key={path} path={`${path}/*`} element={comps} />
        })}
        <Route path='/' element={<Navigate to='/accounts' replace />} />
        <Route path='*' element={<h1 className='p-20'>Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  )
}
