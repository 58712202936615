import { useState, useEffect } from 'react'
import { SolidTrashCan } from '@rushable/icons'
import { toast } from 'react-toastify'

import Drawer from 'components/Drawer'
import Checkbox from 'components/Checkbox'
import BadgeText from 'components/BadgeText'
import { Select } from 'components/Select'
import SingleInput from 'components/SingleInput'
import AlertText from 'components/AlertText'
import Button from 'components/Button'

import { useAppSelector } from 'redux/hooks'
import {
  createLogisticApi,
  updateLogisticApi,
  deleteLogisticApi,
  approveLogisticApi,
  syncEasyShipApi,
} from 'redux/account'

import { CHOOSE_ITEM_OPTIONS } from '../helpers/constant'
import { DOMAIN } from 'data/constant'

type TLogisticItemDrawerProps = {
  show: boolean
  title: string
  curItem: Record<string, any>
  onboardingId: string
  toggle: () => void
  onSuccess?: () => void
}

export default function LogisticItemDrawer({
  show,
  title = 'LOGISTIC ITEM',
  curItem,
  onboardingId,
  toggle,
  onSuccess,
}: TLogisticItemDrawerProps) {
  const profile = useAppSelector(state => state.auth.profile)

  const [team, setTeam] = useState('')
  const [easyShip, setEasyShip] = useState('')

  const [easyShipRes, setEasyShipRes] = useState({ state: '', name: '' })
  const [billingChecked, setBillingChecked] = useState(false)
  const [loading, setLoading] = useState({
    submitLoading: false,
    syncLoading: false,
    approveLoading: false,
    deleteLoading: false,
  })

  useEffect(() => {
    if (!show) {
      setTeam('')
      setEasyShip('')
      setEasyShipRes({ state: '', name: '' })
      setBillingChecked(false)
    }
    if (show && curItem.id) {
      setTeam(curItem.item)
      setEasyShip(curItem?.easyship_tracking_id || '')
    }
  }, [show])

  // 待审核
  const isNeedApprove = !!curItem?.id && !curItem.approved_by
  // 已审核
  const isApproved = !!curItem?.id && !!curItem.approved_by

  const handleSubmit = async () => {
    setLoading(prev => ({ ...prev, submitLoading: true }))
    try {
      const res = await createLogisticApi(onboardingId, team)
      toast.success(res.message)
      onSuccess?.()
      toggle()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, submitLoading: false }))
  }

  const handleUpdate = async () => {
    setLoading(prev => ({ ...prev, submitLoading: true }))
    try {
      const res = await updateLogisticApi(onboardingId, curItem.id, easyShip)
      toast.success(res.message)
      onSuccess?.()
      toggle()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, submitLoading: false }))
  }

  const handleSync = async () => {
    setLoading(prev => ({ ...prev, syncLoading: true }))
    try {
      const res = await syncEasyShipApi(easyShip)

      setEasyShipRes({
        state: `${res.pickup_state.replaceAll(
          '_',
          ' ',
        )} / ${res.delivery_state.replaceAll('_', ' ')}`,
        name: res.destination_address.contact_name,
      })
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, syncLoading: false }))
  }

  const handleDelete = async () => {
    setLoading(prev => ({ ...prev, deleteLoading: true }))
    try {
      const res = await deleteLogisticApi(onboardingId, curItem.id)
      onSuccess?.()
      toast.success(res.message)
      toggle()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, deleteLoading: false }))
  }

  const handleApprove = async () => {
    setLoading(prev => ({ ...prev, approveLoading: true }))
    try {
      const res = await approveLogisticApi(curItem.id)
      onSuccess?.()
      toast.success(res.message)
      toggle()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, approveLoading: false }))
  }

  const getProfile = (info: any) => {
    const { profile_photo, first_name, last_name } = info || {}
    return {
      img_url: profile_photo ? `${DOMAIN}${profile_photo}` : null,
      label: `${first_name || ''} ${last_name || ''}`,
    }
  }
  const getDrawerBtn = () => {
    const btnProps = {
      okText: 'SUBMIT REQUEST',
      okColor: 'primary',
      okDisabled: true,
      isCancelBtn: true,
      onOK: handleSubmit,
    }
    if (!curItem.id) {
      return {
        ...btnProps,
        okDisabled: !team,
      }
    }
    if (isNeedApprove) {
      return {
        ...btnProps,
        okText: 'CLOSE',
        okColor: 'secondary',
        isCancelBtn: false,
        okDisabled: false,
        onOK: toggle,
      }
    }
    if (isApproved) {
      return {
        ...btnProps,
        okText: 'UPDATE',
        okDisabled: !easyShip,
        onOK: handleUpdate,
      }
    }
  }
  const drawerBtn = getDrawerBtn()!
  return (
    <Drawer
      title={title}
      okText={drawerBtn.okText}
      cancelText='CLOSE'
      isCancelBtn={drawerBtn.isCancelBtn}
      okButtonProps={{
        disabled: drawerBtn.okDisabled,
        color: drawerBtn.okColor,
        loading: loading.submitLoading,
      }}
      onOk={drawerBtn.onOK}
      open={show}
      toggle={toggle}
      footerLeftElement={
        <>
          {(isNeedApprove || isApproved) && (
            <div className='flex'>
              <Button
                color='warning'
                theme='text'
                loading={loading.deleteLoading}
                iconBefore={<SolidTrashCan size={12} />}
                onClick={handleDelete}
              >
                DELETE
              </Button>
              {isNeedApprove && (
                <AlertText className='ml-6' color='yellow' text='Pending Approval' />
              )}
            </div>
          )}
        </>
      }
    >
      <div className='w-full h-full flex space-y-6 flex-col'>
        <Select
          disabled
          isPerson
          label='REQUESTER'
          onChange={() => {}}
          placeholder=''
          value='1'
          options={[
            {
              label: curItem.id
                ? getProfile(curItem.request_by).label
                : profile?.userName!,
              img_url: curItem.id
                ? getProfile(curItem.request_by).img_url
                : profile?.userPhoto,
              value: '1',
            },
          ]}
        />
        <Select
          label='CHOOSE ITEM'
          onChange={value => setTeam(value)}
          options={CHOOSE_ITEM_OPTIONS}
          placeholder='Select'
          disabled={!!curItem.id}
          value={team}
        />
        {isApproved && (
          <Select
            disabled
            isPerson
            label='APPROVED BY'
            onChange={() => {}}
            placeholder=''
            value='1'
            options={[{ ...getProfile(curItem.approved_by), value: '1' }]}
          />
        )}
        {/* 编辑/未审核 状态下 有审核权限的人才能看到 */}
        {isNeedApprove && (
          <>
            <Checkbox
              checked={billingChecked}
              onChange={e => setBillingChecked(e.target.checked)}
            >
              Billing handled
            </Checkbox>
            <Button
              color='secondary'
              loading={loading.approveLoading}
              disabled={!billingChecked}
              onClick={handleApprove}
            >
              APPROVE FOR SHIPPING
            </Button>
          </>
        )}

        {/* approve 之后才会显示此按钮 */}
        {isApproved && (
          <div>
            <div className='flex space-x-4 items-end mb-2'>
              <SingleInput
                className='flex-1'
                name='EASYSHIP'
                label='EASYSHIP'
                placeholder='Input'
                value={easyShip}
                onChange={e => setEasyShip(e.target.value)}
              />
              <Button
                className='min-w-[72px] relative bottom-0.5'
                color='secondary'
                theme='outlined'
                disabled={!easyShip}
                loading={loading.syncLoading}
                onClick={handleSync}
              >
                SYNC
              </Button>
            </div>
            <div className='flex space-x-2'>
              {easyShipRes.state && (
                <BadgeText color='default' value={easyShipRes.state} />
              )}
              {easyShipRes.name && <BadgeText color='default' value={easyShipRes.name} />}
            </div>
          </div>
        )}
      </div>
    </Drawer>
  )
}
