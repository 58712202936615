import { useState } from 'react'
import cn from 'classnames'
import { Plus } from '@rushable/icons'
import Table from 'components/Table'
import Label from 'components/Label'
import Button from 'components/Button'
import PersonName from 'components/PersonName'
import LogisticItemDrawer from '../LogisticItemDrawer'
import type { TColumnProp } from 'components/Table'

import { DOMAIN } from 'data/constant'

export type TPremiumAddOnTableProps = {
  onboardingId: string
  className?: string
  title?: string
  disabled?: boolean
  items: any[] // 项目条数
  onSuccess: () => void
}

export default function PremiumAddOnTable({
  onboardingId,
  className,
  title,
  disabled,
  items,
  onSuccess,
}: TPremiumAddOnTableProps): JSX.Element | null {
  const [show, setShow] = useState(false)
  const [curItem, setCurItem] = useState<Record<string, any>>({})

  const getAvatar = (profile_photo: string) =>
    profile_photo && `${DOMAIN}${profile_photo}`

  const columns: TColumnProp[] = [
    {
      key: 'item',
      name: 'ITEM',
    },
    {
      key: 'request_by',
      name: 'REQUESTED BY',
      className: 'w-40',
      custom: (value: any) => {
        return (
          <PersonName
            className='!flex'
            avatar={getAvatar(value?.profile_photo)}
            name={`${value?.first_name || '-'} ${value?.last_name || ''}`}
          />
        )
      },
    },
    {
      key: 'approved_by',
      name: 'APPROVED BY',
      className: 'w-40',
      custom: (value: any) => {
        return (
          <PersonName
            className='!flex'
            avatar={getAvatar(value?.profile_photo)}
            name={`${value?.first_name || '-'} ${value?.last_name || ''}`}
          />
        )
      },
    },
    {
      key: 'shipped_by',
      name: 'SHIPPED BY',
      className: 'w-40',
      custom: (value: any) => {
        return (
          <PersonName
            className='!flex'
            avatar={getAvatar(value?.profile_photo)}
            name={`${value?.first_name || '-'} ${value?.last_name || ''}`}
          />
        )
      },
    },
    {
      key: 'easyship_tracking_status',
      name: 'LOGISTIC STATUS',
      align: 'right',
      custom: (value: string) => (
        <span className='capitalize'>{value || '-'}</span>
      ),
    },
  ]

  return (
    <>
      <div className={cn('ru-premium-addon-table', className)}>
        <div className='flex justify-between mb-2'>
          <Label>{title} </Label>
          <Button
            theme='text'
            size='small'
            color='secondary'
            disabled={disabled}
            onClick={() => {
              setCurItem({})
              setShow(true)
            }}
            iconBefore={<Plus size={12} />}
          >
            ADD LOGISTIC ITEM
          </Button>
        </div>
        <div className={cn('w-full py-2 border border-dark-100 rounded-lg')}>
          <Table
            columns={columns}
            data={items || []}
            clickedRow={row => {
              if (disabled) {
                return
              }
              setCurItem(row)
              setShow(true)
            }}
          />
        </div>
      </div>
      <LogisticItemDrawer
        title='ADD LOGISTIC ITEM'
        show={show}
        curItem={curItem}
        onboardingId={onboardingId}
        toggle={() => setShow(!show)}
        onSuccess={onSuccess}
      />
    </>
  )
}
