import React from 'react'

import ModalFull from 'components/Modal'

type TMenuLockModalProps = {
  open: boolean
  toggle: () => void
}

export default function MenuLockModal({
  open,
  toggle,
}: TMenuLockModalProps): JSX.Element {
  return (
    <ModalFull
      title='Menu managed elsewhere'
      isCancelBtn={false}
      open={open}
      toggle={toggle}
    >
      <div className='text-light-700 text-base text-center'>
        Due to one of your integrations, your online menu is currently being
        managed from your POS system through a 3rd party app. For more
        information, please contact us at <b>(855) 979-8860</b> or email to{' '}
        <b>support@rushable.io</b>
      </div>
    </ModalFull>
  )
}
