import ReactSlider from 'react-slider'
import cn from 'classnames'

export type TLabelProp = {
  className?: string
  min?: number
  max?: number
  step?: number
  discount?: number
  value: number
  onChange: (value: number) => void
}

export default function DragBar({
  className,
  value,
  min = 0,
  max = 100,
  discount = 0,
  step = 1,
  onChange,
}: TLabelProp): JSX.Element {
  // 当 discount 逼近 max 时，滑块会被挤出滚动条故再此处做一个补偿
  const barWidth = discount > 149 ? 24 : 0
  const discountWidth = (discount / (max - min + discount)) * 100
  return (
    <div className={cn('ru-dragbar', className)}>
      {discount > 0 && (
        <div
          className='w-20 ru-dargbar-extra'
          style={{ width: `calc(${discountWidth}% - ${barWidth}px` }}
        />
      )}
      <ReactSlider
        min={min}
        max={max}
        step={step}
        value={value}
        disabled={min === max}
        className={cn('dragbar-slider', discount > 0 ? 'more-than-0' : '')}
        trackClassName={cn('dragbar-track')}
        thumbClassName='dragbar-thumb'
        renderThumb={(props, state) => (
          <div {...props}>
            <div />
            <div />
          </div>
        )}
        onChange={onChange}
      />
    </div>
  )
}
